import { number, string, boolean, date, addMethod } from 'yup';

import type { AnySchema } from 'yup';

export type Operator = {
  label: string;
  name: string;
  requiresSecondValue?: boolean;
  valueType?: Value;
  yup: AnySchema;
};

const BaseEnum = {
  Boolean: 'boolean',
  Date: 'date',
  HistoricDate: 'historicDate',
  Number: 'number',
  Percent: 'percent',
  ProductCategory: 'productCategory',
  Brand: 'brand',
  StateLocation: 'stateLocation',
  String: 'string',
  CustomerType: 'customerType',
  CustomerGroup: 'customerGroup',
  Gender: 'gender',
  BirthdayMonth: 'birthdayMonth',
  LastPurchaseLocation: 'lastPurchaseLocation',
} as const;

export const ValueEnum = {
  ...BaseEnum,
  DateRange: 'dateRange',
  NumberRange: 'numberRange',
} as const;

export type Value = (typeof ValueEnum)[keyof typeof ValueEnum];

export const OperatorGroupEnum = {
  ...BaseEnum,
} as const;

export type OperatorGroup = (typeof OperatorGroupEnum)[keyof typeof OperatorGroupEnum];

export function getOperatorsFromOperatorGroup(operatorGroup?: OperatorGroup, enableBrands = false): Operator[] {
  switch (operatorGroup) {
    case OperatorGroupEnum.Date:
      return dateOperators;
    case OperatorGroupEnum.HistoricDate:
      return historicDateOperators;
    case OperatorGroupEnum.Number:
      return numberOperators;
    case OperatorGroupEnum.Percent:
      return percentileOperators;
    case OperatorGroupEnum.String:
      return stringOperators;
    case OperatorGroupEnum.Boolean:
      return booleanOperators;
    case OperatorGroupEnum.ProductCategory:
      return multiSelectProductCategoryOperators;
    case OperatorGroupEnum.Brand:
      return enableBrands ? multiSelectBrandOperators : stringOperators;
    case OperatorGroupEnum.StateLocation:
      return equalsStateLocationOperators;
    case OperatorGroupEnum.CustomerType:
      return multiSelectCustomerTypeOperators;
    case OperatorGroupEnum.CustomerGroup:
      return multiSelectCustomerGroupOperators;
    case OperatorGroupEnum.Gender:
      return multiSelectGenderOperators;
    case OperatorGroupEnum.BirthdayMonth:
      return multiSelectBirthdayMonthOperators;
    case OperatorGroupEnum.LastPurchaseLocation:
      return multiSelectLastPurchaseLocationOperators;
    default:
      return stringOperators;
  }
}

export type valueTypeValidation = Record<Value, AnySchema>;

addMethod(number, 'validEmptyString', function validEmptyString() {
  return this.transform((value) => (Number.isNaN(value) ? 0 : value));
});

// creating a yup schema for each value type allows for more specific validation
export const validation: valueTypeValidation = {
  [ValueEnum.Boolean]: boolean(),
  [ValueEnum.Date]: date(),
  [ValueEnum.HistoricDate]: date(),
  [ValueEnum.Number]: number().validEmptyString().positive().min(1).max(1000000, 'Value must be less than 1,000,000'),
  [ValueEnum.Percent]: number()
    .validEmptyString()
    .positive()
    .min(1, 'Percent must be greater than 0')
    .max(100, 'Percent must be less than 100'),
  [ValueEnum.ProductCategory]: string(),
  [ValueEnum.Brand]: string(),
  [ValueEnum.StateLocation]: string(),
  [ValueEnum.BirthdayMonth]: string(),
  [ValueEnum.LastPurchaseLocation]: string(),
  [ValueEnum.String]: string(),
  [ValueEnum.CustomerType]: string(),
  [ValueEnum.CustomerGroup]: string(),
  [ValueEnum.Gender]: string(),
  [ValueEnum.DateRange]: date(),
  [ValueEnum.NumberRange]: number()
    .validEmptyString()
    .positive()
    .min(1)
    .max(1000000, 'Value must be less than 1,000,000'),
};

export const dateOperators: Operator[] = [
  { name: '=', label: 'on', valueType: 'date', yup: validation[ValueEnum.Date] },
  { name: '>', label: 'after', valueType: 'date', yup: validation[ValueEnum.Date] },
  { name: '<', label: 'before', valueType: 'date', yup: validation[ValueEnum.Date] },
  {
    name: 'between',
    label: 'between',
    valueType: 'dateRange',
    yup: validation[ValueEnum.DateRange],
    requiresSecondValue: true,
  },
  { name: '>=', label: 'in the last', valueType: 'number', yup: validation[ValueEnum.Number] },
  { name: '<=', label: 'in the next', valueType: 'number', yup: validation[ValueEnum.Number] },
];

// some date operators reference the past only
export const historicDateOperators: Operator[] = [
  { name: '=', label: 'on', valueType: 'date', yup: validation[ValueEnum.Date] },
  { name: '>', label: 'after', valueType: 'date', yup: validation[ValueEnum.Date] },
  { name: '<', label: 'before', valueType: 'date', yup: validation[ValueEnum.Date] },
  {
    name: 'between',
    label: 'between',
    valueType: 'dateRange',
    yup: validation[ValueEnum.Date],
    requiresSecondValue: true,
  },
  { name: '>=', label: 'in the last', valueType: 'number', yup: validation[ValueEnum.Number] },
  { name: '<=', label: 'before number of days ago', valueType: 'number', yup: validation[ValueEnum.Number] },
];

export const numberOperators: Operator[] = [
  { name: '=', label: 'exactly', valueType: 'number', yup: validation[ValueEnum.Number] },
  { name: '>', label: 'more than', valueType: 'number', yup: validation[ValueEnum.Number] },
  { name: '<', label: 'less than', valueType: 'number', yup: validation[ValueEnum.Number] },
  {
    name: 'between',
    label: 'between',
    valueType: 'numberRange',
    yup: validation[ValueEnum.NumberRange],
    requiresSecondValue: true,
  },
];

export const percentileOperators: Operator[] = [
  { name: '>=', label: 'top', valueType: 'percent', yup: validation[ValueEnum.Percent] },
  { name: '<=', label: 'bottom', valueType: 'percent', yup: validation[ValueEnum.Percent] },
];

export const stringOperators: Operator[] = [
  { name: '=', label: 'is', valueType: 'string', yup: validation[ValueEnum.String] },
  { name: '!=', label: 'is not', valueType: 'string', yup: validation[ValueEnum.String] },
  { name: 'like', label: 'like', valueType: 'string', yup: validation[ValueEnum.String] },
  { name: 'not like', label: 'not like', valueType: 'string', yup: validation[ValueEnum.String] },
];

export const booleanOperators: Operator[] = [
  { name: '=', label: 'is', valueType: 'boolean', yup: validation[ValueEnum.Boolean] },
];

export const createEqualsOperators = (valueType: Value): Operator[] => [
  { name: '=', label: 'is', valueType, yup: validation[valueType] },
  { name: '!=', label: 'is not', valueType, yup: validation[valueType] },
];

export const createMultiSelectOperators = (valueType: Value): Operator[] => [
  { name: 'in', label: 'is in', valueType, yup: validation[valueType] },
  { name: 'not in', label: 'is not in', valueType, yup: validation[valueType] },
];

export const equalsStateLocationOperators = createEqualsOperators('stateLocation');
export const equalsCustomerTypesOperators = createEqualsOperators('customerType');

export const multiSelectGenderOperators = createMultiSelectOperators('gender');
export const multiSelectCustomerTypeOperators = createMultiSelectOperators('customerType');
export const multiSelectCustomerGroupOperators = createMultiSelectOperators('customerGroup');
export const multiSelectProductCategoryOperators = createMultiSelectOperators('productCategory');
export const multiSelectBrandOperators = createMultiSelectOperators('brand');
export const multiSelectBirthdayMonthOperators = createMultiSelectOperators('birthdayMonth');
export const multiSelectLastPurchaseLocationOperators = createMultiSelectOperators('lastPurchaseLocation');
