import type { ChangeEvent } from 'react';
import React from 'react';

import { Label } from 'src/app/components/lib/label';

import { getIDFromLabel } from '../utils';

import { CheckboxBase } from './checkbox-base';
import { RebrandFormControlLabel } from './checkbox.styles';

import type { SxProps } from '@mui/material';

export type CheckboxProps = {
  automationId?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  label: React.ReactElement | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  sx?: SxProps;
  tooltip?: string;
  useV2Tooltip?: boolean;
  value?: any;
};

export function Checkbox(props: CheckboxProps) {
  const {
    automationId,
    label,
    labelPlacement = 'end',
    checked,
    indeterminate,
    name,
    onChange,
    disabled,
    value,
    sx,
    tooltip,
    required,
    className = '',
    useV2Tooltip = false,
  } = props;

  const labelId = getIDFromLabel(label);

  return (
    <RebrandFormControlLabel
      className={`${className} checkbox-form-control`}
      control={
        <CheckboxBase
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          name={name}
          value={value}
          onChange={onChange}
        />
      }
      data-testid={automationId}
      disabled={disabled}
      id={`checkbox_${labelId}`}
      label={
        <Label
          htmlFor={`checkbox-input_${labelId}`}
          id={`checkbox-label_${labelId}`}
          required={required}
          tooltip={tooltip}
          useSpanLabel
          useV2Tooltip={useV2Tooltip}
        >
          {label}
        </Label>
      }
      labelPlacement={labelPlacement}
      sx={sx}
    />
  );
}
