import keyMirror from 'keymirror';

export const endPoints = {
  GET_PERMISSIONS: 'api/permissions/get',
  GET_SUPERUSER_PERMISSIONS: 'api/permissions/get-superuser',
  ELEVATE_SUPERUSER: 'api/permissions/user/elevate-superuser',
};

export const Permissions = {
  SuperUser: 1,
  Administrator: 2,
  EditLoyalty: 2,
  AssignAdmin: 3, //
  AssignPermissions: 4, //
  LogintoBackend: 5,
  LogintoPOS: 6,
  LogintoStorefront: 7,
  ViewPlants: 8, //
  ViewPlantGroups: 9, //
  AddPlants: 10, // ignored for now
  RetirePlants: 11, //
  MovePlants: 12, //
  AddPlantToHarvest: 13, //
  CreatePlants: 14, // Sidebar - Add Plants/Clones to Inventory
  CreatePlantGroup: 15, //
  AssignMother: 16, //
  AssignPlantGroup: 17, //
  AssignPlantStatus: 18, // Assign Plant Mother Status in Plant Manager
  ApplyNutrients: 19, // The user-facing nomenclature for this feature is now "Additives", the but the code still uses "Nutrients" everywhere not user-facing
  ViewPlantHistory: 20, // Journal and Additive Log
  ConvertPlants: 21, // Convert to Plant(s) in Plant Manager Action Menu
  EnterPlantWeights: 22, // Batch Manager harvest screen
  EnterWetWaste: 23, //
  EnterDryWaste: 24, //
  CloseHarvest: 25, // Batch Manager Action Menu
  CreateHarvestPackages: 26, //
  PostPackagesToInventory: 27, //
  TransferPlantToInventory: 28, //
  EnterLabResults: 29, //
  PrintPlantLabels: 30, //
  AssignPlantStage: 31, //
  ViewHarvest: 32, //
  ViewLabAnalysis: 33, //
  ViewPackaging: 34, //
  ViewInventory: 35, // Hide Inventory in sidebar
  ViewInventoryCosts: 36, //
  AdjustInventory: 37, //
  CreateBatch: 38, //
  AssignBatch: 39, //
  MoveInventory: 40, //
  CombineInventory: 41, //
  ConvertInventory: 42, //
  PrintInventoryLabels: 43, //
  ChangeInventoryProduct: 44, //
  CreatePackages: 45, // ??
  Sublot: 46, //
  DestroyInventory: 47, //
  ViewLabSamples: 48, //
  EditLabSamples: 49, //
  ViewInventoryTransactions: 50, //
  ViewInventoryHistory: 51, // ??
  UpdatePackageData: 52, //
  CreateInventoryPackages: 53, //
  ReceiveInventory: 54, //
  ViewReceiveHistory: 55, //
  SaveOrders: 56, //
  ReceiveTransfers: 57, //
  ViewIntegrationReconciliation: 58, //
  CreateReconciliation: 59, //
  EditReconciliation: 60, //
  EnterReconciliationData: 61, //
  CloseReconciliation: 62, //
  ViewReconciliation: 63,
  ViewBatchHistory: 64, // Sidebar - Inventory - Batch History

  // Inventory - Manifest
  CreateManifest: 65, //
  AddItemstoManifest: 66, //
  DeleteManifest: 67, //
  CloseManifest: 68, //
  EditManifest: 69, // Details - Drivers - Address - Delete items
  PrintManifest: 70, // Manifest - Invoice

  // Inventory - Recipes
  EditRecipe: 71, //
  ViewRecipes: 72, //

  // Inventory - Recipe Batches
  CreateRecipeBatch: 73, //
  ViewRecipeBatch: 74, //
  RunRecipeBatchSteps: 75, //
  UndoRecipeBatchSteps: 76, //

  CreateProduct: 77, //
  ViewProductDetail: 78, //
  EditProductDetail: 79, //

  ManageRetiredProducts: 80, //
  ViewPOSSummary: 81, //
  ViewRegisterTransactionData: 82, //
  AdjustRegister: 83, //
  CloseOutRegister: 84, //
  ViewClosingReport: 85, //
  ViewMLSR: 349, //
  ViewPOS: 86,
  ViewDashboard: 87, //
  ViewStaticReports: 88, //
  SaveReports: 90, //

  ViewCustomers: 91, //
  EditCustomers: 92, //
  EditCars: 93, //
  EditDrivers: 94, //
  ConfigureLabels: 95, //
  EditRooms: 96, //
  ConfigurePrinters: 97, //
  EditProductCategories: 98, //
  EditStrains: 99, //
  EditVendors: 100, //
  EditTables: 101, //
  EditReasonCodes: 102, //
  EditTimeClocks: 103, //
  EditUsers: 104, //
  ConfigureReceipts: 105, //
  ViewManifest: 106,
  ViewWasteManager: 107,
  ViewProductMaster: 108,
  EditInventoryStatus: 109,
  EditDiscounts: 110,
  EditDiscountGroups: 111,
  EditDoctors: 112,
  EditPricing: 113,
  EditRegisters: 114,
  EditTaxRates: 116,
  DiscontinuePackage: 117,
  POSManager: 118,
  MergeCustomers: 119,
  SetHarvestStage: 120,
  AssignUsersToGroups: 121,
  ResetUserPasswords: 122,
  ViewPermissions: 123,
  BackDatePlantActions: 124,
  ViewPackageHistory: 125,
  BypassStateSystem: 126,
  OverrideDiscounts: 127,
  EditReturnReasons: 128,
  EditPackageIdFormat: 129,
  BypassStateforInventoryAdjustments: 130,
  BypassStateforPackageMovements: 131,
  BypassStateforPlantCreationorConversion: 132,
  BypassStateforPlantHarvesting: 133,
  BypassStateforPlantPackaging: 134,
  BypassStateforPlantMovement: 135,
  BypassStateforReceiveInventory: 136,
  BypassStateforCreateLabSamples: 137,
  EditDosages: 138,
  EditSizes: 139,
  EditReceivedProductDetails: 140,
  ViewInfusions: 141,
  EditInfusion: 142,
  RegisterTransfer: 143,
  EditProductSkuFormat: 144,
  EditTransactionAdjustmentReasons: 145,
  ViewBIDashboard: 146,
  ExploreBIData: 147,
  ExlopreBIEnterpriseData: 148,
  EditPOSCustomerStatus: 149,
  EditPatientJournalEntries: 150,
  EditLocationProductMaster: 151,
  EditBrands: 152,
  MoveInventoryLocation: 153,
  EditFeesDonations: 156,

  // Users -  Integrations
  ViewQuickbooksIntegration: 157,
  EditQuickbooksIntegration: 158,
  ViewSproutIntegration: 159,
  EditSproutIntegration: 160,
  ViewWeedmaps2Integration: 161,
  EditWeedmaps2Integration: 162,
  ViewHypurIntegration: 163,
  EditHypurIntegration: 164,
  ViewMETRCIntegration: 165,
  EditMETRCIntegration: 166,
  ViewBioTrackIntegration: 167,
  EditBioTrackIntegration: 168,
  ViewWeedMapsIntegration: 169,
  EditWeedMapsIntegration: 170,
  ViewLeaflyIntegration: 171,
  EditLeaflyIntegration: 172,
  ViewMassDHIntegration: 173,
  EditMassDHIntegration: 174,
  ViewSpringBigIntegration: 175,
  EditSpringBigIntegration: 176,
  ViewLinxIntegration: 177,
  EditLinxIntegrations: 178,
  ViewMNIntegration: 179,
  EditMNIntegration: 180,
  ViewLeafIntegration: 181,
  EditLeafIntegration: 182,
  ViewOnFleetIntegration: 183,
  EditOnFleetIntegration: 184,
  ViewTookanIntegration: 185,
  EditTookanIntegration: 186,
  ScheduleEmailReports: 187,
  EditVaultRegister: 188,
  EditLeafLinkIntegration: 190,
  ViewLeafLinkIntegration: 191,
  BiotrackDestructionManager: 192,
  EditLineages: 193,
  EditDistillations: 194,
  EditReceiveHistory: 195,
  EditUserStateIds: 196,
  LoginToCRM: 197,
  TextCustomers: 198,
  SendBlastMessages: 199,
  ViewCars: 200,
  ViewDrivers: 201,
  ViewRooms: 202,
  ViewProductCategories: 203,
  ViewStrains: 204,
  ViewVendors: 205,
  ViewTables: 206,
  ViewReasonCodes: 207,
  ViewTimeClocks: 208,
  ViewUsers: 209,
  ViewInventoryStatus: 210,
  ViewDiscounts: 211,
  ViewDiscountGroups: 212,
  ViewDoctors: 214,
  ViewPricing: 215,
  ViewRegisters: 216,
  ViewTaxRates: 217,
  ViewReturnReasons: 218,
  ViewPackageIdentityFormat: 219,
  ViewProductSkuFormat: 220,
  ViewPosCustomerStatus: 221,
  ViewPatientJournalEntries: 222,
  ViewLocationProductMaster: 223,
  ViewFeesAndDonations: 224,
  ViewLineages: 225,
  ViewDistillations: 226,
  ViewUserStateIds: 227,
  ViewDosages: 228,
  ViewSizes: 229,
  ViewReceivedProductDetails: 230,
  ViewBrands: 231,
  EditUserPins: 232,
  TrimPlants: 233,
  EditTags: 235,
  EditAdjustLoyaltyReasons: 236,
  ReconcileMetrcBatchModeSales: 237,
  ReopenManifest: 238,
  CreateTags: 239,
  DeleteTags: 240,
  EditProductTags: 241,
  EditPackageTags: 242,
  ViewAlpineIQIntegration: 243,
  EditAlpineIQIntegration: 244,
  ViewSamlConfiguration: 245,
  EditSamlConfiguration: 246,
  ViewBlindReconExpectedQuantities: 247,
  METRCBatchMode: 248,
  ViewJoryIntegration: 249,
  EditJoryIntegration: 250,
  ViewCustomerInfoAccessLogs: 251,
  EditPasswordSettings: 252,
  ViewPasswordSettings: 253,
  ViewThresholds: 254,
  EditThresholds: 255,
  ViewZones: 256,
  EditZones: 257,
  AdjustImmaturePlantCount: 258,
  ViewCultivationApiQueue: 259,
  EditCultivationApiQueue: 260,
  EditDiscountGroupCustomers: 261,
  ViewMETRCReceiptReconciliation: 262,
  ViewRoutes: 263,
  EditRoutes: 264,
  GeneratePMPUploadFile: 265,
  ViewBatchIdFormat: 266,
  EditBatchIdFormat: 267,
  ViewDeliveryTitleFormat: 268,
  EditDeliveryTitleFormat: 269,
  ViewAGCOReport: 271,
  ViewProducers: 272,
  EditProducers: 273,
  EditPOApprovalThreshold: 274,
  ViewPOApprovalThreshold: 275,
  ApprovePOs: 276,
  ViewPOApprovalGrid: 277,
  ViewUserPins: 278,
  ViewFylloIntegration: 279,
  EditFylloIntegration: 280,
  ViewCCRSIntegration: 281,
  ViewCustomerTypes: 282,
  EditCustomerTypes: 283,
  ViewPlanetPaymentsIntegration: 284,
  EditPlanetPaymentsIntegration: 285,
  ViewBCReport: 286,
  ViewBlindAuditProductswithDifference: 287,
  ViewMMURIntegrationConfiguration: 288,
  EditMMURIntegrationConfiguration: 289,
  ViewABReport: 290,
  ViewVaultRegister: 291,
  ViewCOGSData: 292,
  EditExternalWebsites: 295,
  ViewLoyaltyAccrual: 296,
  EditLoyaltyAccrual: 297,
  ViewDutchiePayIntegration: 298,
  EditDutchiePayIntegration: 299,
  ViewTrymIntegration: 300,
  EditTrymIntegration: 301,
  EditDefaultDeliveryWindow: 302,
  ViewOCSIntegrationConfiguration: 305,
  EditOCSIntegrationConfiguration: 306,
  ViewPaynetworxIntegration: 307,
  EditPaynetworxIntegration: 308,
  ViewReadOnlyFeatureFlags: 309,
  UseDeletePendingMetrcTransfers: 310,
  EditPurchaseLimits: 311,
  ViewMCDMSIntegrationConfiguration: 312,
  EditMCDMSIntegrationConfiguration: 313,
  ViewVCCBIntegrationConfiguration: 314,
  EditVCCBIntegrationConfiguration: 315,
  ViewUserEventLogs: 316,
  ViewReThinkIntegration: 317,
  EditReThinkIntegration: 318,
  ViewSalesReports: 319,
  ViewInventoryReports: 320,
  ViewEmployeeReports: 321,
  ViewMarketingReports: 322,
  ViewOperationsReports: 323,
  ViewDutchieHubIntegration: 324,
  EditDutchieHubIntegration: 325,
  BulkDeleteUsers: 326,
  BulkAssignUserLocations: 327,
  BulkAssignUserGroups: 328,
  ViewVirginiaBioTrackIntegrationConfiguration: 329,
  EditVirginiaBioTrackIntegrationConfiguration: 330,
  ViewNonIntegratedPaymentsIntegrationConfiguration: 331,
  EditNonIntegratedPaymentsIntegrationConfiguration: 332,
  ConfigureExternalLoyalty: 333,
  ManageAuditsAtAllLocations: 334,
  ViewG2: 335,
  EditG2: 336,
  ViewInventoryKits: 337,
  EditInventoryKits: 338,
  ManageInventoryAllocation: 339,
  ViewUtahICSIntegrationConfiguration: 340,
  EditUtahICSIntegrationConfiguration: 341,
  CreateSimpleAudits: 344,
  ViewReceiptAudit: 345,
  PostReceiptsToStateSystem: 346,
  ViewWedge: 347,
  EditWedge: 348,
  ViewSegments: 340,
  EditSegments: 341,
  ViewOrgManagement: 350,
  ViewBillOfMaterial: 352,
  EditBillOfMaterial: 353,
  ViewAssembly: 354,
  EditAssembly: 355,
  AccessEmailMarketing: 356,
  ManuallyFetchQrCodes: 351,
  ViewBirchmount: 357,
  EditBirchmount: 358,
  EditFlowerEquivalencyDefinition: 359,
  ViewFlowerEquivalencyDefinition: 360,
  ViewCampaigns: 362,
  CreateEditCampaigns: 363,
  SendCampaigns: 364,
  ViewSpin: 365,
  EditSpin: 366,
  ViewTFI: 367,
  EditTFI: 368,
} as const;

export const SuperUserPermissions = {
  ManageSuperUsers: 1,
  AccessDataWipe: 2,
  PublishLocation: 3,
  AccessDataImport: 4,
  RevertToStaging: 5,
  AccountCreation: 6,
  LocationCreation: 7,
  StatePackModification: 8,
  AccessLocationManager: 9,
  UpdatePMPSettings: 10,
  SelfServicePublicApiKeys: 12,
} as const;

export const IntegrationPermissions = [
  Permissions.ViewQuickbooksIntegration,
  Permissions.EditQuickbooksIntegration,
  Permissions.ViewSproutIntegration,
  Permissions.EditSproutIntegration,
  Permissions.ViewWeedmaps2Integration,
  Permissions.EditWeedmaps2Integration,
  Permissions.ViewHypurIntegration,
  Permissions.EditHypurIntegration,
  Permissions.ViewMETRCIntegration,
  Permissions.EditMETRCIntegration,
  Permissions.ViewBioTrackIntegration,
  Permissions.EditBioTrackIntegration,
  Permissions.ViewWeedMapsIntegration,
  Permissions.EditWeedMapsIntegration,
  Permissions.ViewLeaflyIntegration,
  Permissions.EditLeaflyIntegration,
  Permissions.ViewMassDHIntegration,
  Permissions.EditMassDHIntegration,
  Permissions.ViewSpringBigIntegration,
  Permissions.EditSpringBigIntegration,
  Permissions.ViewLinxIntegration,
  Permissions.EditLinxIntegrations,
  Permissions.ViewMNIntegration,
  Permissions.EditMNIntegration,
  Permissions.ViewLeafIntegration,
  Permissions.EditLeafIntegration,
  Permissions.ViewCCRSIntegration,
  Permissions.ViewOnFleetIntegration,
  Permissions.EditOnFleetIntegration,
  Permissions.ViewTookanIntegration,
  Permissions.EditTookanIntegration,
  Permissions.BiotrackDestructionManager,
  Permissions.ViewLeafLinkIntegration,
  Permissions.EditLeafLinkIntegration,
  Permissions.ViewSamlConfiguration,
  Permissions.EditSamlConfiguration,
  Permissions.ViewFylloIntegration,
  Permissions.EditFylloIntegration,
  Permissions.ViewMMURIntegrationConfiguration,
  Permissions.EditMMURIntegrationConfiguration,
  Permissions.ViewCampaigns,
  Permissions.CreateEditCampaigns,
  Permissions.SendCampaigns,
];

export const actions = keyMirror({});

export const events = keyMirror({
  CHANGE_EVENT: null,
});
