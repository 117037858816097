import React, { useMemo, useState, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { Input, InputAdornment, TableInput, TableSelectAdornment } from 'src/app/components/lib/input';
import { SelectAdornment } from 'src/app/components/lib/input/select-adornment';
import { inventoryAtom } from 'src/app/state/inventory';

import type { InputProps } from 'src/app/components/lib/input';
import type { Option } from 'src/app/components/lib/input/select-adornment';

export type QuantityInputProps = {
  automationId?: string;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  label?: InputProps['label'];
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  maximum?: number;
  minimum?: number;
  nameType?: 'abbreviation';
  onBlur?: InputProps['onBlur'];
  onChange?: ({ value }: { value: string }) => void;
  onFocus?: InputProps['onFocus'];
  onUnitChange?: ({ unitId }: { unitId: number | string }) => void;
  placeholder?: string;
  required?: boolean;
  showUnit?: boolean;
  tableVariant?: boolean;
  tooltip?: InputProps['tooltip'];
  unitDisabled?: boolean;
  unitPlaceholder?: string;
  unitTypeId?: Array<number> | number;
  unitValue: string;
  uppercaseUnit?: boolean;
  value: number | string;
  step?: number | 'any';
};

export function QuantityInput(props: QuantityInputProps) {
  const {
    automationId,
    disabled = false,
    error = false,
    showUnit = true,
    id,
    label,
    labelPlacement,
    maximum,
    minimum,
    nameType,
    onBlur,
    onChange,
    onFocus,
    onUnitChange,
    placeholder,
    required = false,
    tableVariant,
    unitDisabled = false,
    unitPlaceholder = 'Unit',
    unitTypeId,
    unitValue,
    uppercaseUnit = false,
    tooltip,
    value,
    step,
  } = props;

  const { units } = useRecoilValue(inventoryAtom);
  const [errorMessage, setErrorMessage] = useState('');
  const shouldNotHaveDecimalInput = String(unitValue) === '1';
  useEffect(() => {
    if (shouldNotHaveDecimalInput) {
      validator(value?.toString());
    }
  }, [unitValue]);
  const filteredUnits = units.filter((unit) => {
    if (Array.isArray(unitTypeId)) {
      return unitTypeId.includes(unit.UnitTypeId);
    }
    return !unitTypeId || unit.UnitTypeId === unitTypeId;
  });
  const unitOptions: Option[] = filteredUnits.map((unit) => ({
    label: nameType === 'abbreviation' ? unit.Abbreviation : unit.UnitName,
    value: String(unit.UnitId),
  }));
  const sortedUnitOptions: Option[] = unitOptions.sort((a, b) => a.label.localeCompare(b.label));
  const selectedUnit = filteredUnits.find((unit) => String(unit.UnitId) === String(unitValue));
  const selectedUnitLabel = nameType === 'abbreviation' ? selectedUnit?.Abbreviation : selectedUnit?.UnitName;
  const formattedSelectedUnitLabel = uppercaseUnit ? selectedUnitLabel?.toUpperCase() : selectedUnitLabel;

  async function handleOnChange(newValue) {
    onChange?.({ value: newValue });
  }

  const validator = (newVal?: string): boolean => {
    const blankMessage = required ? '' : ' or can be left blank';

    if (maximum !== undefined && newVal !== '' && Number(newVal) > maximum) {
      setErrorMessage(`Value must be at most ${maximum}${blankMessage}.`);
      return false;
    }

    if (minimum !== undefined && newVal !== '' && Number(newVal) < minimum) {
      setErrorMessage(`Value must be at least ${minimum}${blankMessage}.`);
      return false;
    }
    if (shouldNotHaveDecimalInput && newVal?.includes('.')) {
      setErrorMessage(
        `Decimal values are not allowed for ${(formattedSelectedUnitLabel?.toLowerCase() as string) ?? 'unit'} inputs.`
      );
      return false;
    }
    setErrorMessage('');
    return true;
  };

  function handleUnitChange(newValue) {
    validator(value.toString());
    if (onUnitChange !== undefined) {
      onUnitChange({ unitId: newValue });
    }
  }

  const InputElement = tableVariant ? TableInput : Input;
  const SelectAdornmentElement = tableVariant ? TableSelectAdornment : SelectAdornment;

  const inputProps = useMemo(
    () => ({
      ...(minimum !== undefined && { min: minimum }),
      ...(maximum !== undefined && { max: maximum }),
      ...((step !== undefined || minimum !== undefined || maximum !== undefined) && { step: step ?? 'any' }),
    }),
    [minimum, maximum, step]
  );

  return (
    <InputElement
      autoComplete='off'
      automationId={automationId}
      disabled={disabled}
      endAdornment={
        showUnit &&
        (unitDisabled ? (
          <InputAdornment position='end'>{formattedSelectedUnitLabel ?? unitPlaceholder}</InputAdornment>
        ) : (
          <SelectAdornmentElement
            automationId={`${automationId ?? label}-adornment`}
            label={unitPlaceholder}
            options={sortedUnitOptions}
            renderValue={(val) => {
              if (!val || val === '' || val === 'null' || val === 'undefined') {
                return unitPlaceholder;
              }

              const valueToRender = sortedUnitOptions.find((unit) => unit.value === val)?.label;

              if (uppercaseUnit) {
                return valueToRender?.toUpperCase();
              }
              return valueToRender;
            }}
            value={String(unitValue)}
            onChange={({ target }) => handleUnitChange(target.value)}
          />
        ))
      }
      error={error}
      // Table variant input does not support error messages
      {...(!tableVariant && { errorMessage })}
      id={id}
      inputProps={inputProps}
      label={label}
      // Table variant input does not support label placement
      {...(!tableVariant && { labelPlacement })}
      placeholder={placeholder}
      required={required}
      tooltip={tooltip}
      type='number'
      validator={(e) => validator(e.target.value)}
      value={value}
      onBlur={onBlur}
      onChange={({ target }) => handleOnChange(target.value)}
      onFocus={onFocus}
    />
  );
}
