import { atom } from 'recoil';

import type { ProductCategory, Brand } from 'src/app_deprecated/stores/InventoryStore';
import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

// ideally some of these would be enums...
export type Unit = {
  Abbreviation: string;
  UnitId: number;
  UnitName: string;
  UnitTypeId: number;
};

type InventoryAtom = {
  adjustmentReasons: any[];
  brands: Brand[];
  externalIds: string[];
  inventory: any[];
  inventoryLoading: boolean;
  inventoryStatuses: any[];
  productCategories: ProductCategory[];
  products: any[];
  productsLoading: boolean;
  tags: any[];
  units: Unit[];
  vendors: any[];
};

export const inventoryAtom = atom({
  key: 'inventory',
  default: {
    adjustmentReasons: [],
    brands: [],
    inventory: [],
    inventoryStatuses: [],
    inventoryLoading: false,
    products: [],
    productsLoading: false,
    tags: [],
    units: [],
    vendors: [],
    productCategories: [],
    externalIds: [],
  } as InventoryAtom,
  effects_UNSTABLE: [useSyncStateEffect],
  dangerouslyAllowMutability: true,
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForPosState() {
    const {
      brands,
      inventoryOnly,
      inventoryStatuses,
      inventoryLoading,
      productsOnly,
      productsLoading,
      units,
      adjustmentReasons,
      tags,
      vendors,
      productCategories,
      externalIds,
    } = InventoryStore;

    setSelf({
      brands,
      inventory: inventoryOnly,
      inventoryStatuses,
      inventoryLoading,
      products: productsOnly,
      productsLoading,
      units,
      adjustmentReasons,
      tags,
      vendors,
      productCategories,
      externalIds,
    });
  }

  if (trigger === 'get') {
    changeListenerForPosState(); // initial call
  }

  InventoryStore.addChangeListener(changeListenerForPosState);

  return () => InventoryStore.removeChangeListener(changeListenerForPosState);
}
