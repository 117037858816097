import React from 'react';

import { DatePicker } from 'src/app/components/lib/date-picker';
import { Divider } from 'src/app/components/lib/divider';
import { ServerPaginatedDropdownSingle } from 'src/app/components/lib/dropdown/server-paginated-dropdowns/dropdown-single';
import { FormContainer, FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { Status } from 'src/app/pages/manufacturing/assemblies/constants';

import { Outputs } from './outputs';
import { useAssemblyDetails } from './useAssemblyDetails';

import type { useAssemblyFormData } from '../../use-assembly-form-data';

type AssemblyDetailProps = {
  dropdownOptions: ReturnType<typeof useAssemblyFormData>['dropdownOptions'];
};

export function AssemblyDetails({ dropdownOptions }: AssemblyDetailProps) {
  const { fetchNextPage, isFetching, setFilter, options, morePagesExist } = dropdownOptions.bomDropDownData;
  const { applyBillOfMaterial, errors, formData, isLoadingBom, isReadonly, setValue } = useAssemblyDetails();
  const isAssemblyInProgress = formData.assemblyStatusId === Status.IN_PROGRESS;

  return (
    <>
      <FormContainer>
        <FormSection>
          {/* Name */}
          <Input
            disabled={isAssemblyInProgress || isReadonly}
            error={!!errors?.name}
            label='Name:'
            required
            value={formData.name}
            onChange={({ target }) => setValue('name', target.value)}
          />
          {/* Est start date */}
          <DatePicker
            allowPastDate
            date={formData.estimatedStartDate}
            disabled={isAssemblyInProgress || isReadonly}
            label='Est start date:'
            required
            onDateChange={(value: Date) => setValue('estimatedStartDate', value)}
          />
          <ServerPaginatedDropdownSingle
            disabled={isAssemblyInProgress || isReadonly}
            fetchNextPage={fetchNextPage}
            helpText='Select a bill of materials'
            inputId='bom-id'
            isFetching={isFetching}
            label='Bill of Materials:'
            morePagesExist={morePagesExist}
            options={options}
            required
            setFilterString={setFilter}
            setValue={applyBillOfMaterial}
            value={formData.billOfMaterialsId ?? ''}
          />
        </FormSection>
      </FormContainer>
      <Divider variant='end-of-form' />
      <Outputs dropdownOptions={dropdownOptions} isLoading={isLoadingBom} />
    </>
  );
}
