import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

import { LinkButton } from 'src/app/components/lib/button';
import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { inventoryAtom } from 'src/app/state/inventory';

import { getMultiSelectedOptions, parseSelectedValues } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type BrandDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export function BrandDropdown({ onChange, values, disabled = false }: BrandDropdownProps) {
  const [label, setLabel] = useState('Select a brand');
  const [loading, setLoading] = useState(false);
  const { brands } = useRecoilValue(inventoryAtom);
  const allBrandOptions: any[] = brands?.length ? brands : [];

  const [brandDropdownSelection, setBrandDropdownSelection] = useState<DropdownMenuOption[]>([]);

  const getMultiSelectedOptionsForBrands = (values: string, allBrandOptions: any[]): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allBrandOptions,
      parseSelectedValues,
      (option) => option.BrandName,
      (option) => ({
        label: option.BrandName,
        id: option.BrandId.toString(),
      })
    );

  useEffect(() => {
    if (brandDropdownSelection.length !== 0 && brandDropdownSelection.length < 4) {
      const BrandsLabelList = brandDropdownSelection.map((item) => item.label).join(', ');
      setLabel(BrandsLabelList);
    } else {
      setLabel('Select a brand');
    }
  }, [brandDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    setBrandDropdownSelection(getMultiSelectedOptionsForBrands(values, allBrandOptions));
  }, [brands]);

  useEffect(() => {
    void loadBrands();
  }, []);

  async function loadBrands() {
    setLoading(true);
    void InventoryStore.refreshBrands();
    setLoading(false);
  }

  async function handleRefresh() {
    await loadBrands();
  }

  const handleBrandsFilterChange = (values: DropdownMenuOption[]) => {
    setBrandDropdownSelection(values);

    const brandDropdownSelection = values.map((option) => option.label);
    onChange(brandDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={brandDropdownSelection}
      automationId='brand-dropdown_multi-filter-select'
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId='brand-dropdown_multi-filter-select_refresh-button'
          buttonSize='small'
          key='brand-refresh'
          label='Refresh'
          loading={loading}
          onClick={handleRefresh}
        />
      }
      label={label}
      options={allBrandOptions.map((Brands) => ({
        label: String(Brands.BrandName),
        id: String(Brands.BrandId),
      }))}
      onChange={handleBrandsFilterChange}
    />
  );
}
