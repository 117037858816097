import styled, { css } from 'styled-components';

export const RebrandMonthContainer = styled.div`
  .buffer-day {
    width: 42px;
    height: 42px;
    float: left;
  }
`;

export const RebrandDaysOfWeekContainer = styled.div`
  display: flex;
  margin-bottom: var(--sizes-10);
  color: var(--color-gray-80);

  .week-day__dd {
    width: 42px;
    height: auto;
    font: var(--font-extra-small-12pt-semibold);
    text-transform: none;
    float: left;
    text-align: center;
  }
`;

export const RebrandMonthDate = styled.div<{
  $afterSelectedDate?: boolean;
  $beforeSelectedDate?: boolean;
  $beforeTodayDisabled?: boolean;
  $beforeTodayDisabledAndEnableTime?: boolean;
  $selectedDate?: boolean;
  $todayDate?: boolean;
}>`
  ${({
    $selectedDate,
    $todayDate,
    $beforeTodayDisabled,
    $beforeSelectedDate,
    $afterSelectedDate,
    $beforeTodayDisabledAndEnableTime,
  }) => css`
    float: left;
    width: 42px;
    margin-bottom: var(--sizes-10);
    padding: var(--sizes-30) 0;
    border-radius: 50%;
    color: var(--color-grayscale-black);
    font: var(--font-small-13pt-semibold);
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: var(--color-blue-10);
    }
    ${$todayDate && todayDateStyles}
    ${$selectedDate && selectDateStyles}
    ${$beforeTodayDisabled && beforeTodayAppearDisabledStyles}
    ${$beforeSelectedDate && beforeTodayAppearDisabledStyles}
    ${$afterSelectedDate && beforeTodayAppearDisabledStyles}
    ${$beforeTodayDisabledAndEnableTime && beforeTodayDisabledStyles}
  `}
`;

const selectDateStyles = css`
  color: var(--color-brand-primary-white);
  background-color: var(--color-brand-secondary-sapphire);

  &:hover {
    background-color: var(--color-blue-70);
  }
`;

const todayDateStyles = css`
  background-color: var(--color-gray-10);

  &:hover {
    background-color: var(--color-gray-20);
  }
`;
// AppearDisabledStyles makes a day look disabled but actually allows selection
const beforeTodayAppearDisabledStyles = css`
  color: var(--color-gray-60);
  cursor: not-allowed;
`;
// `pointer-events: none` actually makes the days disabled on the date picker
const beforeTodayDisabledStyles = css`
  color: var(--color-gray-60);
  pointer-events: none;
`;
