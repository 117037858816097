import * as yup from 'yup';

import {
  INPUT_MIN_MESSAGE,
  OUTPUT_MIN_MESSAGE,
  QUANTITY_REQUIRED_MESSAGE,
  NAME_REQUIRED_MESSAGE,
} from '../../shared/constants';
import { requiredNullableNumber, requireWhenNotSkipped } from '../../shared/schema';
import {
  BATCH_REQUIRED_MESSAGE,
  NAME_LENGTH_MESSAGE,
  PACKAGE_ID_REQUIRED_MESSAGE,
  PRODUCT_REQUIRED_MESSAGE,
  REQUIRED_BOM_MESSAGE,
  ROOM_REQUIRED_MESSAGE,
  START_DATE_REQUIRED_MESSAGE,
  VENDOR_REQUIRED_MESSAGE,
} from '../constants';

const quantitySchema = yup
  .number()
  .nullable()
  .default(null)
  .test('quantity', QUANTITY_REQUIRED_MESSAGE, (v) => !!v);

export const inputPackageSchema = yup.object({
  quantity: quantitySchema,
});

export const inputSchema = yup.object({
  assemblyInputPackages: requireWhenNotSkipped(
    yup.array().of(inputPackageSchema).min(1, `${INPUT_MIN_MESSAGE} (packages)`).required().default([])
  ),
  skip: yup.boolean().required().default(false),
});

export const outputSchema = yup.object({
  skip: yup.boolean().nullable().required().default(false),
  product: yup
    .object({
      productId: requiredNullableNumber('productId', PRODUCT_REQUIRED_MESSAGE),
    })
    .nullable(),
  batchId: requireWhenNotSkipped(
    yup
      .number()
      .nullable()
      .when(['isProductionBatch', '$useMetrcV2'], {
        is: (isProductionBatch: boolean, useMetrcV2: boolean) => isProductionBatch || useMetrcV2,
        then: (schema) => schema.test('batchId', BATCH_REQUIRED_MESSAGE, (v) => !!v),
      })
  ),
  packageDateUtc: yup.date().nullable().required().default(null),
  expirationDateUtc: yup.date().nullable().default(null),
  roomId: requireWhenNotSkipped(requiredNullableNumber('roomId', ROOM_REQUIRED_MESSAGE)),
  inventoryStatusId: yup.number().nullable().default(null),
  vendorId: requireWhenNotSkipped(requiredNullableNumber('vendorId', VENDOR_REQUIRED_MESSAGE)),
  quantity: requireWhenNotSkipped(quantitySchema.default(null)),
  sourceSerialNumber: yup.string().default(''),
  serialNumber: requireWhenNotSkipped(
    yup.string().when('$useBioTrack', {
      is: true,
      then: (schema) => schema.default(''),
      otherwise: (schema) => schema.required(PACKAGE_ID_REQUIRED_MESSAGE).default(''),
    })
  ),
  cost: requireWhenNotSkipped(
    yup
      .number()
      .nullable()
      .default(null)
      .test('cost', (c) => !!c)
  ),
  useByDate: yup.date().nullable().default(null),
  isProductionBatch: requireWhenNotSkipped(yup.boolean().required().default(false)),
  bypassStateSystem: requireWhenNotSkipped(yup.boolean().required().default(false)),
  inputItems: requireWhenNotSkipped(yup.array().of(inputSchema).min(1, INPUT_MIN_MESSAGE).required().default([])),
});

export const outputsSchema = yup.array().of(outputSchema).min(1, OUTPUT_MIN_MESSAGE).required().default([]);

export const assemblySchema = yup.object({
  billOfMaterialsId: requiredNullableNumber('billOfMaterialsId', REQUIRED_BOM_MESSAGE),
  name: yup.string().required(NAME_REQUIRED_MESSAGE).max(200, NAME_LENGTH_MESSAGE).default(''),
  estimatedStartDate: yup.date().nullable().required(START_DATE_REQUIRED_MESSAGE).default(null),
  outputs: outputsSchema,
});

export type AssemblySchema = yup.InferType<typeof assemblySchema>;
