import React from 'react';

import { StyledFormControl } from './form-control.styles';

import type { FormControlProps as MUIFormControlProps } from '@mui/material';

type FormControlProps = {
  $fullWidth?: boolean;
  $labelPlacement?: 'bottom' | 'end' | 'image-uploader' | 'start' | 'toggle' | 'top';
  $width?: string;
  automationId?: string;
  children?: MUIFormControlProps['children'];
  className?: string;
  disabled?: boolean;
  error?: MUIFormControlProps['error'];
  gridColumnGap?: string;
  gridColumns?: number | string;
  hiddenLabel?: MUIFormControlProps['hiddenLabel'];
  id?: string;
  lineHeight?: string;
  multilineLabel?: boolean;
  ref?: MUIFormControlProps['ref'];
  sx?: MUIFormControlProps['sx'];
};

export function FormControl(props: FormControlProps) {
  const {
    gridColumns,
    gridColumnGap,
    lineHeight,
    children,
    className = '',
    multilineLabel,
    $fullWidth,
    $labelPlacement,
    $width,
    disabled,
    sx,
    id,
    hiddenLabel,
    error,
    ref,
    automationId,
  } = props;

  return (
    <StyledFormControl
      $fullWidth={$fullWidth}
      $gridColumnGap={gridColumnGap}
      $gridColumns={gridColumns}
      $labelPlacement={$labelPlacement}
      $lineHeight={lineHeight}
      $multilineLabel={multilineLabel}
      $width={$width}
      children={children}
      className={`form-control ${className}`}
      data-testid={automationId}
      disabled={disabled}
      error={error}
      hiddenLabel={hiddenLabel}
      id={id}
      ref={ref}
      sx={sx}
    />
  );
}
