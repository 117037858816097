/**
 * @file Source of truth for all DataGrid table names.
 * The `name` property on the DataGrid connects the component to user-defined settings that are stored in our database.
 * Changing the value of the string values in this file will result in the DataGrid being disconnected from
 * the user-defined settings. Changing the name of the constants declared here will not result in a breaking change.
 */

// TODO-BACKOFFICE: find all remaining DataGrids that have the table settings `name` property set to an inline string and move those values to this file
export const BillOfMaterialsCopyInputs = 'bill-of-materials-copy-inputs';
export const BillOfMaterialDocuments = 'bill-of-materials-documents';
export const BillOfMaterialAttributes = 'bill-of-materials-attributes';
export const BillOfMaterialDocumentsAdd = 'bill-of-materials-documents-add';
export const BulkUpdateLoyaltyPointsTable = 'bulk-update-loyalty-points';
export const CultivationAuditPlantReconciliationDetailCultivationAudit =
  'cultivation-audit-plant-reconciliation-detail-cultivation-audit';
export const CultivationAuditPlantReconciliationDetailReconciledPlant =
  'cultivation-audit-plant-reconciliation-detail-reconciled-plant';
export const CultivationAuditPlantReconciliationDetailUnreconciledPlant =
  'cultivation-audit-plant-reconciliation-detail-unreconciled-plant';
export const CultivationBatchesBatchManagerHarvestPlants = 'cultivation-batches-batch-manager-harvest-plants';
export const CultivationBatchesBatchManagerPackaging = 'cultivation-batches-batch-manager-packaging';
export const CultivationBatchesJournalEntries = 'cultivation-batches-journal-entries';
export const CultivationScanningValidationIssues = 'cultivation-scanning-validation-issues';
export const CultivationPlantGroupsTrim = 'cultivation-plant-groups-trim';
export const CultivationPlantsAddPlantsSelectBatch = 'cultivation-plants-add-plants-select-batch';
export const CultivationPlantsAddPlantsSelectBatchInModal = 'cultivation-plants-add-plants-select-batch-in-modal';
export const CultivationPlantsAddPlantsSelectMother = 'cultivation-plants-add-plants-select-mother';
export const CultivationPlantsAddPlantsToInventory = 'cultivation-plants-add-plants-to-inventory';
export const CultivationPlantsApplyNutrients = 'cultivation-plants-apply-nutrients';
export const CultivationPlantsJournalEntires = 'cultivation-plants-journal-entries';
export const CultivationPlantsMoveSubroomsIntoRooms = 'cultivation-plants-move-subrooms-into-rooms';
export const CultivationPlantsNutrientLog = 'cultivation-plants-nutrient-log';
export const CultivationPlantsNutrientLogExport = 'cultivation-plants-nutrient-log-export';
export const CultivationPlantsScanPlantsIntoHarvest = 'cultivation-plants-scan-plants-into-harvest';
export const CultivationPlantsScanPlantsIntoRetirement = 'cultivation-plants-scan-plants-into-retirement';
export const CultivationPlantsTransferPlantToInventory = 'cultivation-plants-transfer-plant-to-inventory';
export const CultivationPlantsTrimPlants = 'cultivation-plants-trim-plants';
export const CustomersCustomerGroupsDiscounts = 'customers-customer-groups-discounts';
export const CustomersCustomersFiles = 'customers-customers-files';
export const CustomersCustomersJournal = 'customers-customers-journal';
export const CustomersCustomersProductHistory = 'customers-customers-product-history';
export const CustomersCustomersTransactionHistory = 'customers-customers-transaction-history';
export const CustomersCustomersTransactionHistorySelected = 'customers-customers-transaction-history-selected';
export const HomepageDiscountsToday = 'homepage-discounted-transactions';
export const LocationSync = 'location-sync-table';
export const LocationSyncJobHistories = 'location-sync-job-histories';
export const ManufacturingInfusionsInfusionBatchesBatchDetail = 'manufacturing-infusions-infusion-batches-batch-detail';
export const ManufacturingInfusionsInfusionBatchesBatchDetailLabResultsTest =
  'manufacturing-infusions-infusion-batches-batch-detail-lab-results-step';
export const ManufacturingInfusionsInfusionBatchesInfusionBatchAddFlavours =
  'manufacturing-infusions-infusion-batches-infusion-batch-add-flavours';
export const ManufacturingInfusionsInfusionBatchesInfusionBatchLabResultSteps =
  'manufacturing-infusions-infusion-batches-infusion-batch-lab-result-steps';
export const ManufacturingInfusionsInfusionBatchesInfusionBatchLabResultsBom =
  'manufacturing-infusions-infusion-batches-infusion-batch-lab-results-bom';
export const ManufacturingInfusionsInfusionBatchesInfusionBatchStepDetail =
  'manufacturing-infusions-infusion-batches-infusion-batch-step-detail';
export const ManufacturingInfusionsInfusionBatchesLabResultStepDetail =
  'manufacturing-infusions-infusion-batches-lab-result-step-detail';
export const ManufacturingInfusionsInfusionsInfusionList = 'manufacturing-infusions-infusions-infusion-list';
export const MarketingDiscountsDiscountRestrictBrands = 'marketing-discounts-discount-restrict-brands';
export const MarketingDiscountsDiscountRestrictCategories = 'marketing-discounts-discount-restrict-categories';
export const MarketingDiscountsDiscountRestrictProducts = 'marketing-discounts-discount-restrict-products';
export const MarketingDiscountsDiscountRestrictStrains = 'marketing-discounts-discount-restrict-strains';
export const MarketingDiscountsDiscountRestrictTags = 'marketing-discounts-discount-restrict-tags';
export const MarketingDiscountsDiscountRestrictTiers = 'marketing-discounts-discount-restrict-tiers';
export const MarketingDiscountsDiscountRestrictVendors = 'marketing-discounts-discount-restrict-vendors';
export const MarketingDiscountsDiscountRestrictWeights = 'marketing-discounts-discount-restrict-weights';
export const MarketingDiscountsDiscountsList = 'marketing-discounts-discounts-list';
export const MarketingDiscountsDiscountsListAll = 'marketing-discounts-discounts-list-all';
export const MarketingDiscountsDiscountsListEcomm = 'marketing-discounts-discounts-list-ecomm';
export const MarketingDiscountsDiscountWizardProductList = 'marketing-discounts-discount-wizard-product-list';
export const MarketingDiscountsDiscountWizardEligibleProductList =
  'marketing-discounts-discount-wizard-eligible-product-list';
export const PlantsActionsNotesGrowthPhases = 'plants-actions-notes-growth-phases';
export const PlantsActionsNotesNutrients = 'plants-actions-notes-nutrients';
export const PlantsActionsNotesNutrientsHistory = 'plants-actions-notes-nutrients-history';
export const PlantsActionsScanned = 'plants-actions-scanned';
export const ProductsAuditsAddPackage = 'products-audits-add-package';
export const ProductsAuditsFiles = 'products-audits-files';
export const ProductsAuditsMover = 'products-audits-mover';
export const ProductsConfigureCategoriesProductLabelSettings = 'products-configure-categories-product-label-settings';
export const ProductsConfigurePricingPricingTier = 'products-configure-pricing-pricing-tier';
export const ProductsConfigureProductGroups = 'products-configure-product-groups';
export const ProductsInventory = 'products-inventory';
export const ProductsInventoryAddToOrder = 'products-inventory-add-to-order';
export const ProductsInventoryBulkUploadLabs = 'products-inventory-bulk-upload-labs';
export const ProductsInventoryBulkUploadLabsMappingPreview = 'products-inventory-bulk-upload-labs-mapping-preview';
export const ProductsInventoryBulkUploadReceive = 'products-inventory-bulk-upload-receive';
export const ProductsInventoryBulkUploadReceiveMappingPreview =
  'products-inventory-bulk-upload-receive-mapping-preview';
export const ProductsInventoryCombiner = 'products-inventory-combiner';
export const ProductsInventoryConvertPackages = 'products-inventory-convert-packages';
export const ProductsInventoryGetInvIdByPackageId = 'products-inventory-get-inv-id-by-package-id';
export const ProductsInventoryItemMover = 'products-inventory-item-mover';
export const ProductsInventoryLabelPrinter = 'products-inventory-label-printer';
export const ProductsInventoryLookup = 'products-inventory-lookup';
export const ProductsInventoryManageAllocation = 'products-inventory-manage-allocation';
export const ProductsInventoryMover = 'products-inventory-mover-v2'; // "products-inventory-mover" no longer used
export const ProductsInventoryPackageDiscontinuerNoDiscontinue =
  'products-inventory-package-discontinuer-no-discontinue';
export const ProductsInventoryPackageDiscontinuerToBeRestored =
  'products-inventory-package-discontinuer-to-be-restored';
export const ProductsInventoryPackageHistoryViewer = 'products-inventory-package-history-viewer';
export const ProductsInventoryReceive = 'products-inventory-receive';
export const ProductsInventoryReceiveHistory = 'products-inventory-receive-history';
export const ProductsInventoryReceiveInventoryTransaction = 'products-inventory-receive-inventory-transaction';
export const ProductsInventorySublotter = 'products-inventory-sublotter';
export const ProductsInventoryTransactions = 'products-inventory-transactions';
export const ProductsInventoryTransferToCultivation = 'products-inventory-transfer-to-cultivation';
export const ProductsJournalInput = 'products-journal-input';
export const ProductsJournalOutput = 'products-journal-output';
export const ProductsManifestsMultiStopAddItem = 'products-manifests-multi-stop-add-item';
export const ProductsManifestsMultiStopManifestDetails = 'products-manifests-multi-stop-manifest-details';
export const ProductsManifestsNonMultiStopAddItem = 'products-manifests-non-multi-stop-add-item';
export const ProductsManifestsNonMultiStopHistory = 'products-manifests-non-multi-stop-history';
export const ProductsManifestsNonMultiStopManifestDetails = 'products-manifests-non-multi-stop-manifest-details';
export const ProductsOrdersOrdersItems = 'products-orders-orders-items';
export const ProductsOrdersOrdersItemsSelected = 'products-orders-orders-items-selected';
export const ProductsProductMasterCouponDetailCategories = 'products-product-master-coupon-detail-categories';
export const ProductsProductMasterCouponDetailProducts = 'products-product-master-coupon-detail-products';
export const ProductsProductMasterCouponDetailVendors = 'products-product-master-coupon-detail-vendors';
export const ProductsPurchaseOrders = 'products-purchase-orders';
export const ProductsPurchaseOrdersDetails = 'products-purchase-orders-details';
export const RegistersTransactionsByRegister = 'registers-transactions-by-register';
export const RegistersTransactionsByRegisterDetails = 'registers-transactions-by-register-details';
export const RegistersTransactionsTransactionDetails = 'registers-transactions-transaction-details';
export const RegistersTransactionsTransactionDetailsAppliedTaxes =
  'registers-transactions-transaction-details-applied-taxes';
export const RegistersTransactionsTransactionDetailsPmpSubmissions =
  'registers-transactions-transaction-details-pmp-submissions';
export const ReportsConfigureSchedulerDetailRecipients = 'reports-configure-scheduler-detail-recipients';
export const ReportsConfigureSchedulerDetailReports = 'reports-configure-scheduler-detail-reports';
export const ReportsOverviewProductsAll = 'reports-overview-products-all';
export const ReportsOverviewProductsDetail = 'reports-overview-products-detail';
export const ReportsOverviewSalesByBudtender = 'reports-overview-sales-by-budtender';
export const ReportsOverviewSalesByBudtenderDetail = 'reports-overview-sales-by-budtender-detail';
export const SettingsDevicesPrinters = 'settings-devices-printers';
export const SettingsIntegrationsLeaflinkMenuPreviewBatches = 'settings-integrations-leaflink-menu-preview-batches';
export const SettingsIntegrationsLeaflinkMenuPreviewCustomers = 'settings-integrations-leaflink-menu-preview-customers';
export const SettingsIntegrationsLeaflinkMenuPreviewProducts = 'settings-integrations-leaflink-menu-preview-products';
export const SettingsIntegrationsLeaflinkMenuPreviewStrains = 'settings-integrations-leaflink-menu-preview-strains';
export const SettingsIntegrationsMassCipLogs = 'settings-integrations-mass-cip-logs';
export const SettingsIntegrationsMassCipUsers = 'settings-integrations-mass-cip-users';
export const SettingsIntegrationsMetrcSettings = 'settings-integrations-metrc-settings';
export const SettingsIntegrationsMetrcUserKeys = 'settings-integrations-metrc-user-keys';
export const SettingsIntegrationsSkylight = 'settings-integrations-skylight';
export const SettingsIntegrationsSpin = 'settings-integrations-spin';
export const SettingsIntegrationsSpringbigRepostTransactions = 'settings-integrations-springbig-repost-transactions';
export const SettingsIntegrationsSpringbigRepostTransactionsErrors =
  'settings-integrations-springbig-repost-transactions-errors';
export const SettingsIntegrationsSsoSsoUserFederationIds = 'settings-integrations-sso-sso-user-federation-ids';
export const SettingsIntegrationsTranCloudDevices = 'settings-integrations-tran-cloud-devices';
export const SettingsIntegrationsWeedmaps2Logs = 'settings-integrations-weedmaps2-logs';
export const SettingsIntegrationsWeedmaps2MenuPreview = 'settings-integrations-weedmaps2-menu-preview';
export const SettingsIntegrationsWeedmaps2MenuPreviewDroppedProducts =
  'settings-integrations-weedmaps2-menu-preview-dropped-products';
export const SettingsIntegrationsWeedmaps2MenuPreviewVerifiedmenu =
  'settings-integrations-weedmaps2-menu-preview-verifiedmenu';
export const SettingsRoomsDetails = 'settings-rooms-details';
export const SettingsTaxesAndComplianceCustomerAccessLogs = 'settings-taxes-and-compliance-customer-access-logs';
export const SettingsUsersAssignToGroups = 'settings-users-assign-to-groups';
export const SettingsUsersPermissionGroups = 'settings-users-permission-groups';
export const SettingsUsersUserPermissionsSelectUsers = 'settings-users-user-permissions-select-users';
export const SuperuserImportDataLoaderItemsErrors = 'superuser-import-data-loader-items-errors';
export const SuperuserImportDataLoaderItemsStagedData = 'superuser-import-data-loader-items-staged-data';
export const SuperuserLicenceTypesApplyLicensePreview = 'superuser-licence-types-apply-license-preview';
export const SuperuserLicenceTypesDetailsTemplate = 'superuser-licence-types-details-template';
export const SuperuserLicenceTypesDetailsTemplatePreview = 'superuser-licence-types-details-template-preview';
export const SuperuserLicenceTypesPreviewTaxCategories = 'superuser-licence-types-preview-tax-categories';
export const SuperuserLicenceTypesPreviewTaxRates = 'superuser-licence-types-preview-tax-rates';
export const SuperuserManagement = 'superuser-management';
export const SuperuserMetrcToolsSuperuserMetrcSync = 'superuser-metrc-tools-superuser-metrc-sync';
export const SuperuserPublicApi = 'superuser-public-api';
export const TraceabilityLogsBatchMode = 'traceability-logs-batch-mode';
export const TraceabilityPlantDiscrepancies = 'traceability-plant-discrepancies';
export const ReportSchedulerList = 'reports-configure-scheduler';
export const LDExplorer = 'ld-explorer';
export const CustomerMergeHistory = 'customer-merge-history';
export const CustomerSegmentList = 'customer-segment-list';
export const CustomerSegmentAudience = 'customer-segment-audience';
export const CustomerSegmentDiscounts = 'customer-segment-discounts';
export const CustomerSegmentReport = 'customer-segment-report';
