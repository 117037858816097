import React from 'react';

export function SuppliersIcon() {
  return (
    <svg
      className='stroke-overrides'
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2090_2842)' id='Truck-Cargo-1--Streamline-Ultimate'>
        <path
          d='M16.5 20.5879C16.5 21.0852 16.6975 21.5621 17.0492 21.9137C17.4008 22.2653 17.8777 22.4629 18.375 22.4629C18.8723 22.4629 19.3492 22.2653 19.7008 21.9137C20.0525 21.5621 20.25 21.0852 20.25 20.5879C20.25 20.0906 20.0525 19.6137 19.7008 19.2621C19.3492 18.9104 18.8723 18.7129 18.375 18.7129C17.8777 18.7129 17.4008 18.9104 17.0492 19.2621C16.6975 19.6137 16.5 20.0906 16.5 20.5879Z'
          id='Vector'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M0.75 20.5879C0.75 21.0852 0.947544 21.5621 1.29917 21.9137C1.65081 22.2653 2.12772 22.4629 2.625 22.4629C3.12228 22.4629 3.59919 22.2653 3.95083 21.9137C4.30246 21.5621 4.5 21.0852 4.5 20.5879C4.5 20.0906 4.30246 19.6137 3.95083 19.2621C3.59919 18.9104 3.12228 18.7129 2.625 18.7129C2.12772 18.7129 1.65081 18.9104 1.29917 19.2621C0.947544 19.6137 0.75 20.0906 0.75 20.5879Z'
          id='Vector_2'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M4.5 20.5879C4.5 21.0852 4.69754 21.5621 5.04917 21.9137C5.40081 22.2653 5.87772 22.4629 6.375 22.4629C6.87228 22.4629 7.34919 22.2653 7.70083 21.9137C8.05246 21.5621 8.25 21.0852 8.25 20.5879C8.25 20.0906 8.05246 19.6137 7.70083 19.2621C7.34919 18.9104 6.87228 18.7129 6.375 18.7129C5.87772 18.7129 5.40081 18.9104 5.04917 19.2621C4.69754 19.6137 4.5 20.0906 4.5 20.5879Z'
          id='Vector_3'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M12 20.2129V17.2129H21.75C22.1478 17.2129 22.5294 17.3709 22.8107 17.6522C23.092 17.9335 23.25 18.3151 23.25 18.7129C23.25 19.1107 23.092 19.4922 22.8107 19.7736C22.5294 20.0549 22.1478 20.2129 21.75 20.2129H20.213'
          id='Vector_4'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M22.5 17.4139V11.2139C22.5 10.0204 22.0259 8.8758 21.182 8.03189C20.3381 7.18797 19.1935 6.71387 18 6.71387H12V17.2139'
          id='Vector_5'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M12 6.71323V3.27223C11.9999 3.04715 12.0504 2.82493 12.1479 2.62204C12.2453 2.41915 12.3872 2.24079 12.563 2.10018C12.7387 1.95957 12.9439 1.86032 13.1632 1.80978C13.3825 1.75925 13.6104 1.75872 13.83 1.80823C15.422 2.16523 17.544 2.80023 18 3.71323C18.4285 4.65997 18.6825 5.67626 18.75 6.71323V6.77623'
          id='Vector_6'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M9 15.7129H0.75'
          id='Vector_7'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M22.5 13.4629H12'
          id='Vector_8'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M16.5 6.71289V13.4629'
          id='Vector_9'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M9 17.2129V2.96289C9 2.56507 8.84196 2.18353 8.56066 1.90223C8.27936 1.62093 7.89782 1.46289 7.5 1.46289H0.75'
          id='Vector_10'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          d='M16.5379 20.2129H11.9999H8.21094'
          id='Vector_11'
          stroke='var(--color-gray-70)'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_2090_2842'>
          <rect fill='white' height='24' width='24' />
        </clipPath>
      </defs>
    </svg>
  );
}
