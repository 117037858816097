import React, { lazy, Suspense } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Permissions, SuperUserPermissions } from 'src/app_deprecated/constants/PermissionsConstants';
import PermissionStore from 'src/app_deprecated/stores/PermissionStore';

import { StatusBanner } from 'src/app/components/banner/status-banner';
import { Page } from 'src/app/components/lib/page';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useBOMPermissions } from 'src/app/hooks/use-bom-permissions';
import { useGeneriPayFlag } from 'src/app/hooks/use-generipay-flag';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useViewOrgManagementEnabled } from 'src/app/hooks/use-view-org-management';
import PageDisabledEmptyState from 'src/app/layout/main/page-disabled-empty-state';
import { Segments } from 'src/app/pages/customers/customers/segments';
import Assemblies from 'src/app/pages/manufacturing/assemblies';
import { routes } from 'src/app/routes';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { usePermissionCheck, userPermissionsAtom } from 'src/app/state/user-permissions';
import { hasRemoteInitialized } from 'src/app/utils/module-federation';

import { DarklyLaunchedComponent } from './darkly-launched-component';
import { E2EOnlyPage, E2EOnlyPageComponent } from './e2e-only';
import { PrivateComponent } from './private-component';

import type { DomainAndPageLevelKillSwitchEvaluation } from './main.types';

/* MAIN */
const Dashboard = lazy(() => import('src/app/pages/home/homepage'));
const Placeholder = lazy(() => import('src/app/layout/main/placeholder'));

/* SUPPLIERS */
const Suppliers = lazy(() => import('src/app/pages/suppliers'));

/* PRODUCTS */
const InventoryV2 = lazy(() => import('src/app/pages/products/inventory-v2'));
const Catalog = lazy(() => import('src/app/pages/products/catalog'));
const Manifests = lazy(() => import('src/app/pages/products/manifests'));
const PurchaseOrders = lazy(() => import('src/app/pages/products/purchase-orders'));
const AuditsV2 = lazy(() => import('src/app/pages/products/audits-v2'));
const Journal = lazy(() => import('src/app/pages/products/journal'));
const Manufacturers = lazy(() => import('src/app/pages/products/manufacturers'));
const Vendors = lazy(() => import('src/app/pages/products/vendors'));
const VendorsV2 = lazy(() => import('src/app/pages/products/vendors-v2'));
const Brands = lazy(() => import('src/app/pages/products/brands'));
const BrandsV2 = lazy(() => import('src/app/pages/products/brands-v2'));
const Strains = lazy(() => import('src/app/pages/products/strains'));
const StrainsV2 = lazy(() => import('src/app/pages/products/strains-v2'));
const ProductsConfigure = lazy(() => import('src/app/pages/products/configure'));
const Orders = lazy(() => import('src/app/pages/products/orders'));

/* REPORTS */
const Reports = lazy(() => import('src/app/pages/reports-v2'));

/* FINANCIALS */
const Financials = lazy(() => import('src/app/pages/financials'));

/* CUSTOMERS */
const Customers = lazy(() => import('src/app/pages/customers/customers'));
const CustomerGroups = lazy(() => import('src/app/pages/customers/customer-groups'));
const CustomersConfigure = lazy(() => import('src/app/pages/customers/configure'));

/* REGISTERS */
const RegistersOverview = lazy(() => import('src/app/pages/registers/overview'));
const RegistersConfigure = lazy(() => import('src/app/pages/registers/configure'));
const BulkRegistersTransactions = lazy(() => import('src/app/pages/registers/transactions/bulkregister'));
const RegistersTransactions = lazy(() => import('src/app/pages/registers/transactions'));
const RegisterTransactions = lazy(() => import('src/app/pages/registers/transactions/by-register-details'));

/* MARKETING */
const Discounts = lazy(() => import('src/app/pages/marketing/discounts'));
const Loyalty = lazy(() => import('src/app/pages/marketing/loyalty'));
const ExternalLoyalty = lazy(() => import('src/app/pages/marketing/external-loyalty'));
const Campaigns = lazy(() => import('src/app/pages/marketing/campaigns'));

/* DELIVERY */
const DeliveryConfigure = lazy(() => import('src/app/pages/delivery/configure'));

/* CULTIVATION */
const CultivationApiQueue = lazy(() => import('src/app/pages/cultivation/cultivation-api-queue'));
const Plants = lazy(() => import('src/app/pages/cultivation/plants'));
const PlantsV2 = lazy(() => import('src/app/pages/cultivation/plants-v2'));
const PlantGroups = lazy(() => import('src/app/pages/cultivation/plants/plant-groups'));
const Harvests = lazy(() => import('src/app/pages/cultivation/harvests'));
const Batches = lazy(() => import('src/app/pages/cultivation/batches'));
const ScanPlantsIntoRoom = lazy(() => import('src/app/pages/cultivation/plants/scan-plants-into-room'));
const ScanPlantsIntoHarvest = lazy(() => import('src/app/pages/cultivation/plants/scan-plants-into-harvest'));
const ScanPlantsIntoRetirement = lazy(() => import('src/app/pages/cultivation/plants/scan-plants-into-retirement'));
const MoveSubroomsIntoRooms = lazy(() => import('src/app/pages/cultivation/plants/move-subrooms-into-rooms'));
const AddPlants = lazy(() => import('src/app/pages/cultivation/plants/add-plants-to-inventory'));
const Waste = lazy(() => import('src/app/pages/cultivation/waste'));
const CultivationConfigure = lazy(() => import('src/app/pages/cultivation/configure'));

/* MANUFACTURING */
const Recipes = lazy(() => import('src/app/pages/manufacturing/recipes'));
const Infusions = lazy(() => import('src/app/pages/manufacturing/infusions'));
const Distillations = lazy(() => import('src/app/pages/manufacturing/distillations'));
const BillOfMaterials = lazy(() => import('src/app/pages/manufacturing/bill-of-materials'));

/* TRACEABILITY */
const IntegrationAudit = lazy(() => import('src/app/pages/traceability/integration-audit'));
const CultivationAudit = lazy(() => import('src/app/pages/cultivation/cultivation-audit'));
const CultivationIntegrationAudit = lazy(() => import('src/app/pages/traceability/cultivation-integration-audit'));
const ReceiptAudit = lazy(() => import('src/app/pages/traceability/receipt-audit'));
const Logs = lazy(() => import('src/app/pages/traceability/logs'));

/* ORG MANAGEMENT */
const OrgManagement = lazy(() => import('src/app/pages/org-management'));

/* SETTINGS */
const Account = lazy(() => import('src/app/pages/settings/account'));
const Users = lazy(() => import('src/app/pages/settings/users'));
const Integrations = lazy(() => import('src/app/pages/settings/integrations'));
const Devices = lazy(() => import('src/app/pages/settings/devices'));
const Receipts = lazy(() => import('src/app/pages/settings/receipts/index'));
const Rooms = lazy(() => import('src/app/pages/settings/rooms'));
const Labels = lazy(() => import('src/app/pages/settings/labels'));
const TaxesAndCompliance = lazy(() => import('src/app/pages/settings/taxes-and-compliance'));
const Location = lazy(() => import('src/app/pages/settings/location'));
const TimeClock = lazy(() => import('src/app/pages/settings/time-clock'));
const FeesAndDonations = lazy(() => import('src/app/pages/settings/fees-and-donations'));

/* SETTINGS/INTEGRATIONS */
const IntegrationsMetrc = lazy(() => import('src/app/pages/settings/integrations/metrc'));
const IntegrationsBioTrack = lazy(() => import('src/app/pages/settings/integrations/biotrack'));
const MCDMSIntegration = lazy(() => import('src/app/pages/settings/integrations/mcdms'));
const VirginiaIntegration = lazy(() => import('src/app/pages/settings/integrations/virginia-biotrack'));
const IntegrationsTrym = lazy(() => import('src/app/pages/settings/integrations/trym'));
const CanPay = lazy(() => import('src/app/pages/settings/integrations/canpay'));
const CashlessAtm = lazy(() => import('src/app/pages/settings/integrations/cashless-atm'));
const PlanetPayments = lazy(() => import('src/app/pages/settings/integrations/planetpayments'));
const DutchiePay = lazy(() => import('src/app/pages/settings/integrations/dutchiepay'));
const WeedMaps = lazy(() => import('src/app/pages/settings/integrations/weedmaps'));
const WeedMaps2 = lazy(() => import('src/app/pages/settings/integrations/weedmaps2'));
const SSO = lazy(() => import('src/app/pages/settings/integrations/sso'));
const LeafLink = lazy(() => import('src/app/pages/settings/integrations/leaflink'));
const CCRS = lazy(() => import('src/app/pages/settings/integrations/ccrs'));
const IdScan = lazy(() => import('src/app/pages/settings/integrations/idscan'));
const Springbig = lazy(() => import('src/app/pages/settings/integrations/springbig'));

const Linx = lazy(() => import('src/app/pages/settings/integrations/linx'));
const Leaf = lazy(() => import('src/app/pages/settings/integrations/leaf'));
const MMUR = lazy(() => import('src/app/pages/settings/integrations/mmur'));
const Adilas = lazy(() => import('src/app/pages/settings/integrations/adilas'));
const Leafly = lazy(() => import('src/app/pages/settings/integrations/leafly'));
const Quickbooks = lazy(() => import('src/app/pages/settings/integrations/quickbooks'));
const MN = lazy(() => import('src/app/pages/settings/integrations/mn'));
const Tookan = lazy(() => import('src/app/pages/settings/integrations/tookan'));
const Onfleet = lazy(() => import('src/app/pages/settings/integrations/onfleet'));
const SkylightIntegration = lazy(() => import('src/app/pages/settings/integrations/skylight'));
const TranCloudIntegration = lazy(() => import('src/app/pages/settings/integrations/tran-cloud'));
const BioMaurisIntegration = lazy(() => import('src/app/pages/settings/integrations/bio-mauris'));
const AplineIQIntegration = lazy(() => import('src/app/pages/settings/integrations/alpine-iq'));
const Fyllo = lazy(() => import('src/app/pages/settings/integrations/fyllo'));
const Spin = lazy(() => import('src/app/pages/settings/integrations/spin'));
const WorkWave = lazy(() => import('src/app/pages/settings/integrations/workwave'));
const PMP = lazy(() => import('src/app/pages/settings/integrations/pmp'));
const MassCIP = lazy(() => import('src/app/pages/settings/integrations/mass-cip'));
const Hypur = lazy(() => import('src/app/pages/settings/integrations/hypur'));
const Sprout = lazy(() => import('src/app/pages/settings/integrations/sprout'));
const OCSSettings = lazy(() => import('src/app/pages/settings/integrations/ocs'));
const VCCBSettings = lazy(() => import('src/app/pages/settings/integrations/vccb'));
const UtahICSSettings = lazy(() => import('src/app/pages/settings/integrations/utahics'));
const Paynetworx = lazy(() => import('src/app/pages/settings/integrations/paynetworx'));
const PaymentsHub = lazy(() => import('src/app/pages/settings/integrations/paymentshub'));
const NonIntegratedPayments = lazy(() => import('src/app/pages/settings/integrations/non-integrated-payments'));
const GeneriPayIntegration = lazy(() => import('src/app/pages/settings/integrations/generipay'));
const EmailMarketingIntegration = lazy(() => import('src/app/pages/settings/integrations/email-marketing'));
const SMSMarketingIntegration = lazy(() => import('src/app/pages/settings/integrations/sms-marketing'));

/* SUPERUSER */
const SuperuserTools = lazy(() => import('src/app/pages/superuser/tools'));
const SuperuserLogs = lazy(() => import('src/app/pages/superuser/logs'));
const SuperuserLocationManager = lazy(() => import('src/app/pages/superuser/staging-tools/location-manager'));
const SuperuserManagement = lazy(() => import('src/app/pages/superuser/management'));
const SuperuserSSOGlobalConfig = lazy(() => import('src/app/pages/superuser/sso-globalconfig'));
const SuperUserEditManifest = lazy(() => import('src/app/pages/superuser/manifest-options/superuser-manifest-options'));
const SuperUserEditManifestV2 = lazy(
  () => import('src/app/pages/superuser/manifest-options/superuser-manifest-options-v2')
);
const AuditTables = lazy(() => import('src/app/pages/superuser/audit-tables'));
const ProvincialCatalogUpload = lazy(() => import('src/app/pages/superuser/provincial-catalog-upload'));
const MetrcTools = lazy(() => import('src/app/pages/superuser/metrc-tools'));
const FeatureFlags = lazy(() => import('src/app/pages/superuser/feature-flags'));
const CreateEditLsp = lazy(() => import('src/app/pages/superuser/edit-lsp'));
const CreateLsp = lazy(() => import('src/app/pages/superuser/create-lsp'));
const CreateSmbLsp = lazy(() => import('src/app/pages/superuser/create-smb-lsp'));
const PublicAPI = lazy(() => import('src/app/pages/superuser/public-api'));
const LookerAdmin = lazy(() => import('src/app/pages/superuser/looker-admin'));
const LookerAdminV2 = lazy(() => import('src/app/pages/superuser/looker-admin-v2'));
const Documentation = lazy(() => import('src/app/pages/superuser/documentation'));
const BulkUpdater = lazy(() => import('src/app/pages/superuser/bulk-update-data'));
const DataImport = lazy(() => import('src/app/pages/superuser/staging-tools/data-import'));
const DataWipe = lazy(() => import('src/app/pages/superuser/staging-tools/data-wipe'));
const SuperuserPublishLocation = lazy(() => import('src/app/pages/superuser/staging-tools/publish-location'));
const LicenseTypes = lazy(() => import('src/app/pages/superuser/licence-types'));
const LoaderMain = lazy(() => import('src/app/pages/superuser/import-data'));

/* OTHER */
const HelpTutorials = lazy(() => import('src/app/pages/help'));

function Main() {
  const userDispensaries = useRecoilValue(userDispensariesAtom);
  const flags = useFlags();
  const { canViewBoms, canViewAssemblies } = useBOMPermissions();
  const useSmbPacks = flags['pos.tools.small-business-packs'];
  const renderE2EOnlyRoute = flags['pos.backoffice.e2e-suite.operational'];
  const protectSuperuserUrlsRollout = flags['pos.platform.protect-superuser-endpoint.rollout'];
  const isSuperUser = usePermissionCheck([Permissions.SuperUser]);
  const isSuperUserVisible = !protectSuperuserUrlsRollout || (protectSuperuserUrlsRollout && isSuperUser);

  // See https://dutchie.atlassian.net/wiki/spaces/EN/pages/20255735854/POS+Killswitches#Back-office-Domain-and-Page-Level-Kill-Switches
  // for info on how to use these
  const killswitches = useLDFlag<DomainAndPageLevelKillSwitchEvaluation>(LD.KILL_SWITCH_BY_DOMAIN_OR_PAGE, {});
  // Home page kill switch
  const isHomePageDisabled = killswitches?.home === true;
  // Products kill switches
  const areAllProductsPagesDisabled = killswitches?.products?.wholeDomain === true;
  const isInventoryPageDisabled = killswitches?.products?.inventory === true;
  const isCatalogPageDisabled = killswitches?.products?.catalog === true;
  const isManifestPageDisabled = killswitches?.products?.manifests === true;
  const isPurchaseOrdersPageDisabled = killswitches?.products?.purchaseOrders === true;
  const isOrdersPageDisabled = killswitches?.products?.orders === true;
  const isAuditsPageDisabled = killswitches?.products?.audits === true;
  const isJournalsPageDisabled = killswitches?.products?.journals === true;
  const isVendorsPageDisabled = killswitches?.products?.vendors === true;
  const isManufacturersPageDisabled = killswitches?.products?.manufacturers === true;
  const isBrandsPageDisabled = killswitches?.products?.brands === true;
  const isStrainsPageDisabled = killswitches?.products?.strains === true;
  const isProductsConfigurePageDisabled = killswitches?.products?.productsConfigure === true;
  // Financials kill switches
  const areAllFinancialsPagesDisabled = killswitches?.financials?.wholeDomain === true;
  // Reports kill switches
  const areAllReportsPagesDisabled = killswitches?.reports?.wholeDomain === true;
  // Customers kill switches
  const areAllCustomersPagesDisabled = killswitches?.customers?.wholeDomain === true;
  const isCustomersPageDisabled = killswitches?.customers?.customers === true;
  const isSegmentsPageDisabled = killswitches?.customers?.segments === true;
  const isCustomerGroupsPageDisabled = killswitches?.customers?.customerGroups === true;
  const isCustomersConfigurePageDisabled = killswitches?.customers?.customersConfigure === true;
  // Registers kill switches
  const areAllRegistersPagesDisabled = killswitches?.registers?.wholeDomain === true;
  // Marketing kill switches
  const areAllMarketingPagesDisabled = killswitches?.marketing?.wholeDomain === true;
  // Delivery kill switch
  const areAllDeliveryPagesDisabled = killswitches?.delivery?.wholeDomain === true;
  // Cultivation kill switch
  const areAllCultivationPagesDisabled = killswitches?.cultivation?.wholeDomain === true;
  // Manufacturing kill switch
  const areAllManufacturingPagesDisabled = killswitches?.manufacturing?.wholeDomain === true;
  // Traceability kill switch
  const areAllTraceabilityPagesDisabled = killswitches?.traceability?.wholeDomain === true;
  // Settings kill switches
  const areAllSettingsPagesDisabled = killswitches?.settings?.wholeDomain === true;
  // Superuser kill switches
  const areAllSuperuserPagesDisabled = killswitches?.superuser?.wholeDomain === true;
  const isBulkUpdaterPageDisabled = killswitches?.superuser?.bulkUpdater === true;
  const isDataLoaderPageDisabled = killswitches?.superuser?.dataLoader === true;
  const isDataPurgerPageDisabled = killswitches?.superuser?.dataPurger === true;

  // Superuser/manifest options switch v1/v2
  const manifestOptionsV2 = flags['pos.traceability.manifest-test-date.rollout'];

  const { perms } = useRecoilValue(userPermissionsAtom);
  const canViewNonIntegratedPayments =
    userDispensaries.locProfile.EnableManualPayments &&
    (perms[Permissions.ViewNonIntegratedPaymentsIntegrationConfiguration] ||
      perms[Permissions.EditNonIntegratedPaymentsIntegrationConfiguration]);
  const canViewCampaigns = usePermissionCheck(
    [
      Permissions.AccessEmailMarketing,
      Permissions.ViewCampaigns,
      Permissions.CreateEditCampaigns,
      Permissions.SendCampaigns,
    ],
    'or'
  );

  const generipayFlag = useGeneriPayFlag();
  const canViewOrgManagementPage = useViewOrgManagementEnabled();

  const { IsLicensed } = userDispensaries.locProfile;
  const canViewLocationManagerPage = PermissionStore.superUserCan(SuperUserPermissions.AccessLocationManager);

  return (
    <div className='rebrand_container'>
      <StatusBanner />
      <Suspense fallback={<div />}>
        <Routes>
          {/* MAIN  */}
          {isHomePageDisabled ? (
            <>
              <Route element={<PageDisabledEmptyState />} path='/' />
              <Route element={<PageDisabledEmptyState />} path='/home' />
            </>
          ) : (
            <>
              <Route element={<Dashboard />} path='/' />
              <Route element={<Dashboard />} path={routes.home} />
              <Route element={<Placeholder />} path='/boh-v2-placeholder' />
            </>
          )}

          {/* SUPPLIERS */}
          {hasRemoteInitialized('backoffice-remote-suppliers') && (
            <Route element={<Suppliers />} path={routes.suppliers} />
          )}

          {/* PRODUCTS */}
          {areAllProductsPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/products/*' />
          ) : (
            <>
              <Route
                element={isInventoryPageDisabled ? <PageDisabledEmptyState /> : <InventoryV2 />}
                path={`${routes.products.inventory.index}/*`}
              />
              <Route
                element={isCatalogPageDisabled ? <PageDisabledEmptyState /> : <Catalog user={userDispensaries} />}
                path={`${routes.products.catalog.index}/*`}
              />
              <Route
                element={isManifestPageDisabled ? <PageDisabledEmptyState /> : <Manifests />}
                path={`${routes.products.manifests.index}/*`}
              />
              <Route
                element={isPurchaseOrdersPageDisabled ? <PageDisabledEmptyState /> : <PurchaseOrders />}
                path={`${routes.products.purchaseOrders.index}/*`}
              />
              <Route
                element={isOrdersPageDisabled ? <PageDisabledEmptyState /> : <Orders />}
                path={`${routes.products.orders}/*`}
              />
              {/* Product Audits Start */}
              <Route
                element={
                  isAuditsPageDisabled ? (
                    <PageDisabledEmptyState />
                  ) : (
                    <PrivateComponent component={<AuditsV2 />} permission={Permissions.ViewReconciliation} />
                  )
                }
                path={`${routes.products.audits.index}/*`}
              />
              {/* Product Audits End */}
              <Route
                element={isJournalsPageDisabled ? <PageDisabledEmptyState /> : <Journal user={userDispensaries} />}
                path={routes.products.journal}
              />
              <Route
                element={
                  isVendorsPageDisabled ? (
                    <PageDisabledEmptyState />
                  ) : (
                    <DarklyLaunchedComponent
                      component={<VendorsV2 />}
                      fallback={<Vendors user={userDispensaries} />}
                      flag='pos.backoffice.vendors-v2.rollout'
                    />
                  )
                }
                path={`${routes.products.vendors}/*`}
              />
              <Route
                element={
                  isManufacturersPageDisabled ? <PageDisabledEmptyState /> : <Manufacturers user={userDispensaries} />
                }
                path={routes.products.manufacturers}
              />
              <Route
                element={
                  isBrandsPageDisabled ? (
                    <PageDisabledEmptyState />
                  ) : (
                    <DarklyLaunchedComponent
                      component={<BrandsV2 />}
                      fallback={<Brands user={userDispensaries} />}
                      flag='pos.backoffice.brands-v2.rollout'
                    />
                  )
                }
                path={`${routes.products.brands}/*`}
              />
              <Route
                element={
                  isStrainsPageDisabled ? (
                    <PageDisabledEmptyState />
                  ) : (
                    <DarklyLaunchedComponent
                      component={<StrainsV2 />}
                      fallback={<Strains user={userDispensaries} />}
                      flag='pos.backoffice.strains-v2.rollout'
                    />
                  )
                }
                path={`${routes.products.strains}/*`}
              />
              <Route
                element={isProductsConfigurePageDisabled ? <PageDisabledEmptyState /> : <ProductsConfigure />}
                path={`${routes.products.configure.index}/*`}
              />
            </>
          )}
          {/* FINANCIALS */}
          <Route
            element={areAllFinancialsPagesDisabled ? <PageDisabledEmptyState /> : <Financials />}
            path={`${routes.financials.index}/*`}
          />
          {/* REPORTS */}
          <Route
            element={areAllReportsPagesDisabled ? <PageDisabledEmptyState /> : <Reports />}
            path={`${routes.reportsV2.index}/*`}
          />
          {/* CUSTOMERS */}
          {areAllCustomersPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/customers/*' />
          ) : (
            <>
              <Route
                element={isCustomersPageDisabled ? <PageDisabledEmptyState /> : <Customers user={userDispensaries} />}
                path={`${routes.customers.customers}/*`}
              />
              <Route
                element={
                  isSegmentsPageDisabled ? (
                    <PageDisabledEmptyState />
                  ) : (
                    <DarklyLaunchedComponent
                      component={<Segments />}
                      flag='pos.backoffice.customers.project-chunk.rollout'
                    />
                  )
                }
                path={`${routes.customers.segments.index}/*`}
              />
              <Route
                element={isCustomerGroupsPageDisabled ? <PageDisabledEmptyState /> : <CustomerGroups />}
                path={`${routes.customers.customerGroups}/*`}
              />
              <Route
                element={isCustomersConfigurePageDisabled ? <PageDisabledEmptyState /> : <CustomersConfigure />}
                path={`${routes.customers.configure}/*`}
              />
            </>
          )}
          {/* REGISTERS */}
          {areAllRegistersPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/registers/*' />
          ) : (
            <>
              <Route
                element={
                  <RegistersOverview
                    user={userDispensaries}
                    useRegisterRedirectToken={flags['pos.iam.backoffice-to-register-login.rollout']}
                  />
                }
                path={routes.registers.overview}
              />
              <Route element={<RegistersConfigure />} path={`${routes.registers.configure.index}/*`} />
              <Route
                element={<BulkRegistersTransactions />}
                path={`${routes.registers.transactions.bulkActions.index}/*`}
              />
              <Route element={<RegistersTransactions />} path={`${routes.registers.transactions.index}/*`} />
              <Route
                element={<RegisterTransactions user={userDispensaries} />}
                path={`${routes.registers.transactions.byRegister}/:TerminalId`}
              />
            </>
          )}
          {/* MARKETING */}
          {areAllMarketingPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/marketing/*' />
          ) : (
            <>
              <Route element={<Discounts user={userDispensaries} />} path={`${routes.marketing.discounts.index}/*`} />
              <Route element={<Loyalty />} path={`${routes.marketing.loyalty.settings}/*`} />
              <Route
                element={
                  <PrivateComponent component={<ExternalLoyalty />} permission={Permissions.ConfigureExternalLoyalty} />
                }
                path={`${routes.marketing.externalLoyalty.index}/*`}
              />
              <Route
                element={
                  <DarklyLaunchedComponent
                    component={
                      <PrivateComponent booleanPermissionOverride={canViewCampaigns} component={<Campaigns />} />
                    }
                    flag={LD.PROJECT_CHUNK_CAMPAIGNS}
                  />
                }
                path={`${routes.marketing.campaigns.index}/*`}
              />
            </>
          )}
          {/* DELIVERY */}
          <Route
            element={areAllDeliveryPagesDisabled ? <PageDisabledEmptyState /> : <DeliveryConfigure />}
            path={`${routes.delivery.configure.index}/*`}
          />
          {/* CULTIVATION */}
          {IsLicensed &&
            (areAllCultivationPagesDisabled ? (
              <Route element={<PageDisabledEmptyState />} path='/cultivation/*' />
            ) : (
              <>
                <Route
                  element={<CultivationApiQueue user={userDispensaries} />}
                  path={routes.cultivation.cultivationApiQueue}
                />

                {/* Show PlantsV2 when the refactor flag is enabled */}
                {flags['pos.backoffice.cultivation-refactor.rollout'] ? (
                  <Route
                    element={<PrivateComponent component={<PlantsV2 />} permission={Permissions.ViewPlants} />}
                    path={`${routes.cultivation.plants.index}/*`}
                  />
                ) : (
                  <Route element={<Plants />} path={`${routes.cultivation.plants.index}/*`} />
                )}

                {/* Add separate Plant Groups route when the refactor flag is enabled */}
                {flags['pos.backoffice.cultivation-refactor.rollout'] && (
                  <Route
                    element={
                      <PrivateComponent
                        component={
                          <Page>
                            <PlantGroups user={userDispensaries} />
                          </Page>
                        }
                        permission={Permissions.ViewPlantGroups}
                      />
                    }
                    path={`${routes.cultivation.plantGroups}/*`}
                  />
                )}

                <Route
                  element={<ScanPlantsIntoRoom user={userDispensaries} />}
                  path={routes.cultivation.plants.scanPlantsIntoRoom}
                />
                <Route
                  element={<ScanPlantsIntoHarvest user={userDispensaries} />}
                  path={routes.cultivation.plants.scanPlantsIntoHarvest}
                />
                <Route
                  element={<ScanPlantsIntoRetirement user={userDispensaries} />}
                  path={routes.cultivation.plants.scanPlantsIntoRetirement}
                />
                <Route
                  element={<MoveSubroomsIntoRooms user={userDispensaries} />}
                  path={routes.cultivation.plants.moveSubroomsIntoRooms}
                />
                <Route
                  element={
                    <AddPlants defaultPlantType='Clones' motherId='' showHeader showReset user={userDispensaries} />
                  }
                  path={routes.cultivation.plants.addPlantIntoInventory}
                />
                <Route element={<Harvests user={userDispensaries} />} path={routes.cultivation.harvests} />
                <Route element={<Batches user={userDispensaries} />} path={routes.cultivation.batches} />
                <Route element={<Waste user={userDispensaries} />} path={routes.cultivation.waste} />
                <Route element={<CultivationAudit user={userDispensaries} />} path={`${routes.cultivation.audits}/*`} />
                <Route element={<CultivationConfigure />} path={`${routes.cultivation.configure.index}/*`} />
              </>
            ))}
          {/* MANUFACTURING */}
          {areAllManufacturingPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/manufacturing/*' />
          ) : (
            <>
              <Route element={<Recipes />} path={`${routes.manufacturing.recipes}/*`} />
              <Route
                element={
                  <DarklyLaunchedComponent
                    component={
                      <PrivateComponent booleanPermissionOverride={canViewBoms} component={<BillOfMaterials />} />
                    }
                    flag='backoffice.bill-of-materials-and-assemblies.rollout'
                  />
                }
                path={`${routes.manufacturing.billOfMaterials}/*`}
              />
              <Route
                element={
                  <DarklyLaunchedComponent
                    component={
                      <PrivateComponent booleanPermissionOverride={canViewAssemblies} component={<Assemblies />} />
                    }
                    flag='backoffice.bill-of-materials-and-assemblies.rollout'
                  />
                }
                path={`${routes.manufacturing.assemblies}/*`}
              />
              <Route element={<Infusions />} path={`${routes.manufacturing.infusions}/*`} />
              <Route
                element={<Distillations user={userDispensaries} />}
                path={`${routes.manufacturing.distillations}/*`}
              />
            </>
          )}
          {/* TRACEABILITY */}
          {areAllTraceabilityPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/traceability/*' />
          ) : (
            <>
              <Route
                element={<IntegrationAudit user={userDispensaries} />}
                path={routes.traceability.integrationAudit}
              />
              <Route
                element={<CultivationIntegrationAudit user={userDispensaries} />}
                path={`${routes.traceability.cultivationIntegrationAudit}/*`}
              />
              <Route element={<ReceiptAudit />} path={`${routes.traceability.receiptAudit}/*`} />
              <Route element={<Logs />} path={`${routes.traceability.logs.index}/*`} />
            </>
          )}
          {/* ORG MANAGEMENT */}
          {canViewOrgManagementPage && <Route element={<OrgManagement />} path={`${routes.organization.index}/*`} />}
          {/* SETTINGS */}
          {areAllSettingsPagesDisabled ? (
            <Route element={<PageDisabledEmptyState />} path='/settings/*' />
          ) : (
            <>
              <Route element={<Account />} path={routes.settings.account} />
              <Route element={<Users />} path={`${routes.settings.users.index}/*`} />
              <Route element={<Location />} path={`${routes.settings.location}/*`} />
              <Route element={<Integrations />} path={routes.settings.integrations.index} />
              <Route element={<Devices />} path={`${routes.settings.devices}/*`} />
              <Route element={<Receipts />} path={`${routes.settings.receipts}/*`} />
              <Route element={<Rooms />} path={`${routes.settings.rooms.index}/*`} />
              <Route element={<Labels />} path={`${routes.settings.labels}/*`} />
              <Route element={<TaxesAndCompliance />} path={`${routes.settings.taxesAndCompliance.index}/*`} />
              <Route element={<FeesAndDonations />} path={`${routes.settings.feesDonations}/*`} />
              <Route element={<TimeClock user={userDispensaries} />} path={routes.settings.timeClock} />
              {/* INTEGRATION SETTINGS PAGES */}
              <Route element={<IntegrationsMetrc />} path={`${routes.settings.integrations.metrc.index}/*`} />
              <Route element={<IntegrationsBioTrack />} path={`${routes.settings.integrations.biotrack.index}/*`} />
              <Route element={<MCDMSIntegration />} path={`${routes.settings.integrations.mcdms}/*`} />
              <Route element={<VirginiaIntegration />} path={`${routes.settings.integrations.virginiaBioTrack}/*`} />
              <Route element={<CanPay />} path={routes.settings.integrations.canpay} />
              <Route element={<WeedMaps user={userDispensaries} />} path={routes.settings.integrations.weedmaps} />
              <Route element={<WeedMaps2 />} path={`${routes.settings.integrations.weedmaps2}/*`} />
              <Route element={<LeafLink />} path={`${routes.settings.integrations.leaflink}/*`} />
              <Route element={<CashlessAtm />} path={`${routes.settings.integrations.cashlessAtm}/*`} />
              <Route element={<PlanetPayments />} path={`${routes.settings.integrations.planetpayments}/*`} />
              {flags['pos.register.paynetworx.rollout'] && (
                <Route element={<Paynetworx />} path={`${routes.settings.integrations.paynetworx}/*`} />
              )}
              <Route element={<PaymentsHub />} path={`${routes.settings.integrations.paymentshub}/*`} />
              {canViewNonIntegratedPayments && (
                <Route
                  element={<NonIntegratedPayments />}
                  path={`${routes.settings.integrations.nonIntegratedPayments}/*`}
                />
              )}
              <Route element={<DutchiePay />} path={`${routes.settings.integrations.dutchiepay}/*`} />
              <Route element={<Springbig />} path={`${routes.settings.integrations.springbig}/*`} />
              <Route
                element={
                  <PrivateComponent
                    booleanPermissionOverride={canViewCampaigns}
                    component={<EmailMarketingIntegration />}
                  />
                }
                path={`${routes.settings.integrations.emailMarketing}/*`}
              />
              <Route
                element={
                  <PrivateComponent
                    booleanPermissionOverride={canViewCampaigns}
                    component={<SMSMarketingIntegration />}
                  />
                }
                path={`${routes.settings.integrations.smsMarketing}/*`}
              />
              <Route element={<SSO />} path={`${routes.settings.integrations.sso}/*`} />
              <Route element={<CCRS />} path={`${routes.settings.integrations.ccrs}/*`} />
              <Route element={<Linx user={userDispensaries} />} path={routes.settings.integrations.linx} />
              <Route element={<IdScan />} path={routes.settings.integrations.idscan} />
              <Route element={<Leaf />} path={`${routes.settings.integrations.leaf}/*`} />
              <Route element={<MMUR />} path={routes.settings.integrations.mmur} />
              <Route element={<Adilas />} path={routes.settings.integrations.adilas} />
              <Route element={<Leafly user={userDispensaries} />} path={routes.settings.integrations.leafly} />
              <Route element={<Quickbooks />} path={routes.settings.integrations.quickbooks} />
              <Route element={<MN user={userDispensaries} />} path={routes.settings.integrations.mn} />
              <Route element={<Tookan />} path={routes.settings.integrations.tookan} />
              <Route element={<Onfleet user={userDispensaries} />} path={routes.settings.integrations.onfleet} />
              <Route
                element={<SkylightIntegration user={userDispensaries} />}
                path={routes.settings.integrations.skylight}
              />
              <Route
                element={<TranCloudIntegration user={userDispensaries} />}
                path={routes.settings.integrations.trancloud}
              />
              <Route
                element={<BioMaurisIntegration user={userDispensaries} />}
                path={routes.settings.integrations.biomauris}
              />
              <Route
                element={<AplineIQIntegration user={userDispensaries} />}
                path={routes.settings.integrations.alpineiq}
              />
              <Route element={<Fyllo user={userDispensaries} />} path={routes.settings.integrations.fyllo} />
              <Route element={<Spin />} path={routes.settings.integrations.spin} />
              <Route element={<WorkWave user={userDispensaries} />} path={routes.settings.integrations.workwave} />
              <Route element={<PMP user={userDispensaries} />} path={`${routes.settings.integrations.pmp}/*`} />
              <Route element={<MassCIP user={userDispensaries} />} path={routes.settings.integrations.massCip} />
              <Route element={<Hypur user={userDispensaries} />} path={routes.settings.integrations.hypur} />
              <Route element={<Sprout user={userDispensaries} />} path={routes.settings.integrations.sprout} />
              <Route element={<IntegrationsTrym user={userDispensaries} />} path={routes.settings.integrations.trym} />
              <Route element={<OCSSettings />} path={`${routes.settings.integrations.ocs}/*`} />
              <Route element={<VCCBSettings />} path={`${routes.settings.integrations.vccb}/*`} />
              <Route element={<UtahICSSettings />} path={`${routes.settings.integrations.utahics}/*`} />
              {generipayFlag && (
                <Route element={<GeneriPayIntegration />} path={`${routes.settings.integrations.generipay.index}/*`} />
              )}
            </>
          )}
          {/* SUPERUSER */}
          {areAllSuperuserPagesDisabled || !isSuperUserVisible ? (
            <Route element={<PageDisabledEmptyState />} path='/superuser/*' />
          ) : (
            <>
              <Route element={<SuperuserTools user={userDispensaries} />} path={`${routes.superuser.tools}/*`} />
              <Route element={<SuperuserLogs user={userDispensaries} />} path={routes.superuser.logs} />
              <Route element={<SuperuserManagement user={userDispensaries} />} path={routes.superuser.management} />
              <Route
                element={<SuperuserSSOGlobalConfig user={userDispensaries} />}
                path={routes.superuser.ssoGlobalconfig}
              />
              {manifestOptionsV2 ? (
                <Route
                  element={<SuperUserEditManifestV2 flags={flags} user={userDispensaries} />}
                  path={routes.superuser.manifestOptions}
                />
              ) : (
                <Route
                  element={<SuperUserEditManifest flags={flags} user={userDispensaries} />}
                  path={routes.superuser.manifestOptions}
                />
              )}

              <Route element={<AuditTables user={userDispensaries} />} path={routes.superuser.auditTables} />
              <Route element={<ProvincialCatalogUpload />} path={routes.superuser.provincialCatalogUpload} />
              <Route element={<MetrcTools user={userDispensaries} />} path={routes.superuser.metrcTools} />
              <Route element={<FeatureFlags />} path={routes.superuser.features} />
              <Route element={<CreateEditLsp user={userDispensaries} />} path={routes.superuser.editLsp} />
              <Route
                element={useSmbPacks ? <CreateSmbLsp /> : <CreateLsp flags={flags} user={userDispensaries} />}
                path={routes.superuser.createLsp}
              />
              <Route element={<PublicAPI user={userDispensaries} />} path={routes.superuser.publicApi} />
              <Route
                element={
                  flags['pos.backoffice.refactor-looker-admin.rollout'] ? (
                    <LookerAdminV2 />
                  ) : (
                    <LookerAdmin user={userDispensaries} />
                  )
                }
                path={`${routes.superuser.lookerAdmin}/*`}
              />
              <Route element={<Documentation user={userDispensaries} />} path={routes.superuser.documentation} />
              <Route
                element={
                  isBulkUpdaterPageDisabled ? <PageDisabledEmptyState /> : <BulkUpdater user={userDispensaries} />
                }
                path={routes.superuser.bulkUpdateData}
              />
              <Route element={<LicenseTypes user={userDispensaries} />} path={routes.superuser.licenseTypes} />
              <Route
                element={isDataLoaderPageDisabled ? <PageDisabledEmptyState /> : <DataImport />}
                path={routes.superuser.dataImport}
              />
              {canViewLocationManagerPage && (
                <Route element={<SuperuserLocationManager />} path={routes.superuser.locationManager} />
              )}
              <Route
                element={isDataPurgerPageDisabled ? <PageDisabledEmptyState /> : <DataWipe user={userDispensaries} />}
                path={routes.superuser.dataWipe}
              />
              <Route element={<SuperuserPublishLocation />} path={routes.superuser.publishLocation} />
            </>
          )}
          {/* secret route, only accessible by direct navigation */}
          <Route element={<LoaderMain user={userDispensaries} />} path={routes.superuser.importData} />
          {/* OTHER */}
          <Route element={<HelpTutorials />} path={routes.help.tutorials} />
          {/* E2E ONLY ROUTES */}
          {renderE2EOnlyRoute && <Route element={<E2EOnlyPageComponent />} path={E2EOnlyPage.Route} />}
          {/* CATCH ALL REDIRECT TO HOME */}
          <Route element={<Navigate to={routes.home} />} path='*' />
        </Routes>
      </Suspense>
      <div id='temp-elements' style={{ visibility: 'hidden' }} />
    </div>
  );
}

export default Main;
