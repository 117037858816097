import React from 'react';

import styled from 'styled-components';

import RoomsDropdown from 'src/app/components/form-elements/dropdowns/rooms-dropdown';
import { TagsDropdown } from 'src/app/components/form-elements/dropdowns/tags-dropdown';
import { VendorsDropdown } from 'src/app/components/form-elements/dropdowns/vendors-dropdown';
import { QuantityInput } from 'src/app/components/form-elements/inputs';
import { BatchInput } from 'src/app/components/form-elements/inputs/batch-input';
import { CostInput } from 'src/app/components/form-elements/inputs/cost';
import { PackageIdInput } from 'src/app/components/form-elements/inputs/package-id-input';
import { Accordion } from 'src/app/components/lib/accordion';
import { RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { Checkbox } from 'src/app/components/lib/checkbox';
import { DatePicker } from 'src/app/components/lib/date-picker';
import { ServerPaginatedDropdownSingle } from 'src/app/components/lib/dropdown/server-paginated-dropdowns/dropdown-single';
import { FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { Select } from 'src/app/components/lib/select';
import { useBOMPermissions } from 'src/app/hooks/use-bom-permissions';
import { Status, CostType } from 'src/app/pages/manufacturing/assemblies/constants';
import { useAssemblyFormStateContext } from 'src/app/pages/manufacturing/assemblies/detail/use-form-state';
import { useCatalogProductDropdownData } from 'src/app/queries/graphql/inventory-products/use-dropdown-data';
import { FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';

import { buildOutputProductDropdownFooter, getDefaultExpirationDate, getOutputCost } from '../../../../utils';

import type { useAssemblyFormData } from '../../../use-assembly-form-data';
import type { OutputItem } from 'src/app/pages/manufacturing/assemblies/types';

type OutputDetailProps = {
  dropdownOptions: ReturnType<typeof useAssemblyFormData>['dropdownOptions'];
  outputIndex: number;
};

export function OutputDetail({ outputIndex, dropdownOptions }: OutputDetailProps) {
  const { canBypassStateSystem } = useBOMPermissions();
  const { inventoryStatusOptions } = dropdownOptions;
  const [{ formData, errors, isReadonly, context }, dispatch] = useAssemblyFormStateContext();
  const { useMetrcV2, useBioTrack } = context;
  const { assemblyStatusId, outputs, processingJobTypeTemplate } = formData;
  const isAssemblyInProgress = assemblyStatusId === Status.IN_PROGRESS;
  const { isProcessingJob } = processingJobTypeTemplate;
  const outputItem = outputs[outputIndex];

  const {
    availableQuantity,
    isProductionBatch,
    batchId,
    bypassStateSystem,
    cost,
    costType,
    serialNumber,
    sourceSerialNumber,
    outputItemTypeId,
    skip,
    unitId,
    productTypeId: categoryId,
    product,
    packageDateUtc,
    expirationDateUtc,
    roomId,
    vendorId,
    inventoryTags,
    inventoryStatusId,
  } = outputItem;

  const defaultCostTypeName = 'Product';
  const minimumQuantity = 1;

  // Determine if we should show the "System Generated" placeholder
  const showSystemGeneratedPlaceholder = useBioTrack && !sourceSerialNumber && !serialNumber;

  const categoryFilters = [
    {
      value: categoryId ?? '',
      fields: ['productTypeNavigation.id'],
      operator: FilterOperator.EQUALS,
      logicalOperator: LogicalOperator.OR,
    },
    {
      value: unitId ?? '',
      fields: ['defaultUnit.unitId'],
      operator: FilterOperator.EQUALS,
      logicalOperator: LogicalOperator.OR,
    },
  ];

  const selectedProductOption = {
    id: product?.productId ?? '',
    label: product?.productName ?? '',
    footer: buildOutputProductDropdownFooter(
      product?.sku ?? '',
      product?.strainName ?? '',
      product?.alternateDesc ?? ''
    ),
  };

  const includeSelectedProductOption = !!product?.productId;
  // We only need to fetch products if the output item type is a category
  // If the output item type is a product, the product dropdown will be disabled
  // with the product already selected
  const { error, isFetching, options, morePagesExist, fetchNextPage, setFilter, infiniteDropDownMap } =
    useCatalogProductDropdownData({
      enabled: !skip && outputItemTypeId === 2,
      injectedItems: includeSelectedProductOption ? [selectedProductOption] : [],
      dynamicFilters: categoryFilters,
    });

  const setOutputValue = <K extends keyof OutputItem>(key: K, value: OutputItem[K]) => {
    dispatch({ type: 'set-output-value', payload: { outputIndex, key, value } });
  };

  const setOutputProduct = (newProductID: number | null) => {
    if (!newProductID) {
      setOutputValue('vendorId', null);
      setOutputValue('expirationDateUtc', null);
      setOutputValue('product', null);
    }
    const product = newProductID ? infiniteDropDownMap.get(newProductID) : null;
    const newProductVendor = product?.vendorNavigation?.id ?? null;
    const newTags = product?.productToTags?.map((tag) => tag?.tag?.tagId) ?? [];
    const newProduct =
      !!newProductID && !!product
        ? {
            alternateDesc: product?.alternateDesc ?? '',
            cost: product?.cost ?? null,
            productId: newProductID,
            productName: product?.name ?? '',
            sku: product?.sku ?? '',
            strainName: product?.strain?.strainName ?? '',
            tags: newTags,
          }
        : null;
    const newExpirationDateUtc = getDefaultExpirationDate(expirationDateUtc, product?.expirationDays);
    setOutputValue('vendorId', newProductVendor);
    setOutputValue('expirationDateUtc', newExpirationDateUtc);
    setOutputValue('product', newProduct);
    setOutputValue(
      'inventoryTags',
      newTags.filter((tag): tag is number => tag !== undefined)
    );
  };

  const onQuantityChange = (newQuantity: number | null) => {
    setOutputValue('availableQuantity', newQuantity);
    if (newQuantity !== null) {
      updateCost({ newQuantity });
    }
  };

  const updateCost = ({
    newCostType = undefined,
    newCost = undefined,
    newQuantity = undefined,
  }: {
    newCost?: number | undefined;
    newCostType?: keyof typeof CostType | undefined;
    newQuantity?: number | undefined;
  }) => {
    const costTypeToUse = newCostType ?? ((costType?.name as keyof typeof CostType) || defaultCostTypeName);
    const costToUse = newCost ?? cost;
    const quantityToUse = newQuantity ?? availableQuantity;

    const { cost: productCost = null } = product ?? {};
    const costTypeId = CostType[costTypeToUse];

    const costRaw = getOutputCost({
      costTypeId,
      productCost,
      outputItemCost: costToUse,
      inputItems: outputItem.inputItems,
      outputQuantity: quantityToUse ?? minimumQuantity,
      outputUnitId: unitId,
    });

    const costTo5Decimals: string | null = costRaw ? parseFloat(costRaw.toString()).toFixed(5) : null;
    const costFormated = costTo5Decimals ? parseFloat(costTo5Decimals) : null;

    setOutputValue('cost', costFormated);
    setOutputValue('costType', { costTypeId, name: costTypeToUse });
  };

  if (packageDateUtc === null) {
    setOutputValue('packageDateUtc', new Date());
  }

  return (
    <Accordion
      caratPosition='right'
      containerStyleOverrides={{ backgroundColor: 'var(--color-gray-10)' }}
      contentStyleOverrides={{
        borderTop: 'none',
        padding: 'var(--sizes-30) var(--sizes-50)',
      }}
      defaultExpanded={[0]}
      headerStyleOverrides={() => ({ padding: 'var(--sizes-50)' })}
      items={[{ ...outputItem, disabled: isReadonly || skip }]}
      multiExpand
      renderContent={(_, outputIndex) => (
        <FormSection>
          <FormContainer>
            <ServerPaginatedDropdownSingle
              automationId={`assembly-product-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly || outputItemTypeId === 1 || !!error}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              label='Product:'
              labelPlacement='top'
              morePagesExist={morePagesExist}
              options={outputItemTypeId === 1 ? [selectedProductOption] : options}
              required
              setFilterString={setFilter}
              setValue={({ id }) => setOutputProduct(id ? Number(id) : null)}
              value={product?.productId ?? ''}
            />
            <BatchInput
              automationId={`assembly-batch-id-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              initialValue={batchId}
              label='Batch:'
              labelPlacement='top'
              placeholder='Select from list'
              required={isProductionBatch || useMetrcV2}
              onChange={(newBatchId) => setOutputValue('batchId', newBatchId)}
            />
            <DatePicker
              allowPastDate
              automationId={`assembly-package-date-${outputIndex}`}
              date={packageDateUtc}
              disabled={isAssemblyInProgress || isReadonly}
              label='Package date:'
              labelPlacement='top'
              required
              onDateChange={(updatedDate) => setOutputValue('packageDateUtc', updatedDate)}
            />
            <DatePicker
              allowPastDate
              automationId={`assembly-expiration-date-${outputIndex}`}
              date={expirationDateUtc}
              disabled={isAssemblyInProgress || isReadonly}
              label='Expiration date:'
              labelPlacement='top'
              onDateChange={(updatedDate) => setOutputValue('expirationDateUtc', updatedDate ?? null)}
            />
            <RoomsDropdown
              automationId={`assembly-output-room-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              label='Room'
              labelPlacement='top'
              refreshButtonAutomationId={`assembly-output-room-refresh-${outputIndex}`}
              required
              value={roomId ?? ''}
              onChange={(val) => setOutputValue('roomId', val ? Number(val) : null)}
            />
            <Select
              automationId={`assembly-output-inventory-status-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              id={`assembly-output-inventory-status-${outputIndex}`}
              label='Inventory status:'
              labelPlacement='top'
              placeholder='Select inventory status'
              value={inventoryStatusId?.toString() ?? ''}
              onChange={({ target: { value } }) => setOutputValue('inventoryStatusId', value ? Number(value) : null)}
            >
              {inventoryStatusOptions.map((option, index) => (
                <MenuItem key={option.value?.toString() ?? String(index)} value={option.value.toString()}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <TagsDropdown
              automationId={`assembly-output-tags-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              helpText='Select tags'
              label='Tags:'
              labelPlacement='top'
              refreshButtonAutomationId={`assembly-output-tags-${outputIndex}-refresh-button`}
              searchAutomationId={`assembly-output-tags-${outputIndex}-search`}
              selectAllButtonAutomationId={`assembly-output-tags-${outputIndex}-select-all-button`}
              selectNoneButtonAutomationId={`assembly-output-tags-${outputIndex}-select-none-button`}
              value={inventoryTags}
              onChange={(tags: number[]) => setOutputValue('inventoryTags', tags)}
            />
            <VendorsDropdown
              automationId={`assembly-output-vendor-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              label='Vendor'
              labelPlacement='top'
              required
              value={vendorId ?? ''}
              onChange={(vendorId) => setOutputValue('vendorId', vendorId ? Number(vendorId) : null)}
            />
            <QuantityInput
              automationId={`assembly-output-quantity-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              error={!!errors[`outputs[${outputIndex}].quantity`]}
              id={`outputs[${outputIndex}].quantity`}
              label='Quantity:'
              labelPlacement='top'
              minimum={minimumQuantity}
              nameType='abbreviation'
              required
              unitDisabled
              unitValue={(unitId ?? '').toString()}
              value={availableQuantity ?? ''}
              onChange={({ value }) => {
                const newQuantity = value ? parseFloat(value) : null;
                onQuantityChange(newQuantity);
              }}
            />
            <Input
              automationId={`assembly-external-package-id-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly || useBioTrack}
              id={`assembly-external-package-id-${outputIndex}`}
              label='External package id:'
              labelPlacement='top'
              placeholder={showSystemGeneratedPlaceholder ? 'System Generated' : ''}
              value={sourceSerialNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setOutputValue('sourceSerialNumber', event.target.value)
              }
            />
            <PackageIdInput
              automationId={`assembly-package-id-${outputIndex}`}
              disabled={isAssemblyInProgress || isReadonly || useBioTrack}
              label='Package id:'
              labelPlacement='top'
              placeholder={showSystemGeneratedPlaceholder ? 'System Generated' : ''}
              product={{ ProductId: product?.productId ?? null }}
              required={!showSystemGeneratedPlaceholder}
              value={serialNumber}
              onChange={(value) => setOutputValue('serialNumber', value)}
            />
            <CostInput
              automationId={`assembly-cost-type-${outputIndex}`}
              costType={costType?.name ?? defaultCostTypeName}
              costTypeDisabled={isAssemblyInProgress || isReadonly}
              disabled={isAssemblyInProgress || isReadonly}
              label='Cost:'
              labelPlacement='top'
              required
              value={cost ?? ''}
              onChange={(value) => {
                if (parseFloat(value) < 0) {
                  // quick fix to reject negative values
                  // proper validation should be implemented witht he cost input component, used globally and could afftect many consumers.
                  return;
                }

                updateCost({ newCost: parseFloat(value) });
              }}
              onCostTypeChange={(value) => updateCost({ newCostType: (value as keyof typeof CostType) ?? undefined })}
            />
            {/* TODO: When implementing biotrack, make sure to include their use in this conditional */}
            {useMetrcV2 && (
              <>
                <Checkbox
                  checked={bypassStateSystem}
                  disabled={isAssemblyInProgress || isReadonly || !canBypassStateSystem}
                  label='Bypass state system'
                  onChange={({ target }) => setOutputValue('bypassStateSystem', target.checked)}
                />
                {!isProcessingJob && (
                  <Checkbox
                    checked={isProductionBatch}
                    disabled={isAssemblyInProgress || isReadonly || !canBypassStateSystem}
                    label='Is production batch'
                    onChange={({ target }) => setOutputValue('isProductionBatch', target.checked)}
                  />
                )}
              </>
            )}
          </FormContainer>
        </FormSection>
      )}
      renderHeaderLeft={() => (
        <>
          <OutputDetailTitle>Output details</OutputDetailTitle>
          {skip && <RedErrorBadge label='Skipped' size='small' />}
        </>
      )}
      renderHeaderRight={() => (
        <Checkbox
          checked={skip}
          disabled={isAssemblyInProgress || isReadonly}
          label='Skip output'
          value={skip}
          onChange={({ target }) => {
            setOutputValue('skip', target.checked);
            dispatch({
              type: 'apply-value-to-all-inputs',
              payload: { outputIndex, key: 'skip', value: target.checked },
            });
          }}
        />
      )}
    />
  );
}

const OutputDetailTitle = styled.div`
  color: var(--color-grayscale-black);
  /* Text/Default/Semibold */
  font: var(--font-regular-14pt-semibold);
  line-height: 20px; /* 142.857% */
`;

const FormContainer = styled.div`
  display: grid;
  grid-column-gap: var(--sizes-60);
  grid-row-gap: var(--sizes-50);
  flex-direction: row;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  & .form-control {
    background: var(--color-gray-10);
    & .MuiFormControl-root:not(.Mui-disabled),
    .MuiInputBase-input:not(.Mui-disabled) {
      background: #ffffff;
    }
  }
`;
