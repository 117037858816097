import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Permissions, userPermissionsAtom } from 'src/app/state/user-permissions';

import { userDispensariesAtom } from '../state/user-dispensaries';

export function useBOMPermissions() {
  const { integrations } = useRecoilValue(userDispensariesAtom);
  const { UseMetrc, UseBioTrack, UseMetrcV2 } = integrations;
  // This feature is available to users with Metrc V2, BioTrack, or no integration
  const usingValidIntegration = !!UseMetrcV2 || !!UseBioTrack || !UseMetrc;
  const { perms } = useRecoilValue(userPermissionsAtom);
  const bomPermissions = useMemo(
    () => ({
      canViewBoms: !!perms[Permissions.ViewBillOfMaterial] && usingValidIntegration,
      canEditBoms: !!perms[Permissions.EditBillOfMaterial] && usingValidIntegration,
      canViewAssemblies: !!perms[Permissions.ViewAssembly] && usingValidIntegration,
      canEditAssemblies: !!perms[Permissions.EditAssembly] && usingValidIntegration,
      canBypassStateSystem: !!perms[Permissions.BypassStateSystem],
    }),
    [perms, usingValidIntegration]
  );
  return bomPermissions;
}
