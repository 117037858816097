import { v4 as uuidv4 } from 'uuid';

import { conversionRatios } from 'src/app_deprecated/utils/UnitConversion';

import { CostType } from './constants';

import type { AssemblyFormState, SchemaContext } from './detail/use-form-state';
import type { InputItem, InputPackage, OutputItem } from './types';
import type { DocumentSchema, ProcessingJobTypeTemplateSchema } from '../shared/schema';
import type {
  NonNullableAssemblyDocument,
  ProcessingJobTemplate,
  NonNullableAssemblyOutputItems,
  NonNullableAssemblyInputItems,
  NonNullableAssemblyInputPackages,
} from 'src/app/queries/graphql/assemblies/factory';
import type { AssemblyDetailResponse } from 'src/app/queries/graphql/assemblies/get-one';
import type {
  BillOfMaterialInputItem,
  BillOfMaterialOutputItem,
} from 'src/app/queries/graphql/bill-of-materials/factory';
import type { GetBillOfMaterialDetailResponse } from 'src/app/queries/graphql/bill-of-materials/get-one';
import type {
  AssemblyInputItemRequest,
  AssemblyInputPackageRequest,
  AssemblyOutputItemRequest,
  UpsertAssemblyRequest,
} from 'src/app/queries/manufacturing/types';

type ExistingPackageData = {
  batchName: string;
  packageId: number;
  quantity: number | null;
  roomName: string;
  serialNumber: string;
};

export const DEFAULT_ASSEMBLY_STATE: AssemblyFormState = {
  assemblyStatusId: 0,
  billOfMaterialsId: null,
  estimatedStartDate: null,
  documents: [],
  name: '',
  outputs: [],
  processingJobTypeTemplate: {
    isProcessingJob: false,
    id: null,
    name: '',
    attributes: [],
    categoryName: '',
    description: '',
    processingSteps: '',
  },
};

export const filterArrayForValidNumbers = (values: unknown): number[] =>
  Array.isArray(values) ? values.filter((value) => !Number.isNaN(Number(value))).map(Number) : [];

export const buildAvailableQtyString = (quantity: number, unitAbbreviation: string) =>
  `${quantity.toString()} ${unitAbbreviation}`;

export const initializeDefaultAssemblyState = (): AssemblyFormState => DEFAULT_ASSEMBLY_STATE;

export const initializeEditAssemblyState = (values: AssemblyDetailResponse): AssemblyFormState => ({
  assemblyStatusId: values?.assemblyStatus?.assemblyStatusId ?? 0,
  billOfMaterialsId: values?.billOfMaterials?.billOfMaterialsId ?? null,
  estimatedStartDate: values?.estimatedStartDate ?? null,
  documents: mapAssemblyDocumentsToForm(values?.billOfMaterials?.billOfMaterialsFiles?.filter((f) => !!f) ?? []),
  name: values?.name ?? '',
  outputs: mapAssemblyOutputItemsToForm(values?.assemblyOutputItems ?? []),
  processingJobTypeTemplate: mapProcessingJobTypeTemplateToForm(
    values?.billOfMaterials?.metrcProcessingJobTypeTemplate,
    !!values?.billOfMaterials?.isProcessingJob
  ),
});

export const mapAssemblyDocumentsToForm = (documents: NonNullableAssemblyDocument[]): DocumentSchema[] =>
  documents.map((file) => ({
    id: file?.billOfMaterialsFileId ?? null,
    uuid: uuidv4(),
    documentName: file?.documentName ?? '',
    documentTypeId: file?.documentTypeId ?? 1,
    fileName: file?.fileName ?? null,
    url: file?.url ?? null,
    fileUrl: file?.fileUrl ?? null,
    userFileName: file?.userFileName ?? null,
  }));

export const mapProcessingJobTypeTemplateToForm = (
  metrcProcessingJobTypeTemplate: ProcessingJobTemplate,
  isProcessingJob: boolean
): ProcessingJobTypeTemplateSchema => ({
  isProcessingJob,
  id: metrcProcessingJobTypeTemplate?.metrcProcessingJobTypeTemplateId ?? null,
  name: metrcProcessingJobTypeTemplate?.name ?? '',
  attributes: metrcProcessingJobTypeTemplate?.metrcProcessingJobTypeAttributes?.map((attr) => attr?.name ?? '') || [],
  categoryName: metrcProcessingJobTypeTemplate?.categoryName ?? '',
  description: metrcProcessingJobTypeTemplate?.description ?? '',
  processingSteps: metrcProcessingJobTypeTemplate?.processingSteps ?? '',
});

export const mapAssemblyOutputItemsToForm = (outputItems: NonNullableAssemblyOutputItems): OutputItem[] =>
  outputItems.map((output) => ({
    assemblyOutputItemId: output?.assemblyOutputItemId ?? null,
    availableQuantity: output?.actualQuantity ?? null,
    batchId: output?.batchId ?? null,
    bypassStateSystem: !!output?.bypassStateSystem,
    cost: output?.cost ?? null,
    expirationDateUtc: output?.expirationDateUtc ?? null,
    inputItems: mapAssemblyInputItemsToForm(output?.assemblyInputItems ?? []),
    inventoryStatusId: output?.inventoryStatusId ?? null,
    inventoryTags: output?.tags?.map((tag) => tag?.tagId).filter((id): id is number => !!id) ?? [],
    isProductionBatch: !!output?.isProductionBatch,
    outputItemTypeId: output?.outputItemTypeId,
    packageDateUtc: output?.packageDateUtc ?? null,
    product: output?.product?.id
      ? {
          productId: output?.product?.id ?? null,
          productName: output?.product?.name ?? '',
          cost: output?.product?.unitCost ?? null,
          strainName: output?.product?.strain?.strainName ?? '',
          sku: output?.product?.sku ?? '',
          alternateDesc: output?.product?.alternateDesc ?? '',
        }
      : null,
    productTypeId: output?.productType?.id ?? null,
    productTypeName: output?.productType?.productType ?? '',
    quantity: output?.quantity ?? null,
    roomId: output?.roomId ?? null,
    serialNumber: output?.serialNumber ?? '',
    skip: !!output?.skip,
    sourceSerialNumber: output?.sourceSerialNumber ?? '',
    unitId: output?.unit?.unitId ?? null,
    costType: output?.costType?.costTypeId
      ? {
          name: output.costType.name,
          costTypeId: output.costType.costTypeId,
        }
      : null,
    unitAbbreviation: output?.unit?.abbreviation ?? '',
    useByDate: output?.useByDate ?? null,
    uuid: uuidv4(),
    vendorId: output?.vendor?.id ?? null,
  }));

export const mapAssemblyInputItemsToForm = (inputItems: NonNullableAssemblyInputItems): InputItem[] =>
  inputItems.map((input) => ({
    assemblyInputItemId: input?.assemblyInputItemId ?? null,
    assemblyInputPackages: mapAssemblyInputPackagesToForm(input?.assemblyInputPackages ?? []),
    inputItemTypeId: input?.inputItemTypeId,
    productId: input?.product?.id ?? null,
    productName: input?.product?.whseProductsDescription ?? '',
    productTypeId: input?.productType?.id ?? null,
    productTypeName: input?.productType?.productType ?? '',
    quantity: input?.quantity ?? 0,
    skip: !!input?.skip,
    unitAbbreviation: input?.unit?.abbreviation ?? '',
    unitId: input?.unit?.unitId ?? null,
    uuid: uuidv4(),
  }));

export const mapAssemblyInputPackagesToForm = (inputPackages: NonNullableAssemblyInputPackages): InputPackage[] =>
  inputPackages.map((inputPackage) => ({
    assemblyInputPackageId: inputPackage?.assemblyInputPackageId ?? null,
    available:
      !!inputPackage?.package?.quantity && !!inputPackage?.package?.unit?.abbreviation
        ? buildAvailableQtyString(inputPackage.package.quantity, inputPackage.package.unit.abbreviation)
        : '',
    availableQuantity: inputPackage?.package?.quantity ?? 0,
    batchName: inputPackage?.package?.batch?.batchLotNumber ?? '',
    cost: inputPackage?.package?.cost ?? null,
    packageId: inputPackage?.package?.packageId ?? 0,
    quantity: inputPackage?.quantity ?? null,
    roomName: inputPackage?.package?.room?.roomNo ?? '',
    serialNumber: inputPackage?.package?.serialNumber ?? '',
    unitId: inputPackage?.package?.unit?.unitId ?? null,
    useByDate: inputPackage?.package?.useByDate ?? '',
    uuid: uuidv4(),
  }));

export const applyBomToAssemblyState = (
  bom: NonNullable<GetBillOfMaterialDetailResponse>,
  state: AssemblyFormState,
  context: SchemaContext
): AssemblyFormState => ({
  ...state,
  billOfMaterialsId: bom.billOfMaterialsId,
  outputs: mapBillOfMaterialOutputItemsToForm(bom.billOfMaterialsOutputItems?.filter((item) => !!item) ?? [], context),
  processingJobTypeTemplate: mapProcessingJobTypeTemplateToForm(
    bom.metrcProcessingJobTypeTemplate,
    bom.isProcessingJob
  ),
  documents: mapAssemblyDocumentsToForm(bom?.billOfMaterialsFiles?.filter((f) => !!f) ?? []),
});

export const mapBillOfMaterialOutputItemsToForm = (
  outputItems: BillOfMaterialOutputItem[],
  context: SchemaContext
): OutputItem[] => {
  if (!outputItems) {
    return [];
  }
  const costTypeId = context.defaultCostType ?? CostType.Product;
  const filteredOutputs = outputItems.filter((output) => !!output);
  return filteredOutputs.map((output) => {
    const inputItems = mapBillOfMaterialInputItemsToForm(output?.billOfMaterialsInputItems?.filter((i) => !!i) ?? []);
    return {
      assemblyOutputItemId: null,
      availableQuantity: output.quantity,
      batchId: null,
      bypassStateSystem: false,
      cost: getOutputCost({
        costTypeId,
        productCost: output?.product?.unitCost ?? null,
        outputItemCost: null,
        inputItems,
        outputQuantity: output.quantity,
        outputUnitId: output.unitId,
      }),
      expirationDateUtc: null,
      inputItems,
      inventoryStatusId: null,
      inventoryTags: [],
      isProductionBatch: false,
      outputItemTypeId: output?.outputTypeId,
      packageDateUtc: null,
      product: output?.product?.id
        ? {
            productId: output?.product?.id ?? null,
            productName: output?.product?.whseProductsDescription ?? '',
            cost: output?.product?.unitCost ?? null,
            strainName: output?.product?.strain?.strainName ?? '',
            sku: output?.product?.sku ?? '',
            alternateDesc: output?.product?.alternateDesc ?? '',
          }
        : null,
      productTypeId: output?.productType?.id ?? null,
      productTypeName: output?.productType?.productType ?? '',
      quantity: output.quantity,
      roomId: null,
      serialNumber: '',
      skip: false,
      sourceSerialNumber: '',
      costType: {
        costTypeId,
        name:
          Object.entries(CostType) // find the key of the enum value or default to 'Product'
            .find(([, enumValue]) => enumValue === context.defaultCostType)?.[0] ?? 'Product',
      },
      unitId: output.unitId,
      unitAbbreviation: output.unit?.abbreviation ?? '',
      useByDate: null,
      uuid: uuidv4(),
      vendorId: output?.product?.vendorNavigation?.id ?? null,
    };
  });
};

export const mapBillOfMaterialInputItemsToForm = (inputItems: BillOfMaterialInputItem[]): InputItem[] =>
  inputItems.map((input) => ({
    assemblyInputItemId: null,
    assemblyInputPackages: [],
    inputItemTypeId: input.inputTypeId,
    productId: input?.product?.id ?? null,
    productName: input?.product?.whseProductsDescription ?? '',
    productTypeId: input?.productType?.id ?? null,
    productTypeName: input?.productType?.productType ?? '',
    quantity: input?.quantity ?? 0,
    skip: false,
    unitAbbreviation: input?.unit?.abbreviation ?? '',
    unitId: input?.unit?.unitId ?? null,
    uuid: uuidv4(),
  }));

export const buildOutputHeaderTitle = (outputItem: OutputItem) => {
  const prefix =
    outputItem.outputItemTypeId === 1 ? outputItem?.product?.productName ?? '' : outputItem.productTypeName;
  const suffix = `${outputItem.quantity ?? ''} ${outputItem.unitAbbreviation}`;
  return `${prefix ?? ''} ${suffix}`;
};

export const buildInputHeaderTitle = (inputItem: InputItem) => {
  const prefix = inputItem.inputItemTypeId === 1 ? inputItem?.productName ?? '' : inputItem?.productTypeName;
  const suffix = `${inputItem.quantity ?? ''} ${inputItem.unitAbbreviation}`;
  return { prefix, suffix };
};

export const getDefaultExpirationDate = (expirationDateUtc?: Date | null, expirationDays?: number | null) => {
  if (expirationDateUtc) {
    return expirationDateUtc;
  }
  if (expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    return expirationDate;
  }
  return null;
};

/**
 * Ensures that an input has been fulfilled with sufficient packages
 * @param inputItem InputItem
 * @returns boolean
 */
export const getIsInputQuantityFulfilled = (inputItem: InputItem): boolean => {
  const totalPackageQuantity = inputItem.assemblyInputPackages.reduce(
    // Don't falsly indicate the input quantity has been fulfilled if availability is insufficient
    (total, inputPackage) => total + Math.min(inputPackage.availableQuantity, inputPackage.quantity ?? 0),
    0
  );
  return totalPackageQuantity >= inputItem.quantity;
};

const getAreInputPackageQuantitiesValid = (inputItem: InputItem): boolean => {
  let sumOfPackages = 0;
  for (const inputPackage of inputItem.assemblyInputPackages) {
    // Return early if any package exceeds it's availability
    if ((inputPackage.quantity ?? 0) > inputPackage.availableQuantity) {
      return false;
    }
    // If not, sum up the quantity from the package field
    sumOfPackages += inputPackage.quantity ?? 0;
  }
  // Quantities are valid if the sum of all the packages meets (or exceeds) the required input amount
  return sumOfPackages >= inputItem.quantity;
};

/**
 * Validates that all quantities are valid for each output and input in the form.
 * This includes if the quantity of a package exceeds its availability
 * @param outputItems OutputItem[]
 * @returns boolean
 */
export const getAreQuantitiesValid = (outputs: OutputItem[]): boolean =>
  outputs.every((output) => {
    if (output.skip) {
      return true;
    }
    return output.inputItems.every((inputItem) => {
      if (inputItem.skip) {
        return true;
      }
      return getAreInputPackageQuantitiesValid(inputItem);
    });
  });

export const buildInputPackageLabel = ({
  batchName,
  quantity,
  roomName,
  serialNumber,
}: Omit<ExistingPackageData, 'packageId'>) =>
  `${serialNumber} | Available: ${quantity ?? ''} | Batch: ${String(batchName ?? '')} | Room: ${String(
    roomName ?? ''
  )}`;

export const buildOutputProductDropdownFooter = (sku: string, strainName: string, alternateDesc: string) =>
  `SKU: ${sku} | Strain: ${strainName || 'None'}${alternateDesc ? ` | Alternate name: ${alternateDesc}` : ''}`;

const getInputPackageCostByUnitId =
  (desiredUnitId: number | null) =>
  ({ cost: packageCost, quantity: packageQuantity, unitId: packageUnitId }: InputPackage, runningTotal = 0) => {
    const conversionRatio =
      conversionRatios.find(({ fromUnitId, toUnitId }) => fromUnitId === packageUnitId && toUnitId === desiredUnitId)
        ?.ratio ?? 1;
    const adjustedPackageCost = (packageCost ?? 0) * (packageQuantity ?? 0) * conversionRatio;
    return adjustedPackageCost + runningTotal;
  };

export const calculateTotalOutputCost = (
  output: { quantity: number; unitId: number | null },
  inputItems: InputItem[]
) => {
  // Calculate the total cost of all input items divided by the output quantity
  const totalInputCost = inputItems.reduce((sumOfInputs, { assemblyInputPackages }) => {
    const getInputPackageCost = getInputPackageCostByUnitId(output.unitId);
    const totalPackagesCost = assemblyInputPackages.reduce(
      (sumOfPackages, inputPackage) => getInputPackageCost(inputPackage, sumOfPackages),
      0
    );
    return sumOfInputs + totalPackagesCost;
  }, 0);

  return totalInputCost / output.quantity;
};

type GetOutputCostParams = {
  costTypeId: CostType;
  inputItems: InputItem[];
  outputItemCost: number | null;
  outputQuantity: number;
  outputUnitId: number | null;
  productCost: number | null;
};

export const getOutputCost = ({
  costTypeId,
  productCost,
  outputItemCost,
  inputItems,
  outputQuantity,
  outputUnitId,
}: GetOutputCostParams) => {
  const newCost = (() => {
    switch (costTypeId) {
      case CostType.Product:
        return productCost;
      case CostType.Calculated:
        return calculateTotalOutputCost({ quantity: outputQuantity ?? 0, unitId: outputUnitId }, inputItems);
      case CostType.Other:
        return outputItemCost;
      default:
        return null;
    }
  })();
  return newCost;
};

// Map to request payload

const mapInputPackageToRequest = (inputPackage: InputPackage): AssemblyInputPackageRequest => ({
  AssemblyInputPackageId: inputPackage.assemblyInputPackageId ?? null,
  PackageId: inputPackage.packageId,
  Quantity: inputPackage.quantity ?? 0,
  UnitId: inputPackage.unitId ?? 0,
});

const mapInputItemToRequest = (inputItem: InputItem): AssemblyInputItemRequest => ({
  AssemblyInputItemId: inputItem.assemblyInputItemId ?? null,
  AssemblyInputPackages: inputItem.assemblyInputPackages.map(mapInputPackageToRequest),
  InputItemTypeId: inputItem.inputItemTypeId,
  ProductId: inputItem.productId ?? null,
  ProductTypeId: inputItem.productTypeId ?? null,
  Quantity: inputItem.quantity,
  Skip: inputItem.skip,
  UnitId: inputItem.unitId ?? 0,
});

export const mapOutputItemToRequest = (outputItem: OutputItem): AssemblyOutputItemRequest => ({
  ActualQuantity: outputItem.availableQuantity ?? 0,
  AssemblyOutputItemId: outputItem.assemblyOutputItemId ?? null,
  BatchId: outputItem.batchId,
  BypassStateSystem: outputItem.bypassStateSystem,
  Cost: outputItem.cost,
  CostTypeId: outputItem.costType?.costTypeId ?? null,
  ExpirationDateUtc: outputItem.expirationDateUtc,
  InventoryStatusId: outputItem.inventoryStatusId,
  InventoryTags: outputItem.inventoryTags,
  IsProductionBatch: outputItem.isProductionBatch,
  InputItems: outputItem.inputItems.map(mapInputItemToRequest),
  OutputItemTypeId: outputItem.outputItemTypeId,
  PackageDateUtc: outputItem.packageDateUtc,
  PackageId: null, // TODO: Add packageId to the form?
  ProductId: outputItem.product?.productId ?? null,
  ProductTypeId: outputItem.productTypeId ?? null,
  Quantity: outputItem.quantity ?? 0,
  RoomId: outputItem.roomId,
  SerialNumber: outputItem.serialNumber,
  Skip: outputItem.skip,
  SourceSerialNumber: outputItem.sourceSerialNumber,
  UnitId: outputItem.unitId ?? 0,
  UseByDate: outputItem.useByDate,
  VendorId: outputItem.vendorId ?? null,
});

export const mapFormDataToUpsertPayload = ({
  assemblyId,
  billOfMaterialsId,
  estimatedStartDate,
  name,
  outputs,
}: {
  assemblyId?: number;
  billOfMaterialsId: number;
  estimatedStartDate: Date;
  name: string;
  outputs: OutputItem[];
}): UpsertAssemblyRequest => ({
  AssemblyId: assemblyId ?? null,
  BillOfMaterialsId: billOfMaterialsId,
  EstimatedStartDate: estimatedStartDate,
  Name: name,
  OutputItems: outputs.map(mapOutputItemToRequest),
});
