import { EventEmitter } from 'events';

import i18next from 'i18next';
import _ from 'lodash';

import { inventoryPackagesKeys } from 'src/app/queries/graphql/inventory-packages/query-key-factory';
import { getLDClient } from 'src/app/utils/ld-client-do-not-use-me';

import NotificationActions from '../actions/NotificationActions';
import { actions as AppEvents } from '../constants/AppConstants';
import {
  events as InventoryEvents,
  endPoints,
  actions as InventoryActions,
  eventTypes as InventoryEventTypes,
} from '../constants/InventoryConstants';
import { actions as UserActions } from '../constants/UserConstants';
import AjaxPromises from '../utils/AjaxPromises';
import AppDispatcher from '../utils/AppDispatcher';
import HttpClient from '../utils/HttpClient';

import UserStore from './UserStore';

import type { QueryClient } from '@tanstack/query-core';
import type { CustomerType } from 'src/app/queries/customers/get-enabled-customer-types';

export const PotencyIndicatorList = [
  { name: 'High THC : Low CBD', val: 1 },
  { name: 'Low THC : High CBD', val: 2 },
  { name: 'Equal THC : CBD', val: 3 },
  { name: 'Per Pharmacist', val: 4 },
];

export type ProductCategory = {
  CustomerTypes: CustomerType[];
  FlowerEquivalent: any;
  LeafLinkCategoryId: any;
  LeafLinkSubCategoryId: any;
  LeaflyCategory: any;
  MasterCategory: string;
  MMURAlternativeForms: [];
  MMURFormId: any;
  ProductCategory: string;
  ProductCategoryId: number;
  ProductFlag: any;
  PurchaseLimitCategoryId: any;
  RecFlowerEquivalent: any;
  RegulatoryCategoryId: any;
  SalesAccountId: any;
  StateInventoryFlag: '';
  TaxCategories: number[];
  WeedMapsCategoryId: any;
  WeedMapsSubCategoryId: any;
};

export type Brand = {
  BrandId: number;
  BrandName: string;
};

const _state = {
  categoryId: 0,

  inventory: [],
  inventoryLoading: false,
  zeroInventory: [],
  products: [],
  productsLoading: false,
  locchargecodes: [],
  retiredProducts: [],
  units: [],
  unitTypes: [],
  productCategories: [],
  regulatoryCategories: [],
  vendorTypes: [],
  productGroups: [],
  vendors: [],
  vendorsLoading: false,
  rooms: [],
  inventoryStatuses: [],
  strains: [],
  qualifyingConditions: [],
  manifest: [],
  retailManifest: [],
  reapedManifest: [],
  adjustmentReasons: [],
  destructionReasons: [],
  orders: [],
  dosages: [],
  sizelist: [],
  brands: [],
  lineages: [],
  distillations: [],
  WeedmapsCategories: [],
  WeedmapsSubCategories: [],
  tags: [],
  externalIds: [],
  metrcOnboardingManifest: [],
};

const _status = {
  inventory: false,
  zeroInventory: false,
  inventoryStatuses: false,
  products: false,
  retiredProducts: false,
  productCategories: false,
  regulatoryCategories: false,
  vendorTypes: false,
  productGroups: false,
  vendors: false,
  rooms: false,
  strains: false,
  qualifyingConditions: false,
  manifest: false,
  retailManifest: false,
  adjustmentReasons: false,
  destructionReasons: false,
  orders: false,
  dosages: false,
  sizelist: false,
  brands: false,
  lineages: false,
  distillations: false,
  tags: false,
  externalIds: false,
  metrcOnboardingManifest: false,
};

class InventoryStoreClass extends EventEmitter {
  constructor() {
    super();
    this.refreshUnits = this.refreshUnits.bind(this);
    this.refreshInventory = this.refreshInventory.bind(this);
    this.refreshZeroInventory = this.refreshZeroInventory.bind(this);
    this.refreshProducts = this.refreshProducts.bind(this);
    this.refreshRetiredProducts = this.refreshRetiredProducts.bind(this);
    this.refreshVendors = this.refreshVendors.bind(this);
    this.refreshInactiveVendors = this.refreshInactiveVendors.bind(this);
    this.refreshDosages = this.refreshDosages.bind(this);
    this.refreshSizeList = this.refreshSizeList.bind(this);
    this.refreshRooms = this.refreshRooms.bind(this);
    this.refreshStrains = this.refreshStrains.bind(this);
    this.refreshManifest = this.refreshManifest.bind(this);
    this.refreshRetailManifest = this.refreshRetailManifest.bind(this);
    this.refreshAdjustmentReasons = this.refreshAdjustmentReasons.bind(this);
    this.refreshDestructionReasons = this.refreshDestructionReasons.bind(this);
    this.UpdateProduct = this.UpdateProduct.bind(this);
    this.updateProductsPrices = this.updateProductsPrices.bind(this);
    this.refreshInventoryStatus = this.refreshInventoryStatus.bind(this);
    // this.updateInventoryStatus = this.updateInventoryStatus.bind(this);
    this.updateStrain = this.updateStrain.bind(this);
    this.updateRoom = this.updateRoom.bind(this);
    this.clear = this.clear.bind(this);
    this.deleteRoom = this.deleteRoom.bind(this);
    this.deleteProductCategory = this.deleteProductCategory.bind(this);
    this.refreshOrders = this.refreshOrders.bind(this);
    this.refreshBrands = this.refreshBrands.bind(this);
    this.refreshLineages = this.refreshLineages.bind(this);
    this.refreshDistillations = this.refreshDistillations.bind(this);
    this.refreshMetrcOnboardingManifest = this.refreshMetrcOnboardingManifest.bind(this);
    this.vendorsLoading = this.vendorsLoading.bind(this);
  }

  dispatchToken: any;

  get inventory() {
    if (!(_status.inventory || _state.inventoryLoading)) {
      _status.inventory = true; // prevent multiple calls
      this.refreshInventory();
    }
    return _state.inventory;
  }

  get inventoryOnly() {
    return _state.inventory;
  }

  get inventoryLoading() {
    return _state.inventoryLoading;
  }

  get zeroInventory() {
    if (!(_status.zeroInventory || _state.inventoryLoading)) {
      _status.zeroInventory = true; // prevent multiple calls
      this.refreshZeroInventory();
    }
    return _state.zeroInventory;
  }

  get products() {
    if (!(_status.products || _state.productsLoading)) {
      _status.products = true; // prevent multiple calls
      this.refreshProducts();
    }
    return _state.products;
  }

  get productsLoading() {
    return _state.productsLoading;
  }

  get productsOnly() {
    return _state.products;
  }

  get retiredProducts() {
    if (!_status.retiredProducts) {
      _status.retiredProducts = true; // prevent multiple calls
      this.refreshRetiredProducts();
    }
    return _state.retiredProducts;
  }

  get productCategories() {
    if (!_status.productCategories) {
      _status.productCategories = true; // prevent multiple calls
      this.refreshProductCategories();
    }
    return _state.productCategories as ProductCategory[];
  }

  get regulatoryCategories() {
    if (!_status.regulatoryCategories) {
      _status.regulatoryCategories = true; // prevent multiple calls
      this.refreshRegulatoryCategories();
    }
    return _state.regulatoryCategories;
  }

  get vendorTypes() {
    if (!_status.vendorTypes) {
      _status.vendorTypes = true; // prevent multiple calls
      this.refreshVendorTypes();
    }
    return _state.vendorTypes;
  }

  get productGroups() {
    if (!_status.productGroups) {
      _status.productGroups = true; // prevent multiple calls
      this.refreshProductGroups();
    }
    return _state.productGroups;
  }

  get brands() {
    if (!_status.brands) {
      _status.brands = true; // prevent multiple calls
      this.refreshBrands();
    }
    return _state.brands;
  }

  get lineages() {
    if (!_status.lineages) {
      _status.lineages = true; // prevent multiple calls
      this.refreshLineages();
    }
    return _state.lineages;
  }

  get distillations() {
    if (!_status.distillations) {
      _status.distillations = true; // prevent multiple calls
      this.refreshDistillations();
    }
    return _state.distillations;
  }

  get vendors() {
    if (!_status.vendors) {
      _status.vendors = true; // prevent multiple calls
      this.refreshVendors();
    }
    return _state.vendors;
  }

  vendorsLoading() {
    return !_status.vendors;
  }

  get inactiveVendors() {
    if (!_status.vendors) {
      _status.vendors = true; // prevent multiple calls
      this.refreshInactiveVendors();
    }
    return _state.vendors;
  }

  get units() {
    return _state.units;
  }

  get unitTypes() {
    return _state.unitTypes;
  }

  get rooms() {
    if (!_status.rooms) {
      _status.rooms = true; // prevent multiple calls
      this.refreshRooms();
    }
    return _state.rooms;
  }

  get inventoryStatuses() {
    if (!_status.inventoryStatuses) {
      _status.inventoryStatuses = true; // prevent multiple calls
      this.refreshInventoryStatus();
    }
    return _state.inventoryStatuses;
  }

  get strains() {
    if (!_status.strains) {
      _status.strains = true; // prevent multiple calls
      this.refreshStrains();
    }
    return _state.strains;
  }

  get allQualifyingConditions() {
    if (!_status.qualifyingConditions) {
      _status.qualifyingConditions = true; // prevent multiple calls
      this.refreshQualifyingConditions();
    }
    const ret = _state.qualifyingConditions;
    return ret || [];
  }

  get allActiveQualifyingConditions() {
    return InventoryStore.allQualifyingConditions.filter((qc) => qc.IsDeleted == 0);
  }

  get allActivePrimaryQualifyingConditions() {
    return InventoryStore.allActiveQualifyingConditions.filter((qc) => qc.IsPrimary == 1);
  }

  get allActiveSecondaryQualifyingConditions() {
    return InventoryStore.allActiveQualifyingConditions.filter((qc) => qc.IsSecondary == 1);
  }

  get dosages() {
    if (!_status.dosages) {
      _status.dosages = true; // prevent multiple calls
      this.refreshDosages();
    }
    return _state.dosages;
  }

  get sizelist() {
    if (!_status.sizelist) {
      _status.sizelist = true; // prevent multiple calls
      this.refreshSizeList();
    }
    return _state.sizelist;
  }

  get orders() {
    if (!_status.orders) {
      _status.orders = true;
      this.refreshOrders();
    }
    return _state.orders;
  }

  get manifest() {
    if (!_status.manifest) {
      _status.manifest = true;
      this.refreshManifest();
    }
    return _state.manifest;
  }

  get metrcOnboardingManifest() {
    if (!_status.metrcOnboardingManifest) {
      _status.metrcOnboardingManifest = true;
      this.refreshMetrcOnboardingManifest();
    }
    return _state.metrcOnboardingManifest;
  }

  get retailManifest() {
    if (!_status.manifest) {
      _status.retailManifest = true;
      this.refreshRetailManifest();
    }
    return _state.retailManifest;
  }

  get adjustmentReasons() {
    if (!_status.adjustmentReasons) {
      _status.adjustmentReasons = true;
      this.refreshAdjustmentReasons();
    }
    return _state.adjustmentReasons;
  }

  get destructionReasons() {
    if (!_status.destructionReasons) {
      _status.destructionReasons = true;
      this.refreshDestructionReasons();
    }
    return _state.destructionReasons;
  }

  get locChargeCodes() {
    return _state.locchargecodes;
  }

  get WeedmapsCategories() {
    if (!_status.products) {
      _status.products = true; // prevent multiple calls
      this.refreshProducts();
    }
    return _state.WeedmapsCategories;
  }

  set WeedmapsCategories(categories) {
    _state.WeedmapsCategories = categories;
  }

  get WeedmapsSubCategories() {
    return [
      { WeedmapsSubCategoryId: 1, Name: 'Indica' },
      { WeedmapsSubCategoryId: 2, Name: 'Sativa' },
      { WeedmapsSubCategoryId: 3, Name: 'Hybrid' },
    ];
  }

  get LeaflyCategories() {
    return [
      { LeaflyValue: 'Flower', Name: i18next.t('Flower') },
      { LeaflyValue: 'Edible', Name: i18next.t('Edible') },
      { LeaflyValue: 'PreRoll', Name: i18next.t('PreRoll') },
      { LeaflyValue: 'Cartridge', Name: i18next.t('Cartridge') },
      { LeaflyValue: 'Concentrate', Name: i18next.t('Concentrate') },
      { LeaflyValue: 'Accessory', Name: i18next.t('Accessory') },
      { LeaflyValue: 'Clone', Name: i18next.t('Clone') },
      { LeaflyValue: 'Seeds', Name: i18next.t('Seeds') },
      { LeaflyValue: 'Topical', Name: i18next.t('Topical') },
      { LeaflyValue: 'Other', Name: i18next.t('Other') },
    ];
  }

  get tags() {
    if (!_status.tags) {
      _status.tags = true; // prevent multiple calls
      this.refreshTags();
    }

    return _state.tags;
  }

  get externalIds() {
    if (!_status.externalIds) {
      _status.externalIds = true;
      this.refreshExternalIds();
    }

    return _state.externalIds;
  }

  public async refreshOrders() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_ORDERS, data);
      if (resp.Result) {
        _status.orders = true;
        _state.orders = resp.Data;
      }
      this.emitChange(InventoryEventTypes.ORDERS);
    } catch {
      console.warn('orders failed to load');
      _status.orders = false;
    }
  }

  public async refreshManifest() {
    const useMultiStop = UserStore.getState().locProfile.UseMultiStopManifests;
    try {
      const data = { ...UserStore.getApiData() };
      this.emitChange(InventoryEventTypes.LOADING_MANIFEST);
      const resp = await AjaxPromises.POST(endPoints.GET_MANIFESTS, data);
      if (useMultiStop) {
        if (resp.Result) {
          _status.manifest = true;
          _state.manifest = resp.Data;

          let reapedData = [];
          let data = resp.Data;
          data = _.groupBy(data, 'SuperManifestId');
          const groupBySuperManifest = Object.keys(data).map((key) => data[key]);
          groupBySuperManifest.forEach((manifests) => {
            if (manifests[0].SuperManifestId !== null) {
              const metaData = {
                SuperManifestId: manifests[0].SuperManifestId,
                SuperManifestTitle: manifests[0].SuperManifestTitle,
                Title: manifests.map((m: any) => m.Title).join(', '),
                Customer: manifests
                  .map((m: any) => m.Customer)
                  .filter((m, i, a) => a.indexOf(m) === i)
                  .join(', '),
                Date: manifests[0].Date,
                Status: manifests[0].Status,
                TotalItems: manifests.reduce(
                  (accumulator: number, { TotalItems }: { TotalItems: number }) => accumulator + TotalItems,
                  0
                ),
                Type: manifests[0].Type,
                InvoiceNumber: manifests.map((m: any) => m.InvoiceNumber).join(', '),
                SubManifests: manifests,
              };
              reapedData.push(metaData);
            } else {
              reapedData = [...reapedData, ...manifests];
            }
          });
          _state.reapedManifest = reapedData;
        } else {
          NotificationActions.error(`Failed to load manifests ${resp.Message}`);
        }
      } else if (resp.Result) {
        _status.manifest = true;
        _state.manifest = resp.Data;
      } else {
        NotificationActions.error(`Failed to load manifests ${resp.Message}`);
      }

      this.emitChange(InventoryEventTypes.MANIFEST);
    } catch {
      NotificationActions.error(`Failed to load manifests`);
      console.warn('manifest failed to load');
      _status.manifest = false;
    }
  }

  public async refreshMetrcOnboardingManifest() {
    this.emitChange(InventoryEventTypes.LOADING_METRC_ONBOARDING_MANIFEST);
    const resp = await HttpClient.post(
      endPoints.GET_METRC_ONBOARDING_MANIFESTS,
      {},
      'Failed to fetch metrc onboarding manfiests.'
    );

    if (resp) {
      _status.metrcOnboardingManifest = true;
      _state.metrcOnboardingManifest = resp;
      this.emitChange(InventoryEventTypes.METRC_ONBOARDING_MANIFEST);
    }
  }

  public async refreshRetailManifest() {
    try {
      const data = { ...UserStore.getApiData() };
      this.emitChange(InventoryEventTypes.LOADING_RETAIL_MANIFEST);
      const resp = await AjaxPromises.POST(endPoints.GET_RETAIL_MANIFESTS, data);
      if (resp.Result) {
        _status.retailManifest = true;
        _state.retailManifest = resp.Data;
      } else {
        NotificationActions.error(`Failed to load manifests ${resp.Message}`);
      }

      this.emitChange(InventoryEventTypes.RETAIL_MANIFEST);
    } catch {
      NotificationActions.error(`Failed to load manifests`);
      console.warn('manifest failed to load');
      _status.retailManifest = false;
    }
  }

  public async refreshRooms() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_ROOMS, data);
      if (resp.Result) {
        _status.rooms = true;
        _state.rooms = resp.Data;
      }
    } catch (ex) {
      console.warn('Rooms failed to load');
      _status.rooms = false;
      return;
    }
    this.emitChange('room');
  }

  public async deleteRoom(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    return AjaxPromises.POST(endPoints.DELETE_ROOM, data);
  }

  public async deleteProductCategory(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_PRODUCT_CATEGORY, data);
    if (deleteResp.Result) {
      NotificationActions.success('Product category deleted');
    } else {
      NotificationActions.error('Failed to delete product category');
      return;
    }
    await this.refreshProductCategories();
  }

  public async deleteProductGroup(group) {
    const data = { ...UserStore.getApiData(), ...group };
    const resp = await AjaxPromises.POST(endPoints.REMOVE_PRODUCT_GROUP, data);
    if (resp.Result) {
      this.refreshProductGroups();
    } else {
      throw 'Update Product Group Failed';
    }
  }

  public async deleteStrain(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_STRAIN, data);
    if (deleteResp.Result) {
      await this.refreshStrains();
      NotificationActions.success('Strain deleted');
    } else {
      NotificationActions.error('Failed to delete strain');
    }
  }

  public async DeleteQualifyingCondition(qualifyingCondition) {
    qualifyingCondition.Delete = true;
    await this.UpdateQualifyingCondition(qualifyingCondition);
  }

  public async UpdateQualifyingCondition(qualifyingCondition) {
    const successMessage = qualifyingCondition.Delete ? 'Qualifying condition deleted' : '';
    const errorMessage = qualifyingCondition.Delete ? 'Failed to delete qualifying condition' : '';

    const updateResp = await HttpClient.post(
      endPoints.UPDATE_QUALIFYING_CONDITION,
      UserStore.getApiData(qualifyingCondition),
      errorMessage,
      successMessage
    );
    if (!updateResp) {
      return false;
    }
    await this.refreshQualifyingConditions();
    return true;
  }

  public async refreshAdjustmentReasons() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_ADJUSTMENT_REASONS, data);
      if (resp.Result) {
        _status.adjustmentReasons = true;
        _state.adjustmentReasons = resp.Data;
      }
    } catch (ex) {
      console.warn('Adjustment reasons failed to load', ex);
      _status.adjustmentReasons = false;
      return;
    }
    this.emitChange('adjustmentReasons');
  }

  public async refreshDestructionReasons() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_DESTRUCTION_REASONS, data);
      if (resp.Result) {
        _status.destructionReasons = true;
        _state.destructionReasons = resp.Data;
      }
    } catch (ex) {
      console.warn('Destruction reasons failed to load', ex);
      _status.destructionReasons = false;
      return;
    }
    this.emitChange('destructionReasons');
  }

  public async refreshUnits() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_UNITS, data);
      if (resp.Result) {
        _state.units = resp.Data.Units;
        _state.unitTypes = resp.Data.UnitTypes;
      }
      this.emitChange('unit');
    } catch {
      console.warn('Units failed to load');
    }
  }

  public async refreshInventory(queryClient?: QueryClient) {
    try {
      _state.inventory = [];
      _state.inventoryLoading = true;
      this.emitChange('inventory');
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INVENTORY, data);
      const curLoc = UserStore.getState().data.LocId;
      if (curLoc != data.LocId) {
        return;
      }

      if (resp.Result) {
        _status.inventory = true;
        _state.inventory = resp.Data;
      } else {
        NotificationActions.error('Inventory failed to load');
      }
    } catch (err) {
      console.warn('Inventory failed to load');
      return;
    } finally {
      _state.inventoryLoading = false;
      this.emitChange('inventory');
    }

    if (queryClient) {
      void queryClient.invalidateQueries({ queryKey: inventoryPackagesKeys.all });
    }
  }

  public async refreshZeroInventory() {
    try {
      _state.inventoryLoading = true;
      this.emitChange('zeroInventory');
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_ZERO_INVENTORY, data);
      if (resp.Result) {
        _status.zeroInventory = true;
        _state.zeroInventory = resp.Data;
      }
    } catch {
      console.warn('zeroInventory failed to load');
      return;
    } finally {
      _state.inventoryLoading = false;
      this.emitChange('zeroInventory');
    }
  }

  public async refreshProducts() {
    try {
      _state.productsLoading = true;
      this.emitChange('products');
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_PRODUCTS, data);

      if (resp.Result) {
        _status.products = true;
        _state.products = resp.Data.products || [];
        _state.locchargecodes = resp.Data.locchargecodes || [];
        _state.WeedmapsCategories = resp.Data.WeedmapsCategories || [];
      } else if (!resp.Result) {
        NotificationActions.error(`Products failed to load: ${resp.Message}`);
      }
      this.emitChange('product');
    } catch {
      console.warn('Products failed to load');
      _status.products = false;
    } finally {
      _state.productsLoading = false;
      this.emitChange('products');
    }
  }

  public async refreshRetiredProducts() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_RETIRED_PRODUCTS, data);
      if (resp.Result) {
        _status.retiredProducts = true;
        _state.retiredProducts = resp.Data.products || [];
      } else if (!resp.Result) {
        NotificationActions.error(`Retired products failed to load: ${resp.Message}`);
      }
      this.emitChange('product');
    } catch {
      console.warn('Products failed to load');
      _status.retiredProducts = false;
    }
  }

  public async refreshProductCategories() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_PRODUCT_CATEGORIES, data);
      if (resp.Result) {
        _status.productCategories = true;
        _state.productCategories = resp.Data;
      }
      this.emitChange('category');
    } catch (ex) {
      console.warn('Product Categories failed to load');
      console.warn(ex);
      _status.productCategories = false;
    }
  }

  public async refreshRegulatoryCategories() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_REGULATORY_CATEGORIES, data);
      if (resp.Result) {
        _status.regulatoryCategories = true;
        _state.regulatoryCategories = resp.Data;
      }
      this.emitChange('category');
    } catch (ex) {
      console.warn('Regulatory Categories failed to load');
      console.warn(ex);
      _status.regulatoryCategories = false;
    }
  }

  public async refreshVendorTypes() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_VENDOR_TYPE, data);
      if (resp.Result) {
        _status.vendorTypes = true;
        _state.vendorTypes = resp.Data;
      }
      this.emitChange('type');
    } catch (ex) {
      console.warn('Vendor Types failed to load');
      console.warn(ex);
      _status.vendorTypes = false;
    }
  }

  public async refreshProductGroups() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_PRODUCT_GROUPS, data);
      if (resp.Result) {
        _status.productGroups = true;
        _state.productGroups = resp.Data;
      }
      this.emitChange('groups');
    } catch (ex) {
      console.warn('Product Groups failed to load');
      console.warn(ex);
      _status.products = false;
    }
  }

  public async refreshVendors(showSuccess = false) {
    _state.vendorsLoading = true;
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_VENDORS, data);
      if (resp.Result) {
        _status.vendors = true;
        _state.vendors = resp.Data;
        if (showSuccess) {
          NotificationActions.success('Vendors Refreshed');
        }
      } else {
        NotificationActions.error('Vendors failed to load');
      }
      this.emitChange('vendor');
    } catch (ex) {
      console.warn('Vendors failed to load');
      console.warn(ex);
      _status.vendors = false;
    } finally {
      _state.vendorsLoading = false;
    }
  }

  public async refreshInactiveVendors() {
    _state.vendorsLoading = true;
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INACTIVE_VENDORS, data);
      if (resp.Result) {
        _status.vendors = true;
        _state.vendors = resp.Data;
      }
      this.emitChange('vendor');
    } catch (ex) {
      console.warn('Vendors failed to load');
      console.warn(ex);
      _status.vendors = false;
    } finally {
      _state.vendorsLoading = false;
    }
  }

  public async deleteBrand(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_BRAND, data);
    if (deleteResp.Result) {
      await this.refreshBrands();
      NotificationActions.success('Brand deleted');
    } else {
      NotificationActions.error('Failed to delete brand');
    }
  }

  public async refreshBrands() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_BRANDS, data);
      if (resp.Result) {
        _status.brands = true;
        _state.brands = resp.Data;
      }
      this.emitChange('brands');
    } catch (ex) {
      console.warn('Brands failed to load');
      console.warn(ex);
      _status.brands = false;
    }
  }

  public async refreshInactiveBrands() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INACTIVE_BRANDS, data);
      if (resp.Result) {
        _status.brands = true;
        _state.brands = resp.Data;
      }
      this.emitChange('brands');
    } catch (ex) {
      console.warn('Brands failed to load');
      console.warn(ex);
      _status.brands = false;
    }
  }

  public async refreshExternalIds() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_EXTERNAL_IDS, data);
      if (resp.Result) {
        _status.externalIds = true;
        _state.externalIds = resp.Data;
      }
    } catch (ex) {
      console.warn('Batch IDs failed to load');
      _status.externalIds = false;
      return;
    }
    this.emitChange('externalIds');
  }

  public async deleteLineage(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_LINEAGE, data);
    if (!deleteResp.Result) {
      NotificationActions.error('Failed to delete lineage');
      return;
    }
    await this.refreshLineages();
  }

  public async refreshLineages() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_LINEAGES, data);
      if (resp.Result) {
        _status.lineages = true;
        _state.lineages = resp.Data;
      }
      this.emitChange('lineages');
    } catch (ex) {
      console.warn('Lineages failed to load');
      console.warn(ex);
      _status.lineages = false;
    }
  }

  public async refreshInactiveLineages() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INACTIVE_LINEAGES, data);
      if (resp.Result) {
        _status.lineages = true;
        _state.lineages = resp.Data;
      }
      this.emitChange('lineages');
    } catch (ex) {
      console.warn('Lineages failed to load');
      console.warn(ex);
      _status.lineages = false;
    }
  }

  public async deleteDistillation(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_DISTILLATION, data);
    if (deleteResp.Result) {
      await this.refreshDistillations();
      NotificationActions.success('Distillation deleted');
    } else {
      NotificationActions.error('Failed to delete distillation');
    }
  }

  public async refreshDistillations() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_DISTILLATIONS, data);
      if (resp.Result) {
        _status.distillations = true;
        _state.distillations = resp.Data;
      }
      this.emitChange('distillations');
    } catch (ex) {
      console.warn('Distillations failed to load');
      console.warn(ex);
      _status.distillations = false;
    }
  }

  public async refreshInactiveDistillations() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INACTIVE_DISTILLATIONS, data);
      if (resp.Result) {
        _status.distillations = true;
        _state.distillations = resp.Data;
      }
      this.emitChange('distillations');
    } catch (ex) {
      console.warn('Distillations failed to load');
      console.warn(ex);
      _status.distillations = false;
    }
  }

  public async refreshDosages() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_DOSAGE, data);
      if (resp.Result) {
        _status.dosages = true;
        _state.dosages = resp.Data;
      }
      this.emitChange('dosages');
    } catch (ex) {
      console.warn('Dosages failed to load');
      console.warn(ex);
      _status.dosages = false;
    }
  }
  public async updateDosage(dosage) {
    const data = { ...UserStore.getApiData(), ...dosage };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_DOSAGE, data);
    if (!!resp.Result && resp.Data.length > 0) {
      const p = _.find(_state.dosages, (x) => x.DosageId === resp.Data[0].DosageId);

      if (p) {
        Object.assign(p, resp.Data[0]);
      } else {
        _state.dosages.push(resp.Data[0]);
      }
      this.emitChange('dosages');
      return resp.Data[0];
    }
    throw 'Update Dosage Failed';
  }
  public async deleteDosage(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_DOSAGE, data);
    if (deleteResp.Result) {
      await this.refreshDosages();
      NotificationActions.success('Dosage deleted');
    } else {
      NotificationActions.error('Failed to delete dosage');
    }
  }

  public async refreshSizeList() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_SIZELIST, data);
      if (resp.Result) {
        _status.sizelist = true;
        _state.sizelist = resp.Data;
      }
      this.emitChange('sizelist');
    } catch (ex) {
      _status.sizelist = false;
    }
  }
  public async updateSizeListItem(sizelistitem) {
    const data = { ...UserStore.getApiData(), ...sizelistitem };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_SIZELISTITEM, data);
    if (!!resp.Result && resp.Data.length > 0) {
      const p = _.find(_state.sizelist, (x) => x.SizeItemId === resp.Data[0].SizeItemId);

      if (p) {
        Object.assign(p, resp.Data[0]);
      } else {
        _state.sizelist.push(resp.Data[0]);
      }
      this.emitChange('sizelistitem');
      return resp.Data[0];
    }
    throw 'Update Size Failed';
  }
  public async deleteSizeListItem(id, isDeleted) {
    const data = { Id: id, IsDeleted: isDeleted, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_SIZELISTITEM, data);
    if (deleteResp.Result) {
      await this.refreshSizeList();
      NotificationActions.success('Size deleted');
    } else {
      NotificationActions.error('Failed to delete size');
    }
  }

  public async refreshInventoryStatus() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_INVENTORY_STATUS, data);
      if (resp.Result) {
        _status.inventoryStatuses = true;
        _state.inventoryStatuses = resp.Data;
      }
      this.emitChange('inventoryStatuses');
    } catch (ex) {
      console.warn('Inventory Statuses failed to load');
      console.warn(ex);
      _status.inventoryStatuses = false;
    }
  }

  public async refreshStrains() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_STRAINS, data);
      if (resp.Result) {
        _status.strains = true;
        _state.strains = resp.Data;
      }
      this.emitChange('strain');
    } catch (ex) {
      console.warn('Strains failed to load');
      console.warn(ex);
      _status.strains = false;
    }
  }

  public async refreshQualifyingConditions() {
    const resp = await HttpClient.post(
      endPoints.GET_QUALIFYING_CONDITIONS,
      UserStore.getApiData(),
      'Qualifying Conditions failed to load'
    );
    if (resp) {
      _status.qualifyingConditions = true;
      _state.qualifyingConditions = resp;
      this.emitChange('qualifyingConditions');
    } else {
      _status.qualifyingConditions = false;
    }
  }

  public async UpdateProduct(product) {
    const data = { ...UserStore.getApiData(), ...product };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_PRODUCT, data);
    const respDataProduct = resp?.Data[0];

    if (Boolean(resp.Result) && resp.Data.length > 0) {
      const p = _.find(_state.products, ({ ProductId }) => ProductId === respDataProduct.ProductId);

      if (p) {
        Object.assign(p, respDataProduct);
      } else {
        const newProducts = [..._state.products, resp.Data[0]];
        _state.products = newProducts;
      }
      this.emitChange('product');
      return respDataProduct;
    }
    throw resp;
  }

  public async UpdateProducts(productlist) {
    const data = { ...UserStore.getApiData(), ...productlist };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_BULK_PRODUCTS, data);
    if (resp.Result) {
      this.emitChange('product');
      return resp.Result;
    }
    // notification
    throw resp;
  }

  public async updateProductsPrices(productsPrices) {
    const data = { ...UserStore.getApiData(), ...productsPrices };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_PRODUCT_PRICES, data);
    if (resp.Result) {
      this.emitChange('product');
      return resp.Result;
    }
    // notification
    throw resp;
  }

  public async UpdateProductsLocationSpecificDetails(productlist) {
    const data = { ...UserStore.getApiData(), ...productlist };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_BULK_PRODUCTS_LOCATION_SPECIFIC, data);
    if (resp.Result) {
      this.emitChange('product');
      return resp.Result;
    }
    // notification
    throw resp;
  }

  public async UpdateProductCategory(cat) {
    const data = { ...UserStore.getApiData(), ...cat };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_PRODUCT_CATEGORY, data);
    if (!!resp.Result && resp.Data.length > 0) {
      const p = _.find(_state.productCategories, (x) => x.ProductCategoryId === resp.Data[0].ProductCategoryId);

      if (p) {
        Object.assign(p, resp.Data[0]);
      } else {
        _state.productCategories.push(resp.Data[0]);
      }
      this.emitChange('category');
      return resp.Data[0];
    }
    throw 'Update Product Category Failed';
  }

  public async UpdateProductGroup(group) {
    const data = { ...UserStore.getApiData(), ...group };
    const resp = await AjaxPromises.POST(endPoints.SET_PRODUCT_GROUP, data);
    if (resp.Result) {
      this.refreshProductGroups();
    } else {
      throw 'Update Product Group Failed';
    }
  }

  // public async updateVendor(vendor) {
  //     let data = Object.assign({}, UserStore.getApiData(), vendor);
  //     let resp = await AjaxPromises.POST(endPoints.UPDATE_VENDOR, data)
  //     if(!!resp.Result && resp.Data.length > 0) {
  //         let p = _.find(_state.vendors, x => x.VendorId === resp.Data[0].VendorId);

  //         if(!!p) {
  //             Object.assign(p, resp.Data[0]);
  //         }
  //         else {
  //             _state.vendors.push(resp.Data[0]);
  //         }
  //         this.emitChange(_state);
  //         return resp.Data[0];
  //     }
  //     else {
  //         //notification
  //         throw 'Update Product Category Failed';
  //     }
  // }

  public async updateRoom(room) {
    const data = { ...UserStore.getApiData(), ...room };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_ROOM, data);
    if (!!resp.Result && resp.Data.length > 0) {
      const p = _.find(_state.rooms, (x) => x.RoomId === resp.Data[0].RoomId);

      if (p) {
        Object.assign(p, resp.Data[0]);
      } else {
        _state.rooms.push(resp.Data[0]);
      }
      this.emitChange('room');
      return resp.Data[0];
    }
    throw 'Update Room Failed';
  }

  // public async updateInventoryStatus(status) {
  //     let data = Object.assign({}, UserStore.getApiData(), status);
  //     let resp = await AjaxPromises.POST(endPoints.UPDATE_INVENTORY_STATUS, data)
  //     if(!!resp.Result && resp.Data.length > 0) {
  //         let p = _.find(_state.inventoryStatuses, x => x.InventoryStatusId === resp.Data[0].InventoryStatusId);

  //         if(!!p) {
  //             Object.assign(p, resp.Data[0]);
  //         }
  //         else {
  //             _state.inventoryStatuses.push(resp.Data[0]);
  //         }
  //         this.emitChange(_state);
  //         return resp.Data[0];
  //     }
  //     else {
  //         //notification
  //         throw 'Update Inventory Status Failed';
  //     }
  // }

  public async updateStrain(strain) {
    const data = { ...UserStore.getApiData(), ...strain };
    const resp = await AjaxPromises.POST(endPoints.UPDATE_STRAIN, data);
    if (!!resp.Result && resp.Data.length > 0) {
      const p = _.find(_state.strains, (x) => x.StrainId === resp.Data[0].StrainId);

      if (p) {
        Object.assign(p, resp.Data[0]);
      } else {
        _state.strains.push(resp.Data[0]);
      }
      this.emitChange('strain');
      return resp.Data[0];
    }
    throw 'Update Strain Failed';
  }

  public async updatePackage(item) {
    const data = { ...UserStore.getApiData(), ...item };
    data.UserId = UserStore.getState().data.Id;

    const resp = await AjaxPromises.POST(endPoints.UPDATE_PACKAGE, data);
    if (resp.Result) {
      return resp.Data;
    }
    throw resp;
  }

  public async retireProduct(product) {
    const data = { ...UserStore.getApiData(), ...product };
    data.UserId = UserStore.getState().data.Id;

    const resp = await AjaxPromises.POST(endPoints.RETIRE_PRODUCT, data);
    if (resp.Result) {
      const p = _.find(_state.products, (x) => x.ProductId === product.ProductId);
      if (p) {
        p.IsRetired = true;
      }
      this.emitChange('product');
    } else {
      throw 'Retire Product failed';
    }
  }

  public async unretireProduct(product) {
    const data = { ...UserStore.getApiData(), ...product };
    data.UserId = UserStore.getState().data.Id;

    const resp = await AjaxPromises.POST(endPoints.UNRETIRE_PRODUCT, data);
    if (resp.Result) {
      const p = _.find(_state.products, (x) => x.ProductId === product.ProductId);
      if (p) {
        p.IsRetired = false;
      }
      this.emitChange('product');
    } else {
      throw 'Unretire Product failed';
    }
  }

  public refreshTags = async () => {
    const resp = await HttpClient.post(endPoints.GET_TAGS, UserStore.getApiData(), 'Tags failed to load');
    if (resp) {
      _status.tags = true;
      _state.tags = resp;
      this.emitChange('tags');
    } else {
      _status.tags = false;
    }
  };

  public async deleteTag(TagId) {
    const data = { TagId, ...UserStore.getApiData() };
    const deleteResp = await AjaxPromises.POST(endPoints.DELETE_TAGS, data);
    if (deleteResp.Result) {
      await this.refreshTags();
      NotificationActions.success('Tag deleted');
    } else {
      NotificationActions.error('Failed to delete tag');
    }
  }

  emitChange(type) {
    const data = {
      type, // <-- this is not the right way to do this, the emitter already supports event types
      state: _state,
    };
    this.emit(InventoryEvents.CHANGE_EVENT, data);
  }

  addChangeListener(callback, event = InventoryEvents.CHANGE_EVENT) {
    this.on(event, callback);
  }

  removeChangeListener(callback, event = InventoryEvents.CHANGE_EVENT) {
    this.removeListener(event, callback);
  }

  public clear() {
    _state.inventory = [];
    _status.inventory = false;
    _state.zeroInventory = [];
    _status.zeroInventory = false;
    _state.products = [];
    _status.products = false;
    _state.productCategories = [];
    _status.productCategories = false;
    _status.regulatoryCategories = false;
    _state.regulatoryCategories = [];
    _status.vendorTypes = false;
    _state.vendorTypes = [];
    _state.productGroups = [];
    _status.productGroups = false;
    _state.vendors = [];
    _status.vendors = false;
    _state.rooms = [];
    _status.rooms = false;
    _state.inventoryStatuses = [];
    _status.inventoryStatuses = false;
    _state.manifest = [];
    _status.manifest = false;
    _state.retailManifest = [];
    _status.retailManifest = false;
    _state.strains = [];
    _status.strains = false;
    _state.qualifyingConditions = [];
    _status.qualifyingConditions = false;
    _state.adjustmentReasons = [];
    _status.adjustmentReasons = false;
    _state.brands = [];
    _status.brands = false;
    _state.lineages = [];
    _status.lineages = false;
    _state.distillations = [];
    _status.distillations = false;
    _state.tags = [];
    _status.tags = false;
    _state.dosages = [];
    _status.dosages = false;
    _state.sizelist = [];
    _status.sizelist = false;
  }
}

const InventoryStore = new InventoryStoreClass();
InventoryStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.APP_BOOTED:
      InventoryStore.clear();
      InventoryStore.refreshUnits();
      break;

    case UserActions.SELECT_COMPANY:
      InventoryStore.clear();
      InventoryStore.emitChange('all');
      break;

    case AppEvents.LOCATION_LOADED:
      _state.inventory = [];
      _status.inventory = false;
      _state.zeroInventory = [];
      _status.zeroInventory = false;
      _state.inventoryStatuses = [];
      _status.inventoryStatuses = false;
      _state.rooms = [];
      _status.rooms = false;
      _state.manifest = [];
      _status.manifest = false;
      _state.retailManifest = [];
      _status.retailManifest = false;
      _state.regulatoryCategories = [];
      _status.regulatoryCategories = false;
      InventoryStore.emitChange('all');
      break;
    case InventoryActions.PRODUCTS_REFRESHED:
      _status.products = false;
      InventoryStore.emitChange('product');
      break;
  }
});
export default InventoryStore;
