/*
 * Note: This does not include tabbed routes!
 */
export const routes = {
  home: '/home',
  suppliers: '/suppliers',
  products: {
    inventory: {
      index: '/products/inventory',
      receiveInventory: '/products/inventory/receive-inventory',
      receiveHistory: '/products/inventory/receive-history',
      bulkUploadLabs: '/products/inventory/bulk-upload-labs',
      bulkUploadReceive: '/products/inventory/bulk-upload-receive',
    },
    catalog: {
      index: '/products/catalog',
      newProduct: '/products/catalog/newProduct',
      newCoupon: '/products/catalog/newCoupon',
    },
    manifests: {
      index: '/products/manifests',
      wholesale: '/products/manifests/wholesale',
      retail: '/products/manifests/retail',
    },
    purchaseOrders: {
      index: '/products/purchase-orders',
      purchaseOrders: '/products/purchase-orders/purchase-orders',
      pendingApproval: '/products/purchase-orders/pending-approval',
    },
    audits: { index: '/products/audits', indexBeta: '/products/audits-beta' },
    journal: '/products/journal',
    vendors: '/products/vendors',
    manufacturers: '/products/manufacturers',
    brands: '/products/brands',
    strains: '/products/strains',
    configure: {
      index: '/products/configure',
      pricing: '/products/configure/pricing',
      sizes: '/products/configure/sizes',
      fields: '/products/configure/fields',
      inventoryStatuses: '/products/configure/inventory-statuses',
      dosages: '/products/configure/dosages',
      tags: '/products/configure/tags',
      inventoryAdjustments: '/products/configure/inventory-adjustments',
      productGroups: '/products/configure/product-groups',
      categories: '/products/configure/categories',
      formats: '/products/configure/formats',
      kits: '/products/configure/inventory-kits',
    },
    orders: '/products/orders',
  },
  financials: {
    index: '/financials',
    salesSummaryByLocations: '/financials/sales-summary-by-locations',
    salesSummaryForLocation: '/financials/sales-summary-for-location',
    salesBreakdownByLocations: '/financials/sales-breakdown-by-locations',
    salesBreakdownForLocation: '/financials/sales-breakdown-for-location',
  },
  reports: {
    overview: {
      index: '/reports/overview',
      dailySnapshot: '/reports/overview/daily-snapshot',
      monthlySnapshot: '/reports/overview/monthly-snapshot',
      budtenders: '/reports/overview/budtenders',
      products: '/reports/overview/products',
    },
    biTools: {
      index: '/reports/bi-tools',
      dailySnapshot: '/reports/bi-tools/daily-snapshot',
      monthlySnapshot: '/reports/bi-tools/monthly-snapshot',
      budtenders: '/reports/bi-tools/budtenders',
      products: '/reports/bi-tools/products',
      dailyTrends: '/reports/bi-tools/daily-trends',
      salesReports: '/reports/bi-tools/sales-reports',
      inventory: '/reports/bi-tools/inventory',
      explore: '/reports/bi-tools/explore',
    },
    closingReport: {
      index: '/reports/closing-report',
      registers: '/reports/closing-report/registers',
    },
    configure: '/reports/configure',
    configureScheduler: '/reports/configure/scheduler',
    ecommerce: '/reports/ecommerce',
    pointOfSale: '/reports/point-of-sale',
    stateReporting: '/reports/state-reporting',
    sales: '/reports/sales',
    inventory: '/reports/inventory',
    employee: '/reports/employee',
    endOfDay: '/reports/end-of-day',
    taxes: '/reports/taxes',
    wholesale: '/reports/wholesale',
    dataAuditTools: '/reports/data-audit-tools',
    dataTroubleshooting: '/reports/data-troubleshooting',
    dataValidation: '/reports/data-validation',
    corporate: '/reports/corporate',
    cultivation: '/reports/cultivation',
    delivery: '/reports/delivery',
    asyncReports: '/reports/queued-reports',
  },
  reportsV2: {
    index: '/reports',
    overview: {
      index: '/reports/overview',
      dailySnapshot: '/reports/overview/daily-snapshot',
      monthlySnapshot: '/reports/overview/monthly-snapshot',
      budtenders: '/reports/overview/budtenders',
      products: '/reports/overview/products',
    },
    biTools: {
      index: '/reports/bi-tools',
      dailySnapshot: '/reports/bi-tools/daily-snapshot',
      monthlySnapshot: '/reports/bi-tools/monthly-snapshot',
      budtenders: '/reports/bi-tools/budtenders',
      products: '/reports/bi-tools/products',
      dailyTrends: '/reports/bi-tools/daily-trends',
      salesReports: '/reports/bi-tools/sales-reports',
      inventory: '/reports/bi-tools/inventory',
      explore: '/reports/bi-tools/explore',
    },
    closingReport: {
      index: '/reports/closing-report',
      registers: '/reports/closing-report/registers',
    },
    asyncReports: '/reports/queued-reports',
    sales: {
      index: '/reports/sales',
      overview: '/reports/sales/overview',
      salesSummaryByLocations: '/reports/sales/overview/sales-summary-by-locations',
      salesSummaryForLocation: '/reports/sales/overview/sales-summary-for-location',
      salesBreakdownByLocations: '/reports/sales/overview/sales-breakdown-by-locations',
      salesBreakdownForLocation: '/reports/sales/overview/sales-breakdown-for-location',
      reports: '/reports/sales/reports',
    },
    inventory: {
      index: '/reports/inventory',
      overview: '/reports/inventory/overview',
      reports: '/reports/inventory/reports',
      inventoryRemaining: '/reports/inventory/inventory-remaining',
      inventoryExpiring: '/reports/inventory/inventory-expiring',
    },
    employee: {
      index: '/reports/employee',
      performance: '/reports/employee/performance',
      reports: '/reports/employee/reports',
      budtenderOverview: '/reports/employee/budtender-overview',
    },
    marketing: '/reports/marketing',
    operations: '/reports/operations',
    configure: '/reports/configure',
  },
  customers: {
    customers: '/customers/customers',
    segments: {
      index: '/customers/segments',
      audience: '/customers/segments/:segmentId/audience',
    },
    customerGroups: '/customers/customer-groups',
    configure: '/customers/configure',
    createNew: '/customers/customers/new',
    doctors: '/customers/configure/doctors',
    qualifyingConditions: '/customers/configure/qualifying-conditions',
    fields: '/customers/configure/fields',
  },
  registers: {
    overview: '/registers/overview',
    configure: {
      index: '/registers/configure',
      adjustments: '/registers/configure/register-adjustments',
      cancellations: '/registers/configure/cancellations',
      cards: '/registers/configure/cards',
      guestlistStatus: '/registers/configure/guestlist-status',
      orderWorkflow: '/registers/configure/order-workflow',
      returns: '/registers/configure/returns',
      visits: '/registers/configure/customer-visits',
      voids: '/registers/configure/voids',
    },
    transactions: {
      index: '/registers/transactions',
      byRegister: '/registers/transactions/by-register',
      allTransactions: '/registers/transactions/all-transactions',
      bulkActions: {
        index: '/registers/transactions/bulk-actions',
        dropCash: '/registers/transactions/bulk-actions/bulk-drop-cash',
        closeOut: '/registers/transactions/bulk-actions/bulk-close-out',
      },
    },
  },
  marketing: {
    discounts: {
      index: '/marketing/discounts',
      createNew: '/marketing/discounts/discount/new',
    },
    loyalty: {
      settings: '/marketing/loyalty',
      loyaltyAdjustments: '/marketing/loyalty/loyalty-adjustments',
    },
    externalLoyalty: {
      index: '/marketing/external-loyalty',
      logs: '/marketing/external-loyalty/logs',
      settings: '/marketing/external-loyalty/settings',
      transactions: '/marketing/external-loyalty/transactions',
    },
    campaigns: {
      index: '/marketing/campaigns',
    },
  },
  delivery: {
    configure: {
      index: '/delivery/configure',
      cars: '/delivery/configure/cars',
      drivers: '/delivery/configure/drivers',
      zones: '/delivery/configure/zones',
      thresholds: '/delivery/configure/thresholds',
      formats: '/delivery/configure/formats',
      deliveryTimes: '/delivery/configure/delivery-times',
    },
  },
  cultivation: {
    cultivationApiQueue: '/cultivation/cultivation-api-queue',
    plants: {
      index: '/cultivation/plants',
      allPlants: '/cultivation/plants/plants',
      plantGroups: '/cultivation/plants/plant-groups',
      scanPlantsIntoRoom: '/cultivation/plants/scan-plants-into-room',
      scanPlantsIntoHarvest: '/cultivation/plants/scan-plants-into-harvest',
      scanPlantsIntoRetirement: '/cultivation/plants/scan-plants-into-retirement',
      moveSubroomsIntoRooms: '/cultivation/plants/move-subrooms-into-rooms',
      addPlantIntoInventory: '/cultivation/plants/add-plant-into-inventory',
    },
    plantGroups: '/cultivation/plant-groups',
    harvests: '/cultivation/harvests',
    batches: '/cultivation/batches',
    waste: '/cultivation/waste',
    audits: '/cultivation/audits',
    configure: {
      index: '/cultivation/configure',
      cultivationAdjustments: '/cultivation/configure/cultivation-adjustments',
      retireWasteMethods: '/cultivation/configure/retire-waste-methods',
    },
  },
  manufacturing: {
    assemblies: '/manufacturing/assemblies',
    billOfMaterials: '/manufacturing/bill-of-materials',
    recipes: '/manufacturing/recipes',
    recipeBatches: '/manufacturing/recipes/recipe-batches',
    infusions: '/manufacturing/infusions',
    distillations: '/manufacturing/distillations',
  },
  organization: {
    index: '/organization',
    users: {
      index: 'users',
      users: 'users',
      permissionGroups: 'permission-groups',
    },
  },
  traceability: {
    integrationAudit: '/traceability/integration-audit',
    cultivationIntegrationAudit: '/traceability/cultivation-integration-audit',
    receiptAudit: '/traceability/receipt-audit',
    logs: {
      index: '/traceability/logs',
      errors: '/traceability/logs/errors',
      batchMode: '/traceability/logs/batch-mode-logs',
    },
  },
  settings: {
    account: '/settings/account',
    users: {
      index: '/settings/users',
      users: '/settings/users/users',
      permissionGroups: '/settings/users/permission-groups',
      eventsAudit: '/settings/users/events-audit',
    },
    location: '/settings/location',
    integrations: {
      index: '/settings/integrations',
      metrc: {
        index: '/settings/integrations/metrc',
        pull: '/settings/integrations/metrc/pull',
        settings: '/settings/integrations/metrc/settings',
      },
      biotrack: {
        index: '/settings/integrations/biotrack',
        settings: '/settings/integrations/biotrack/settings',
      },
      mcdms: '/settings/integrations/mcdms',
      virginiaBioTrack: '/settings/integrations/virginia-biotrack',
      vccb: '/settings/integrations/vccb',
      utahics: '/settings/integrations/utahics',
      canpay: '/settings/integrations/canpay',
      weedmaps: '/settings/integrations/weedmaps',
      weedmaps2: '/settings/integrations/weedmaps2',
      leaflink: '/settings/integrations/leaflink',
      cashlessAtm: '/settings/integrations/cashless-atm',
      planetpayments: '/settings/integrations/planetpayments',
      paynetworx: '/settings/integrations/pin-debit',
      paymentshub: '/settings/integrations/dutchie-hub',
      dutchiepay: '/settings/integrations/dutchiepay',
      nonIntegratedPayments: '/settings/integrations/non-integrated-payments',
      springbig: '/settings/integrations/springbig',
      emailMarketing: '/settings/integrations/email-marketing',
      smsMarketing: '/settings/integrations/sms-marketing',
      sso: '/settings/integrations/sso',
      ccrs: '/settings/integrations/ccrs',
      linx: '/settings/integrations/linx',
      idscan: '/settings/integrations/idscan',
      leaf: '/settings/integrations/leaf',
      mmur: '/settings/integrations/mmur',
      adilas: '/settings/integrations/adilas',
      leafly: '/settings/integrations/leafly',
      quickbooks: '/settings/integrations/quickbooks',
      mn: '/settings/integrations/mn',
      tookan: '/settings/integrations/tookan',
      onfleet: '/settings/integrations/onfleet',
      skylight: '/settings/integrations/skylight',
      trancloud: '/settings/integrations/trancloud',
      biomauris: '/settings/integrations/iowa-dph',
      alpineiq: '/settings/integrations/alpineiq',
      fyllo: '/settings/integrations/fyllo',
      spin: '/settings/integrations/spin',
      workwave: '/settings/integrations/workwave',
      pmp: '/settings/integrations/pmp',
      massCip: '/settings/integrations/mass-cip',
      hypur: '/settings/integrations/hypur',
      sprout: '/settings/integrations/sprout',
      trym: '/settings/integrations/trym',
      ocs: '/settings/integrations/ocs',
      generipay: {
        index: '/settings/integrations/generipay/:integrationType',
        settings: '/settings/integrations/generipay/:integrationType/settings',
        deviceConfiguration: '/settings/integrations/generipay/:integrationType/device-configuration',
        registerSettings: '/settings/integrations/generipay/:integrationType/register-settings',
      },
    },
    devices: '/settings/devices',
    receipts: '/settings/receipts',
    rooms: {
      index: '/settings/rooms/rooms',
      new: '/settings/rooms/rooms/new',
      list: '/settings/rooms/rooms/rooms',
      subrooms: '/settings/rooms/rooms/subrooms',
    },
    labels: '/settings/labels',
    addlabels: '/settings/labels/labels',
    taxesAndCompliance: {
      index: '/settings/taxes-and-compliance',
      taxes: '/settings/taxes-and-compliance/taxes',
      purchaseLimits: '/settings/taxes-and-compliance/purchase-limits',
    },
    feesDonations: '/settings/fees-donations',
    timeClock: '/settings/time-clock',
  },
  superuser: {
    tools: '/superuser/tools',
    toolsOther: '/superuser/tools/other',
    logs: '/superuser/logs',
    management: '/superuser/management',
    ssoGlobalconfig: '/superuser/sso-globalconfig',
    manifestOptions: '/superuser/manifest-options',
    auditTables: '/superuser/audit-tables',
    provincialCatalogUpload: '/superuser/provincial-catalog-upload',
    metrcTools: '/superuser/metrc-tools',
    features: '/superuser/features',
    editLsp: '/superuser/edit-lsp',
    createLsp: '/superuser/create-lsp',
    publicApi: '/superuser/public-api',
    lookerAdmin: '/superuser/looker-admin',
    documentation: '/superuser/documentation',
    bulkUpdateData: '/superuser/bulk-update-data',
    licenseTypes: '/superuser/license-types',
    dataWipe: '/superuser/data-wipe',
    dataImport: '/superuser/data-import',
    locationManager: '/superuser/location-manager',
    publishLocation: '/superuser/publish-location',
    importData: '/superuser/import-data',
  },
  help: {
    tutorials: '/help/tutorials',
  },
};
