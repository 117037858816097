import { formatMoney } from 'src/app/utils/formatters';

import type { GridColDef } from '@mui/x-data-grid-pro';

const dateTimeColumnNames = [
  // Used for reporting 2.0
  'inventoryreceiveddate',
  'lastinventoryauditdate',
  'inventoryexpirationdate',
  'inventoryallocationdate',
  'inventorysnapshotdate',
  'ordertime',
  // Other
  'transactiondate',
  'voiddate',
  'canceldate',
  'closedate',
  'harvestdate',
  'transactiondatetime',
  'createdon',
  'ftpdttm',
  'lastauditeddate',
  'destroyedon',
  'firstvisited',
  'checkindate',
  'firstpurchase',
  'lastpurchase',
  'assignedtoregister',
  'checkindate',
  'transactioncompleted',
  'transaction_timestamp_local',
  'order_date',
];
const dateColumnNames = [
  // Used for reporting 2.0
  'inventoryusebydate',
  'orderdate',
  'returndate',
  'voiddate',
  // Other
  'posdate',
  'discdatefrom',
  'discdateto',
  'biotrackdt',
  'date',
  'endofmonth',
  'posinvoiceddate',
  'posdelivereddate',
  'dateofbirth',
  'hireddate',
  'harvestdate',
  'curedate',
  'batchdt',
  'statusdate',
  'batchclosed',
  'packagingdate',
  'inventorydate',
  'issueddate',
  'batchclosedate',
  'harvestdate',
  'statedate',
  'scheduleddate',
  'thcdate',
  'testdate',
  'testeddate',
  'packagedate',
  'effectivedate',
  'expirationdate',
  'extractiondate',
  'wastedate',
  'created',
  'recondate',
  'closeddate',
  'hiredate',
  'transdate',
  'enddate',
  'startdate',
  'notedate',
  'membersince',
  'firsttransaction',
  'lasttransaction',
  'mmjidexpirationdate',
  'mjstateexpdate',
  'dob',
  'effective_date_local',
  'return_transaction_date',
  'estimated_deposit_date',
];
const priceColumnNames = [
  // Used for reporting 2.0
  'amountpaidincash',
  'amountpaidincheck',
  'amountpaidincredit',
  'amountpaidindebit',
  'amountpaidindutchiepay',
  'amountpaidingiftcards',
  'amountpaidinmmap',
  'amountpaidinelectronic',
  'cannabisdiscountamount',
  'cannabisgrosssales',
  'cannabisnetsales',
  'cashreturnedtocustomer',
  'couponamount',
  'discountedamount',
  'grossaov',
  'grosssales',
  'inventorycost',
  'inventoryprice',
  'inventoryprofit',
  'loyaltyasdiscount',
  'loyaltyaspayment',
  'loyaltyearned',
  'netaov',
  'netaovw/tax',
  'netsales',
  'netsalesw/rounding',
  'netsalesw/tax',
  'non-cannabisdiscountamount',
  'non-cannabisgrosssales',
  'non-cannabisnetsales',
  'non-revenuefees/donations',
  'ordercosts',
  'orderprofit',
  'returnedamount',
  'revenuefees/donations',
  'roundedamount',
  'salesminusdiscountsandreturnsw/taxes',
  'taxamount',
  'tipamount',
  'totalincome',
  // Other
  'rebate_amount',
  'totalspend',
  'avgcart',
  'totalinvoice',
  'paidincash',
  'paidindebit',
  'paidincredit',
  'cashpaidback',
  'avgpercart',
  'paidinloyalty',
  'unitprice',
  'discountamt',
  'poscouponamt',
  'postax',
  'posproductcost',
  'posnetprofit',
  'voidamt',
  'cashpaid',
  'debitpaid',
  'creditpaid',
  'epaymentpaid',
  'cannabissales',
  'noncannabissales',
  'duecustomer',
  'loyaltyamt',
  'startbalance',
  'transactionamt',
  'endingbalance',
  'amount',
  'discounts',
  'totalsales',
  'netsales',
  'tax',
  'cost',
  'netprofit',
  'price',
  'retail',
  'wholesale',
  'soldprice',
  'priceperunit',
  'taxamount',
  'endingbalance',
  'total',
  'totalprice',
  'discount',
  'unitdiscount',
  'subtotal',
  'totaldiscount',
  'totalcost',
  'productunitprice',
  'producttotalprice',
  'checkpaid',
  'transaction_amount_local',
  'gross_amount_local',
  'discount_amount_local',
  'tax_amount_local',
  'tax_amount',
  'merchant_fee_amount_local',
  'transaction_tip_amount_local',
  'disbursement_amount_local',
  'transaction_amount',
  'transaction_fee_amount',
  'transaction_tip_amount',
  'tip_amount',
  'disbursement_amount',
  'totalafterdiscount',
  'nutrientcost',
  'statetax',
  'non-cannabisitems',
  'non-mmisnon-cannabis',
  'excisetax',
  'nonexcisetax',
  'totalpaid',
  'cogs',
  'localtaxrec',
  'non-mmiccannabisitem',
  'countytax',
  'totalincome',
  'netsales',
  'ach_return_amount',
  'grosssales',
  'discountedamount',
  'discounted_amount',
  'invoicetotal',
  'inventorycost',
  'cannabisgrosssales',
  'cannabisdiscountamount',
  'cannabisnetsales',
  'non-cannabisgrosssales',
  'discountedamountonnon-cannabis',
  'non-cannabisdiscountamount',
  'non-cannabisnetsales',
  'deposit_amount',
  'card_activation_fee',
  'revenuefeesdonations',
  'nonrevenuefeesdonation',
  'paymentamount',
  'giftpaid',
  'mmappaid',
  'electronicpaid',
  'manualpaid',
  'netsalesoncannabis',
  'noncannabisgrosssales',
  'noncannabisdiscountamount',
  'noncannabisnetsales',
];

const binaryColumnNames = [
  'isreturnorder',
  'iscategorycannabis',
  'isreturnorder',
  'isvoidedorder',
  'isdeliveryorder',
  'ispreorder',
  'isreturn',
];

const hiddenColumnNames = ['uuid', 'match', 'isselected'];

export function getAutoTypesForColumn({ columnName }: { columnName: string }): Partial<GridColDef> | undefined {
  const lowerCaseColumnName = columnName.toLowerCase().replace(/\s+/g, '');
  if (dateTimeColumnNames.includes(lowerCaseColumnName)) {
    return {
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    };
  }

  if (dateColumnNames.includes(lowerCaseColumnName)) {
    return { type: 'date', valueGetter: ({ value }) => value && new Date(value) };
  }

  if (priceColumnNames.includes(lowerCaseColumnName)) {
    return { type: 'number', valueFormatter: ({ value }) => formatMoney(value) };
  }

  if (hiddenColumnNames.includes(lowerCaseColumnName)) {
    return { hide: true };
  }

  if (binaryColumnNames.includes(lowerCaseColumnName)) {
    return { type: 'string', valueGetter: ({ value }) => (value === 1 || value === true ? 'Yes' : 'No') };
  }

  // special case for reporting v2
  if (lowerCaseColumnName === 'isnewcustomer') {
    return { type: 'string', valueGetter: ({ value }) => (value === 1 ? 'New' : 'Existing') };
  }

  return undefined;
}
