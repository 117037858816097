/* eslint-disable */
import React from 'react';

import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import Cookies from 'universal-cookie';

import { useLDFlag } from './app/hooks/use-ld-flag';
import { LD } from './app/constants/launch-darkly-flags';

import './assets/css/main.scss';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import { useNotificationActions } from './app/state/notifications';
import { cleanLdKey } from './app/utils/clean-ld-key';
import { getEnvDetails } from './app/utils/get-env-details';
import { initDatadogRum } from './app/utils/init-datadog-rum';
import { initLogRocket } from './app/utils/init-logrocket';
import { initPendo } from './app/utils/init-pendo';
import i18n from './app_deprecated/utils/lang/i18n';
import { DarkModeProvider } from './app/state/dark-mode';
import { Root } from './root';
import { ReactQueryError, RevokedSessionError } from './app/queries/utils';
import PageDisabledEmptyState from './app/layout/main/page-disabled-empty-state';

// Import all the stores so they register for any App_BOOT events
// Do not remove these or the app won't work correctly
import AccountingStore from './app_deprecated/stores/AccountingStore';
import AppStore from './app_deprecated/stores/AppStore';
import ConfigStore from './app_deprecated/stores/ConfigStore';
import CultivationStore from './app_deprecated/stores/CultivationStore';
import CustomerTypeStore from './app_deprecated/stores/CustomerTypeStore';
import DeliveryStore from './app_deprecated/stores/DeliveryStore';
import ExternalCategoriesStore from './app_deprecated/stores/ExternalCategoriesStore';
import GeneralStore from './app_deprecated/stores/GeneralStore';
import InventoryStore from './app_deprecated/stores/InventoryStore';
import LabelStore from './app_deprecated/stores/LabelStore';
import MMURFormsStore from './app_deprecated/stores/MMURFormsStore';
import NotificationStore from './app_deprecated/stores/NotificationStore';
import PosStore from './app_deprecated/stores/PosStore';
import PricingStore from './app_deprecated/stores/PricingStore';
import PrinterStore from './app_deprecated/stores/PrinterStore';
import ProductTaxCategoryStore from './app_deprecated/stores/ProductTaxCategoryStore';
import PurchaseLimitCategoryStore from './app_deprecated/stores/PurchaseLimitCategoryStore';
import RecipeBatchStore from './app_deprecated/stores/RecipeBatchStore';
import ReportDashboardStore from './app_deprecated/stores/ReportDashboardStore';
import ReportingStore from './app_deprecated/stores/ReportingStore';
import SavedOrderStore from './app_deprecated/stores/SavedOrderStore';
import settings from './app_deprecated/stores/SettingsStore';
import TaxRateStore from './app_deprecated/stores/TaxRateStore';
import UserStore from './app_deprecated/stores/UserStore';

/* eslint-enable */

const ddRum = initDatadogRum();
initLogRocket();
initPendo();

(async () => {
  let environment = null;
  let ldKey = null;
  let region = null;
  try {
    const envDetails = await getEnvDetails();
    if (envDetails.Result) {
      environment = envDetails.Data;
      ldKey = envDetails.Data.LaunchDarklyClientKey;
      region = envDetails.Data.Region;
    }
  } catch (e) {
    console.warn(`Unable to load LaunchDarkly client key: ${e}`);
    if (e.status === 503) {
      ReactDOM.render(
        // eslint-disable-next-line react/jsx-filename-extension
        <PageDisabledEmptyState />,
        document.getElementById('template')
      );
      return;
    }
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldKey || process.env.LD_PRODUCTION_KEY || '',
    context: {
      kind: 'user',
      key: 'eng-internal',
      email: 'engineering@dutchie.com',
      region: region || '',
      host: window.location.hostname,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            ddRum.addFeatureFlagEvaluation(cleanLdKey(key), detail.value);
          },
        },
      ],
    },
  });

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <LDProvider>
          <DarkModeProvider>
            <CustomQueryClientProvider environment={environment} />
          </DarkModeProvider>
        </LDProvider>
      </RecoilRoot>
    </I18nextProvider>,
    document.getElementById('template')
  );
})();

// temporarily move this to be inside of the recoil root so we can
// use the useNotificationActions hook
function CustomQueryClientProvider({ environment }) {
  const notifications = useNotificationActions();
  const flagEnabled = useLDFlag(LD.LOG_OUT_ON_SESSION_REVOCATION);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        if (error instanceof RevokedSessionError && flagEnabled) {
          const cookies = new Cookies();
          const session = cookies.get('LeafLogixSessionInfo');
          if (session !== undefined) {
            UserStore.logout();
            queryClient.clear();
            notifications.error({ message: 'Your session has expired. Please login again to proceed.' });
          }
        }
        if (error instanceof ReactQueryError) {
          notifications[error.action](error.args);
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Root environment={environment} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
