/**
 * Combines a date with a time string to create a new Date object
 * @param date The base date to use
 * @param time The time string in format "hh:mm AM/PM"
 * @param enableTime Whether time functionality is enabled
 * @returns A new Date object with the combined date and time
 */
export function combineDateWithTime(date: Date, time?: string, enableTime = false): Date {
  const dateWithTime = new Date(date);

  if (!enableTime || !time) {
    return dateWithTime;
  }

  const timeMatch = /(\d+):(\d+)\s*(AM|PM)?/i.exec(time);
  if (!timeMatch) {
    return dateWithTime;
  }

  const [, hoursStr, minutesStr, period] = timeMatch;
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const isPM = period?.toUpperCase() === 'PM';

  // Convert 12-hour format to 24-hour format
  if (isPM && hours !== 12) {
    hours += 12;
  } else if (!isPM && hours === 12) {
    hours = 0;
  }

  dateWithTime.setHours(hours, minutes, 0, 0);
  return dateWithTime;
}
