import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { ServerPaginatedDropdownMultiple } from 'src/app/components/lib/dropdown/server-paginated-dropdowns/dropdown-multiple';
import { ModalDataGrid, useGridApiRef } from 'src/app/components/lib/table';
import { Status } from 'src/app/pages/manufacturing/assemblies/constants';
import { usePackagesDropdownData } from 'src/app/queries/graphql/inventory-packages/use-dropdown-data';
import { FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';

import { buildAvailableQtyString, buildInputPackageLabel } from '../../../../utils';
import { useAssemblyFormStateContext } from '../../../use-form-state';

import { useInputPackageColumns } from './use-input-package-columns';

import type { InputPackage } from '../../../../types';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { GridColDef } from 'src/app/components/lib/table';

type InputPackagesProps = {
  inputIndex: number;
  outputIndex: number;
};

export function InputPackages({ inputIndex, outputIndex }: InputPackagesProps) {
  const gridApiRef = useGridApiRef();
  const columns = useInputPackageColumns({ outputIndex, inputIndex });
  const [{ formData, isReadonly }, dispatch] = useAssemblyFormStateContext();
  const isAssemblyInProgress = formData.assemblyStatusId === Status.IN_PROGRESS;
  const inputItem = formData.outputs[outputIndex]?.inputItems[inputIndex];
  const { assemblyInputPackages, skip } = inputItem;

  const selectedPackages: DropdownMenuOption[] = assemblyInputPackages.map(
    ({ packageId, batchName, quantity, roomName, serialNumber }) => ({
      id: packageId,
      label: buildInputPackageLabel({
        batchName,
        quantity,
        roomName,
        serialNumber,
      }),
    })
  );

  const productFilter = {
    value: inputItem.productId ?? '',
    fields: ['product.id'],
    operator: FilterOperator.EQUALS,
    logicalOperator: LogicalOperator.OR,
  };

  const categoryFilter = {
    value: inputItem.productTypeId ?? '',
    fields: ['product.productTypeNavigation.id'],
    operator: FilterOperator.EQUALS,
    logicalOperator: LogicalOperator.OR,
  };

  const unitFilter = {
    value: inputItem.unitId ?? '',
    fields: ['unitId'],
    operator: FilterOperator.EQUALS,
    logicalOperator: LogicalOperator.OR,
  };

  const dynamicFilters = inputItem.inputItemTypeId === 1 ? [productFilter, unitFilter] : [categoryFilter, unitFilter];

  const { error, isFetching, options, morePagesExist, fetchNextPage, setFilter, infiniteDropDownMap } =
    usePackagesDropdownData({ enabled: !skip, injectedItems: selectedPackages, dynamicFilters });

  const handleSelect = (selectedPackages: DropdownMenuOption[]) => {
    const selectedPackageIds = selectedPackages.map((option) => option.id);
    // get a list of packageIds already selected
    const { existingSelectedPackageIds, newSelectedPackageIds } = selectedPackageIds.reduce<{
      existingSelectedPackageIds: number[];
      newSelectedPackageIds: number[];
    }>(
      (acc, packageId) => {
        if (assemblyInputPackages.some((inputPackage) => inputPackage.packageId === packageId)) {
          acc.existingSelectedPackageIds.push(Number(packageId));
        } else {
          acc.newSelectedPackageIds.push(Number(packageId));
        }
        return acc;
      },
      { existingSelectedPackageIds: [], newSelectedPackageIds: [] }
    );

    // remove existing selected packages
    const filteredExistingPackages = assemblyInputPackages.filter((inputPackage) =>
      existingSelectedPackageIds.includes(inputPackage.packageId)
    );
    const newPackages: InputPackage[] = newSelectedPackageIds.map((packageId) => {
      const newData = infiniteDropDownMap.get(packageId);
      return {
        assemblyInputPackageId: null,
        available:
          !!newData?.quantity && !!newData?.unit?.abbreviation
            ? buildAvailableQtyString(newData.quantity, newData.unit.abbreviation)
            : '',
        availableQuantity: newData?.quantity ?? 0,
        batchName: newData?.batch?.batchName ?? '',
        packageId,
        cost: newData?.cost ?? null,
        quantity: null,
        roomName: newData?.room?.roomNo ?? '',
        serialNumber: newData?.serialNumber ?? '',
        unitId: newData?.unit?.unitId ?? null,
        useByDate: newData?.useByDate ?? '',
        uuid: uuidv4(),
      };
    });

    const updatedPackages = [...filteredExistingPackages, ...newPackages];
    dispatch({
      type: 'set-input-packages',
      payload: { inputIndex, outputIndex, value: updatedPackages },
    });
  };
  return (
    <>
      <ServerPaginatedDropdownMultiple
        disabled={isAssemblyInProgress || isReadonly || !!error}
        fetchNextPage={fetchNextPage}
        isFetching={isFetching}
        morePagesExist={morePagesExist}
        options={options}
        placeholder='Select package(s)'
        setFilterString={setFilter}
        setValue={handleSelect}
        value={selectedPackages}
      />
      {assemblyInputPackages.length > 0 && (
        <ModalDataGrid
          apiRef={gridApiRef}
          autoHeight
          columns={columns as GridColDef[]}
          disablePagination
          disableSelectionOnClick
          getRowId={(row) => row.uuid}
          minHeight='unset'
          name='inputs-grid'
          rows={assemblyInputPackages}
        />
      )}
    </>
  );
}
