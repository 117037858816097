{
  "name": "back-office",
  "version": "0.144.2",
  "description": "",
  "scripts": {
    "clear-parcel-cache": "rm -rf .parcel-cache",
    "start": "parcel src/index.html --port 8080 --hmr-port 8081",
    "start-dev": "parcel src/index.html",
    "build": "parcel build src/index.html --dist-dir \"build/ui_src\" --public-url \"/ui_src\"",
    "build-static": "parcel build src/index.html --dist-dir \"build\"",
    "serve-static": "node e2e-server.js",
    "postbuild": "mv ./build/ui_src/index.html ./build/index.html && mv ./build/ui_src/assets ./build/assets || echo \"skipping move\"",
    "sonarqube": "sonar-scanner",
    "knit": "yarn && yarn start",
    "storybook": "start-storybook -p 6006",
    "build-storybook": "build-storybook",
    "chromatic": "chromatic --project-token=CHROMATIC_PROJECT_TOKEN --auto-accept-changes",
    "lint": "eslint",
    "lint-ci": "eslint --no-eslintrc -c .eslintrc-ci.js \"src/app/**/*.*\"",
    "lint-nibble": "eslint-nibble \"src/app/**/*.*\"",
    "lint-e2e": "eslint \"e2e/**/*.ts\"",
    "lint-e2e-local": "files=$(git diff --name-only --diff-filter=d $(git rev-parse HEAD^) $(git rev-parse HEAD) | grep \"e2e/.*\\.ts$\"); if [ ! -z \"$files\" ]; then echo \"Performing lint check\"; echo \"$files\" | xargs yarn eslint; fi",
    "build-coverage-report": "npx c8 report",
    "build-e2e-report": "npx tsx e2e/reporters/custom-github/report_builder.ts",
    "e2e-slack-report": "npx tsx e2e/reporters/slack_notifier.ts",
    "test:jest": "jest",
    "test:component": "playwright test -c playwright-ct.config.ts",
    "test:e2e": "playwright test",
    "test:e2e-debug": "playwright test --headed --workers=1 --reporter=line --debug",
    "test:e2e-playground": "playwright test --ui --workers=5",
    "prepare": "husky install",
    "codegen": "graphql-codegen --config codegen.ts",
    "knip": "npx knip",
    "canna-link": "sh ./scripts/link_canna.sh link",
    "canna-unlink": "sh ./scripts/link_canna.sh unlink",
    "canna-update": "sh ./scripts/update_canna.sh"
  },
  "staticFiles": {
    "staticPath": "assets",
    "staticOutPath": "assets"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@dlenroc/testrail": "^1.7.2",
    "@dutchie/canna-fixtures": "^25.321.1810",
    "@graphql-codegen/cli": "2.16.4",
    "@graphql-codegen/client-preset": "1.2.6",
    "@graphql-codegen/introspection": "2.2.3",
    "@graphql-codegen/schema-ast": "^4.0.0",
    "@graphql-codegen/typescript-react-query": "^4.1.0",
    "@graphql-eslint/eslint-plugin": "^3.16.1",
    "@parcel/transformer-graphql": "^2.2.1",
    "@parcel/transformer-sass": "^2.8.3",
    "@playwright/experimental-ct-react": "^1.22.2",
    "@slack/web-api": "^6.9.0",
    "@storybook/addon-actions": "^6.4.19",
    "@storybook/addon-essentials": "^6.4.19",
    "@storybook/addon-links": "^6.4.19",
    "@storybook/builder-webpack5": "^6.4.19",
    "@storybook/manager-webpack5": "^6.4.19",
    "@storybook/preset-scss": "^1.0.3",
    "@storybook/react": "^6.4.19",
    "@swc/core": "^1.2.207",
    "@swc/jest": "^0.2.21",
    "@testing-library/dom": "^9.0.1",
    "@testing-library/jest-dom": "^5.16.4",
    "@testing-library/react": "^12",
    "@testing-library/react-hooks": "^8.0.1",
    "@testing-library/user-event": "^14.2.1",
    "@types/cleave.js": "^1.4.0",
    "@types/lodash": "^4.14.77",
    "@types/node": "^18.11.18",
    "@types/react": "16.9.45",
    "@types/react-window": "^1.8.5",
    "@types/sinon": "^10.0.15",
    "@types/styled-components": "^5.1.26",
    "@types/zxcvbn": "^4.4.1",
    "@typescript-eslint/eslint-plugin": "^5.5.0",
    "@typescript-eslint/parser": "^5.5.0",
    "allure-playwright": "^2.0.0-beta.19",
    "buffer": "^5.5.0",
    "chromatic": "^6.2.1",
    "commander": "^11.1.0",
    "crypto": "^1.0.1",
    "css-loader": "5.2.7",
    "eslint": "^8.3.0",
    "eslint-config-airbnb": "^19.0.1",
    "eslint-config-prettier": "^8.8.0",
    "eslint-import-resolver-alias": "^1.1.2",
    "eslint-nibble": "^8.1.0",
    "eslint-plugin-ban": "^1.6.0",
    "eslint-plugin-import": "^2.25.3",
    "eslint-plugin-jest": "^26.6.0",
    "eslint-plugin-jest-dom": "^4.0.2",
    "eslint-plugin-jsx-a11y": "^6.5.1",
    "eslint-plugin-lodash": "^7.3.0",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.27.1",
    "eslint-plugin-react-hooks": "^4.3.0",
    "eslint-plugin-storybook": "^0.5.7",
    "eslint-plugin-typescript-sort-keys": "^2.1.0",
    "fishery": "^2.2.2",
    "husky": "^8.0.0",
    "identity-obj-proxy": "^3.0.0",
    "jest": "^28.1.2",
    "jest-canvas-mock": "^2.5.2",
    "jest-environment-jsdom": "^28.1.2",
    "jest-expect-message": "^1.1.3",
    "jest-when": "^3.6.0",
    "parcel": "^2.8.3",
    "parcel-reporter-static-files-copy": "^1.3.4",
    "prettier": "^2.8.8",
    "pretty-quick": "^3.1.3",
    "react-addons-test-utils": "^15.6.2",
    "react-docgen-typescript-plugin": "^1.0.0",
    "sass": "^1.56.1",
    "sass-loader": "10.1.1",
    "sinon": "^15.0.0",
    "slack-block-builder": "^2.7.2",
    "sonar-scanner": "^3.1.0",
    "style-loader": "2.0.0",
    "stylelint": "^7.13.0",
    "tsx": "3.13.0",
    "typescript": "^4.9.4",
    "v8-to-istanbul": "^9.2.0",
    "webpack": "5"
  },
  "dependencies": {
    "@beefree.io/sdk": "^7.0.0",
    "@capacitor/core": "^5.7.0",
    "@datadog/browser-rum": "^4.50.0",
    "@dutchie/capacitor-hardware": "^0.0.8",
    "@dutchie/cart-calculator": "24.603.2201",
    "@emotion/react": "^11.7.1",
    "@hookform/resolvers": "^2.9.8",
    "@module-federation/bridge-react": "0.8.12",
    "@module-federation/runtime": "0.8.12",
    "@mui/material": "^5.8.7",
    "@mui/styled-engine-sc": "npm:@mui/styled-engine-sc@latest",
    "@mui/system": "^5.15.9",
    "@mui/x-data-grid-pro": "^5.17.9",
    "@mui/x-date-pickers-pro": "^6.19.4",
    "@playwright/test": "^1.35.1",
    "@radix-ui/react-select": "^1.1.2",
    "@segment/analytics-next": "^1.33.5",
    "@tanstack/react-query": "^4.16.1",
    "@tanstack/react-query-devtools": "^4.16.1",
    "@ungap/structured-clone": "^1.2.0",
    "axios": "^0.27.2",
    "bardcode": "^2.2.0",
    "browser-image-compression": "^2.0.0",
    "chart.js": "^2.9.4",
    "classnames": "^2.3.2",
    "convert-units": "2.3.4",
    "cookie-parser": "^1.4.6",
    "copy-text-to-clipboard": "^3.0.1",
    "date-fns": "^2.29.3",
    "date-fns-tz": "^2.0.0",
    "dotenv": "^16.0.1",
    "email-validator": "^2.0.4",
    "events": "^3.3.0",
    "express": "^4.18.2",
    "flatpickr": "^4.6.9",
    "flux": "^3.1.3",
    "graphql": "^16.6.0",
    "graphql-request": "5.1.0",
    "http-proxy-middleware": "^2.0.1",
    "i18next": "^14.0.1",
    "i18next-browser-languagedetector": "^2.2.4",
    "i18next-xhr-backend": "^2.0.1",
    "immer": "10.0.4",
    "jquery": "^3.5.1",
    "json-2-csv": "^4.0.0",
    "keymirror": "^0.1.1",
    "konva": "^8.0.2",
    "launchdarkly-react-client-sdk": "^3.0.8",
    "lodash": "4.17.21",
    "logrocket": "^8.1.0",
    "moment": "2.29.4",
    "nanoid": "^3",
    "numeral": "^2.0.6",
    "posthtml-expressions": "1.10.0",
    "prop-types": "^15.7.2",
    "react": "^17",
    "react-chartjs-2": "^2.11.2",
    "react-color": "^2.17.0",
    "react-dnd": "^14.0.5",
    "react-dnd-html5-backend": "^14.1.0",
    "react-dom": "^17",
    "react-dropzone": "^14.2.1",
    "react-flatpickr": "^3.6.4",
    "react-hook-form": "^7.37.0",
    "react-hotkeys-hook": "^3.4.4",
    "react-i18next": "^9.0.10",
    "react-idle-timer": "^5.4.2",
    "react-json-view": "^1.19.1",
    "react-konva": "^17.0.2-4",
    "react-modal-promise": "^1.0.2",
    "react-player": "^1.12.0",
    "react-router-dom": "^6.22.3",
    "react-tabs": "^3.1.2",
    "react-tooltip": "^3.9.2",
    "react-transition-group": "^4.4.5",
    "react-window": "^1.8.7",
    "recoil": "0.7.7",
    "rsvp": "^4.0.1",
    "semver-compare": "^1.0.0",
    "sffjs": "^1.16.1",
    "styled-breakpoints": "^11.1.1",
    "styled-components": "^5.3.0",
    "ts-node": "^10.9.1",
    "universal-cookie": "^2.1.0",
    "uuid": "^3.1.0",
    "yup": "^0.32.11",
    "zustand": "^4.5.5",
    "zxcvbn": "^4.4.2"
  },
  "alias": {
    "src": "./src",
    "assets": "./assets"
  },
  "resolutions": {
    "@mui/styled-engine": "npm:@mui/styled-engine-sc@latest",
    "react-refresh": "0.11.0",
    "@types/react": "^17"
  },
  "@parcel/bundler-default": {
    "minBundleSize": 300000000000
  }
}
