import React from 'react';

import {
  HomeIcon,
  ProductsIcon,
  FinancialsIcon,
  CustomersIcon,
  RegistersIcon,
  ReportsIcon,
  MarketingIcon,
  DeliveryIcon,
  CultivationIcon,
  ManufacturingIcon,
  TraceabilityIcon,
  SettingsIcon,
  SuperuserIcon,
  SuppliersIcon,
} from './navigation-icons/index';

type NavigationIconProps = {
  type: string;
};

export function NavigationIcon({ type }: NavigationIconProps): JSX.Element {
  function getIcon() {
    switch (type) {
      case 'Home':
        return HomeIcon;
      case 'Products':
        return ProductsIcon;
      case 'Financials':
        return FinancialsIcon;
      case 'Customers':
        return CustomersIcon;
      case 'Registers':
        return RegistersIcon;
      case 'Reports':
        return ReportsIcon;
      case 'Marketing':
        return MarketingIcon;
      case 'Delivery':
        return DeliveryIcon;
      case 'Suppliers':
        return SuppliersIcon;
      case 'Cultivation':
        return CultivationIcon;
      case 'Manufacturing':
        return ManufacturingIcon;
      case 'Traceability':
        return TraceabilityIcon;
      case 'Settings':
        return SettingsIcon;
      case 'Superuser':
        return SuperuserIcon;
      default:
        return HomeIcon;
    }
  }

  const IconToUse = getIcon();

  return <IconToUse />;
}
