import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { assemblyKeys } from '../graphql/assemblies/query-key-factory';

import type { UpsertAssemblyRequest } from './types';
import type { AxiosError } from 'axios';

export const endpoint = 'api/manufacturing/assembly/upsert';

export type UpsertAssemblyResponse = {
  assemblyId: string;
};

export const useUpsertAssembly = () => {
  const queryClient = useQueryClient();
  return useMutation<UpsertAssemblyResponse, AxiosError<{ Data: string; Message: string }>, UpsertAssemblyRequest>({
    mutationKey: ['upsert-assembly'],
    mutationFn: (payload) => postData({ payload, endpoint }),
    onSuccess: (_data: UpsertAssemblyResponse) => {
      void queryClient.resetQueries({ queryKey: assemblyKeys.all });
    },
  });
};
