import { getPackageCustomerTypesFromApi } from 'src/app/utils/customer-types-helpers';
import { formatLabResult } from 'src/app/utils/formatters';

import type { InventoryPackageAttributesV2Fragment, InventoryPackageAttributesFragment as PT } from 'src/gql/graphql';

export type InventoryPackage = ReturnType<typeof extendInventoryPackage>;

export const extendInventoryMap = {
  AllergenList: (pkg: PT) => pkg.product?.allergenList,
  AllocatedQuantity: (pkg: PT) => pkg.allocatedInventorySum ?? 0,
  AlternateName: (pkg: PT) => pkg.product?.alternateDesc,
  ApprovalDateUTC: (pkg: PT) => pkg.product?.approvalDateUTC ?? null,
  Available: (pkg: PT) => {
    const unitTypeId = pkg.unit?.unitTypeId || 1;
    const { quantity } = pkg;
    const abbreviation = pkg.unit?.abbreviation || '';

    const available = unitTypeId === 1 ? String(quantity) : `${String(quantity)} ${abbreviation}`;
    return available;
  },
  BatchId: (pkg: PT) => pkg.batch?.id,
  BioTrackQAStatus: (pkg: PT) => pkg.bioTrackQAStatus,
  BrandName: (pkg: PT) => pkg.product?.brand?.brandName,
  CannabisInventory: (pkg: PT) => pkg.product?.cannabisInventory,
  Category: (pkg: PT) => pkg.product?.productTypeNavigation?.productType,
  CBD: (pkg: PT) => formatLabResult({ pkg, valueKey: 'cbdValue', unitKey: 'cbdUnit' }),
  CBDa: (pkg: PT) => formatLabResult({ pkg, valueKey: 'cbdaValue', unitKey: 'cbdaUnit' }),
  CBN: (pkg: PT) => formatLabResult({ pkg, valueKey: 'cbnValue', unitKey: 'cbnUnit' }),
  Cost: (pkg: PT) => pkg.cost,
  CultivationTax: (pkg: PT) => pkg.cultivationTax,
  CustomerTypes: (pkg: PT) => getPackageCustomerTypesFromApi(pkg),
  EffectivePotencyMg: (pkg: PT) => pkg.effectivePotencyMg,
  ExciseTax: (pkg: PT) => pkg.exciseTax,
  ExpirationDate: (pkg: PT) => pkg.expirationDate,
  ExternalCategory: (pkg: PT) => pkg.product?.externalCategory,
  FlowerEquivalent: (pkg: PT) => pkg.flowerEquivalent ?? pkg.product?.flowerEquivalent,
  GrossWeight: (pkg: PT) =>
    pkg.packageWeight && pkg.packageWeight !== 0 ? pkg.packageWeight : pkg.product?.grossWeight,
  HarvestDate: (pkg: PT) => pkg.batch?.harvestCompletedOn,
  ILTaxCategory: (pkg: PT) => pkg.iltaxCategory,
  IngredientList: (pkg: PT) => pkg.product?.ingredientList,
  InventoryDate: (pkg: PT) => pkg.receivedDate,
  InventoryStatusId: (pkg: PT) => pkg.inventoryStatusId,
  IsCoupon: (pkg: PT) => pkg.product?.couponItem,
  IsFinishedInIntegration: (pkg: PT) => pkg.isFinishedInIntegration,
  IsNutrient: (pkg: PT) => pkg.product?.isNutrient,
  IsOnHold: (pkg: PT) => pkg.isOnHold,
  IsOnlineProduct: (pkg: PT) => {
    if (pkg.locationProductMaster?.onlineAvailable != null) {
      return pkg.locationProductMaster.onlineAvailable === 1 ? 'Yes' : 'No';
    }
    return pkg.product?.onlineProduct;
  },
  IsOnSale: (pkg: PT) => pkg.product?.isOnSale,
  IsPosProduct: (pkg: PT) => {
    if (pkg.locationProductMaster?.posAvailable != null) {
      return pkg.locationProductMaster.posAvailable === 1 ? 'Yes' : 'No';
    }
    return pkg.product?.posproducts;
  },
  IsRetired: (pkg: PT) => pkg.product?.isRetired,
  ItemTaxable: (pkg: PT) => pkg.product?.itemTaxable ?? 'No',
  LastAuditedDate: (pkg: PT) => pkg.product?.lastAuditedDateUtc,
  Lot: (pkg: PT) => pkg.batch?.batchLotNumber,
  LowInventoryThreshold: (pkg: PT) =>
    pkg.locationProductMaster?.lowInventoryThreshold ?? pkg.product?.lowInventoryThreshold,
  ManufacturingDate: (pkg: PT) => pkg.manufacturingDate,
  MasterCategory: (pkg: PT) => pkg.product?.productTypeNavigation?.masterCategory,
  MedicalOnly: (pkg: PT) => pkg.medicalOnly,
  METRCLabTestingStatus: (pkg: PT) => pkg.metrclabTestingStatus,
  NetWeight: (pkg: PT) => pkg.netWeight,
  NetWeightUnit: (pkg: PT) => pkg.netWeightUnit,
  OnlineDescription: (pkg: PT) => pkg.product?.onlineDescription,
  OnlineTitle: (pkg: PT) => pkg.product?.onlineTitle,
  PackageId: (pkg: PT) => pkg.id,
  PackageMedPrice: (pkg: PT) => pkg.unitPrice,
  PackageNDC: (pkg: PT) => pkg.packageNdc || '',
  PackageRecPrice: (pkg: PT) => pkg.recUnitPrice,
  PackageStatus: () => 'Receipt', // yes this should be static. see GetInventoryV2
  PackagingDate: (pkg: PT) => pkg.packagedDate,
  PotencyIndicator: (pkg: PT) => pkg.potencyIndicator,
  Prescription: (pkg: PT) => pkg.product?.prescription,
  Price: (pkg: PT) => {
    if (pkg.loc?.whichUnitPrice === 'Inventory') {
      return pkg.unitPrice ?? pkg.locationProductMaster?.price ?? pkg.product?.whseProductsUnitPrice ?? 0;
    }
    return pkg.locationProductMaster?.price ?? pkg.product?.whseProductsUnitPrice ?? pkg.unitPrice ?? 0;
  },
  PriceCatalog: (pkg: PT) => pkg.product?.whseProductsUnitPrice ?? null,
  PriceLocation: (pkg: PT) => pkg.locationProductMaster?.price ?? null,
  ProducerId: (pkg: PT) => pkg.producerId,
  ProducerName: (pkg: PT) => pkg.producer?.producerName,
  Product: (pkg: PT) => pkg.product?.whseProductsDescription,
  ProductId: (pkg: PT) => pkg.product?.id,
  ProductImageFileName: (pkg: PT) => pkg.product?.productImageFileNameProcessed,
  ProductInstructions: (pkg: PT) => pkg.product?.instructions,
  ProductName: (pkg: PT) => pkg.product?.whseProductsDescription,
  ProductType: (pkg: PT) => pkg.product?.unitType?.unitTypeAbbreviation,
  ProductWeight: (pkg: PT) => pkg.productWeight ?? null,
  QuantityAvailable: (pkg: PT) => pkg.quantity ?? 0,
  RecFlowerEquivalent: (pkg: PT) => pkg.recFlowerEquivalent ?? pkg.product?.recFlowerEquivalent,
  RecPrice: (pkg: PT) => {
    if (pkg.loc?.whichUnitPrice === 'Inventory') {
      return pkg.recUnitPrice ?? pkg.locationProductMaster?.recPrice ?? pkg.product?.whseProductsRecUnitPrice ?? 0;
    }
    return pkg.locationProductMaster?.recPrice ?? pkg.product?.whseProductsRecUnitPrice ?? pkg.recUnitPrice ?? 0;
  },
  RecPriceCatalog: (pkg: PT) => pkg.product?.whseProductsRecUnitPrice ?? null,
  RecPriceLocation: (pkg: PT) => pkg.locationProductMaster?.recPrice ?? null,
  Room: (pkg: PT) => pkg.room?.roomNo,
  RoomId: (pkg: PT) => pkg.room?.id,
  SerialNumber: (pkg: PT) => pkg.serialNumber,
  SizeLabel: (pkg: PT) => pkg.product?.sizeListItem?.description,
  SKU: (pkg: PT) => pkg.product?.whseProductsProductNo,
  SourceBatch: (pkg: PT) => pkg.inventorySourceBatches?.[0]?.sourceBatch || '',
  SourcePackageId: (pkg: PT) => pkg.sourceSerialNumber || '',
  Status: (pkg: PT) => pkg.inventoryStatus?.invStatusDesc,
  StrainName: (pkg: PT) => pkg.batch?.batchStrainNavigation?.strainName || pkg.product?.strain?.strainName,
  TableId: (pkg: PT) => pkg.tableId,
  TableName: (pkg: PT) => pkg.table?.trayNumber,
  TagNames: (pkg: PT) => pkg.packageToTags?.map((tag) => tag?.tag?.tagName).join(', ') ?? [],
  Tags: (pkg: PT) => pkg.packageToTags?.map((tag) => tag?.tag?.tagId),
  THC: (pkg: PT) => formatLabResult({ pkg, valueKey: 'thcValue', unitKey: 'thcUnit' }),
  THC9: (pkg: PT) => formatLabResult({ pkg, valueKey: 'thc9Value', unitKey: 'thc9Unit' }),
  THCA: (pkg: PT) => formatLabResult({ pkg, valueKey: 'thcaValue', unitKey: 'thcaUnit' }),
  Type: (pkg: PT) => pkg.product?.unitType?.unitTypeAbbreviation,
  Unit: (pkg: PT) => pkg.unit?.abbreviation,
  UnitId: (pkg: PT) => pkg.unit?.unitId,
  UseByDate: (pkg: PT) => pkg.useByDate,
  Vendor: (pkg: PT) => pkg.vendor?.vendorName,
  VendorId: (pkg: PT) => pkg.vendor?.id,
  ProductGrams: (pkg: PT) => pkg.product?.productGrams,
} as const;

type ExtendedInventoryMap = typeof extendInventoryMap;

type ExtendedPackage = {
  [Key in keyof ExtendedInventoryMap]: ExtendedInventoryMap[Key] extends (arg: any) => infer T ? T : never;
};

export function extendInventoryPackage(inventoryPackage: PT) {
  const extendedValues = Object.entries(extendInventoryMap).reduce((acc, [key, fn]) => {
    acc[key] = fn(inventoryPackage);
    return acc;
  }, {});
  return {
    ...(inventoryPackage as InventoryPackageAttributesV2Fragment),
    ...(extendedValues as ExtendedPackage),
  };
}
