import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { LinkButton } from 'src/app/components/lib/button';
import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { customerGroupQueryKey, useGetDiscountGroupLists } from 'src/app/queries/customers/get-discount-group-lists';

import { getMultiSelectedOptions, parseSelectedIds } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type CustomerGroupDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export type CustomerGroup = {
  Active: boolean;
  Expired: boolean;
  GroupName: string;
  Id: number;
};

export function CustomerGroupDropdown(props: CustomerGroupDropdownProps) {
  const { onChange, values, disabled = false, automationId } = props;
  const queryClient = useQueryClient();
  const { data: activeCustomerGroups = [], isLoading } = useGetDiscountGroupLists();

  const [label, setLabel] = useState('Select a customer group');
  const [customerGroupDropdownSelection, setCustomerGroupDropdownSelection] = useState<DropdownMenuOption[]>([]);

  const getMultiSelectedOptionsForCustomerGroups = (
    values: string,
    allCustomerGroupOptions: CustomerGroup[]
  ): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allCustomerGroupOptions,
      parseSelectedIds,
      (option) => option.Id,
      (option) => ({
        label: option.GroupName,
        id: option.Id.toString(),
      })
    );

  useEffect(() => {
    if (customerGroupDropdownSelection.length !== 0 && customerGroupDropdownSelection.length < 4) {
      const customerGroupLabelList = customerGroupDropdownSelection.map((item) => item.label).join(', ');
      setLabel(customerGroupLabelList);
    } else {
      setLabel('Select a customer group');
    }
  }, [customerGroupDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    if (activeCustomerGroups.length) {
      setCustomerGroupDropdownSelection(getMultiSelectedOptionsForCustomerGroups(values, activeCustomerGroups));
    }
  }, [activeCustomerGroups]);

  async function loadCustomerGroups() {
    void queryClient.invalidateQueries({ queryKey: customerGroupQueryKey });
  }

  async function handleRefresh() {
    await loadCustomerGroups();
  }

  const handleCustomerGroupFilterChange = (values: DropdownMenuOption[]) => {
    setCustomerGroupDropdownSelection(values);

    const customerGroupDropdownSelection = values.map((option) => option.id);
    onChange(customerGroupDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={customerGroupDropdownSelection}
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId='customer-group-dropdown_multi-filter-select_refresh-button'
          buttonSize='small'
          key='category-refresh'
          label='Refresh'
          loading={isLoading}
          onClick={handleRefresh}
        />
      }
      label={label}
      options={activeCustomerGroups.map((customerGroup) => ({
        label: String(customerGroup.GroupName),
        id: String(customerGroup.Id),
      }))}
      onChange={handleCustomerGroupFilterChange}
    />
  );
}
