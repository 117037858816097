import { useQuery } from '@tanstack/react-query';

import { endPoints as posEndpoints } from 'src/app_deprecated/constants/PosConstants';

import { fetchData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

export const customerGroupQueryKey: QueryKey = ['getDiscountGroupLists'];

type CustomerGroupProps = {
  Active: boolean;
  Expired: boolean;
  GroupName: string;
  Id: number;
  Visible: boolean;
};

export function useGetDiscountGroupLists() {
  return useQuery({
    queryKey: [...customerGroupQueryKey],
    queryFn: async () => {
      const data = await fetchData<CustomerGroupProps[]>(posEndpoints.GET_DISCOUNT_GROUP_LIST);
      return data.filter((group) => group.Active && !group.Expired);
    },
  });
}
