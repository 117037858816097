import React, { useState, useEffect } from 'react';

import { Popover, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { DateRangeCalendar, LocalizationProvider, MultiInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  endOfYesterday,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import styled from 'styled-components';

import { CaretDown } from 'src/app/components/icons';
import { Button } from 'src/app/components/lib/button';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useDarkMode } from 'src/app/state/dark-mode';

import { Divider } from '../../divider';

import type { DateRange } from '@mui/x-date-pickers-pro';
import type { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';

export const MILLISECONDS_IN_A_WEEK = 7 * 24 * 60 * 60 * 1000;

export enum DateRangeOption {
  Custom = 'Custom',
  Last7Days = 'Last 7 Days',
  LastMonth = 'Last Month',
  LastQuarter = 'Last Quarter',
  LastYear = 'Last Year',
  Yesterday = 'Yesterday',
  YTD = 'Year To Date',
}

export const NameOfMonth = {
  1: 'JAN',
  2: 'FEB',
  3: 'MAR',
  4: 'APR',
  5: 'MAY',
  6: 'JUN',
  7: 'JUL',
  8: 'AUG',
  9: 'SEP',
  10: 'OCT',
  11: 'NOV',
  12: 'DEC',
};

const FormatString = 'MM/dd/yyyy';

export type DatePickerRangeProps = {
  className?: any;
  disabled?: boolean;
  isMobile?: boolean;
  onDateChange: (dateChange: NonEmptyDateRange<Date>) => void;
  shouldDisableDate: (day: unknown, _position: 'end' | 'start') => boolean;
  value?: NonEmptyDateRange<Date>;
};

// This component is used with the snowflake reporting and has date ranges that correspond with the current day lag of data being synced.
export function DatePickerRange({
  onDateChange,
  value = GetRangeFromOption(DateRangeOption.Yesterday),
  shouldDisableDate,
  disabled = false,
  isMobile = false,
  className,
}: DatePickerRangeProps) {
  const isReportsEnabled = useLDFlag(LD.CUSTOMERS_SEGMENTS_REPORTS_ROLLOUT, false);
  const darkMode = useDarkMode();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rangeTypeChosen, setRangeTypeChosen] = useState(DateRangeOption.Yesterday);
  const [buttonLabel, setButtonLabel] = useState(DateRangeOption.Yesterday.toString());
  const [dateRange, setDateRange] = useState<DateRange<Date>>(value ?? GetRangeFromOption(DateRangeOption.Yesterday));
  const [inputDateRange, setInputDateRange] = useState<DateRange<Date>>(
    value ?? GetRangeFromOption(DateRangeOption.Yesterday)
  );

  const open = Boolean(anchorEl);
  const popoverId = 'date-range';

  useEffect(() => {
    const option = GetOptionFromRange(dateRange);
    setRangeTypeChosen(option);
    if (option === DateRangeOption.Custom) {
      const startDate = dateRange[0] ? format(dateRange[0]!, FormatString) : 'pick start date';
      const endDate = dateRange[1] ? format(dateRange[1]!, FormatString) : 'pick end date';
      const customLabel = `${startDate} - ${endDate}`;
      setButtonLabel(customLabel);
    } else {
      setButtonLabel(option);
    }
  }, [dateRange]);

  useEffect(() => {
    if (!open) {
      if (!dateRange[1] || !dateRange[0]) {
        const yesterdayDateRange = GetRangeFromOption(DateRangeOption.Yesterday);
        setDateRange(yesterdayDateRange);
        setInputDateRange(yesterdayDateRange);
        setButtonLabel(DateRangeOption.Yesterday);
        onDateChange(yesterdayDateRange);
      } else {
        setInputDateRange(dateRange);
        onDateChange(dateRange as NonEmptyDateRange<Date>);
      }
    }
  }, [open, dateRange, onDateChange]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRangeTypeClick = (option: DateRangeOption) => {
    setRangeTypeChosen(option);
    const range = GetRangeFromOption(option);
    setDateRange(range);
    setInputDateRange(range);
  };

  const onDateRangeChange = (range: DateRange<Date>) => {
    const startDate = range[0] ? startOfDay(range[0]) : null;
    const endDate = range[1] ? endOfDay(range[1]) : null;

    const cleanedRange: DateRange<Date> = [startDate, endDate];
    setDateRange(cleanedRange);
    setInputDateRange(cleanedRange);
  };

  const onInputDateRangeChange = (range: DateRange<Date>) => {
    // clean range
    const cleanedRange: DateRange<Date> = [startOfDay(range[0]!), endOfDay(range[1]!)];
    setInputDateRange(cleanedRange);
    if (isValid(cleanedRange[0]) && isValid(cleanedRange[1]) && isAfter(cleanedRange[1]!, cleanedRange[0]!)) {
      setDateRange(cleanedRange);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledButton
        aria-controls='actions-menu'
        aria-describedby={popoverId}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        automationId='user-actions-menu-button'
        className={className}
        disabled={disabled}
        endIcon={<CaretDown fill={darkMode ? 'var(--color-brand-primary-white)' : undefined} />}
        id='actions-menu-button'
        label={buttonLabel}
        variant='white'
        onClick={handleClick}
      />
      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={popoverId}
        open={open}
        style={{ width: `${isMobile ? '400px' : '100%'}` }}
        onClose={handleClose}
      >
        <Stack direction={isMobile ? 'column' : 'row'} divider={<Divider variant='vertical-flex' />}>
          <StyledList>
            <ListItem disablePadding key={DateRangeOption.Yesterday}>
              <StyledListItemButton
                selected={rangeTypeChosen === DateRangeOption.Yesterday}
                onClick={() => handleRangeTypeClick(DateRangeOption.Yesterday)}
              >
                <ListItemText primary={DateRangeOption.Yesterday} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding key={DateRangeOption.Last7Days}>
              <StyledListItemButton
                selected={rangeTypeChosen === DateRangeOption.Last7Days}
                onClick={() => handleRangeTypeClick(DateRangeOption.Last7Days)}
              >
                <ListItemText primary={DateRangeOption.Last7Days} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding key={DateRangeOption.LastMonth}>
              <StyledListItemButton
                selected={rangeTypeChosen === DateRangeOption.LastMonth}
                onClick={() => handleRangeTypeClick(DateRangeOption.LastMonth)}
              >
                <ListItemText primary={DateRangeOption.LastMonth} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding key={DateRangeOption.LastQuarter}>
              <StyledListItemButton
                selected={rangeTypeChosen === DateRangeOption.LastQuarter}
                onClick={() => handleRangeTypeClick(DateRangeOption.LastQuarter)}
              >
                <ListItemText primary={DateRangeOption.LastQuarter} />
              </StyledListItemButton>
            </ListItem>
            {isReportsEnabled && (
              <ListItem disablePadding key={DateRangeOption.YTD}>
                <StyledListItemButton
                  selected={rangeTypeChosen === DateRangeOption.YTD}
                  onClick={() => handleRangeTypeClick(DateRangeOption.YTD)}
                >
                  <ListItemText primary={DateRangeOption.YTD} />
                </StyledListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding key={DateRangeOption.LastYear}>
              <StyledListItemButton
                selected={rangeTypeChosen === DateRangeOption.LastYear}
                onClick={() => handleRangeTypeClick(DateRangeOption.LastYear)}
              >
                <ListItemText primary={DateRangeOption.LastYear} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding key={DateRangeOption.Custom}>
              <StyledListItemButton disabled selected={rangeTypeChosen === DateRangeOption.Custom}>
                <ListItemText primary={DateRangeOption.Custom} />
              </StyledListItemButton>
            </ListItem>
          </StyledList>
          <StyledStack direction='column'>
            <StyledMultiInputDateRangeField
              maxDate={endOfYesterday()}
              slots={{ separator: React.Fragment }}
              value={inputDateRange}
              onChange={(newValue) => onInputDateRangeChange(newValue as DateRange<Date>)}
            />
            <StyledDateRangeCalendar
              calendars={isMobile ? 1 : 2}
              shouldDisableDate={shouldDisableDate}
              value={dateRange}
              onChange={(newValue) => onDateRangeChange(newValue as DateRange<Date>)}
            />
          </StyledStack>
        </Stack>
      </StyledPopover>
    </LocalizationProvider>
  );
}

export function GetRangeFromOption(option: DateRangeOption): [Date, Date] {
  const nowDate = new Date();
  switch (option) {
    case DateRangeOption.Last7Days:
      return [subDays(startOfYesterday(), 6), endOfYesterday()];
    case DateRangeOption.LastMonth: {
      const lastMonth = subMonths(nowDate, 1);
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)];
    }
    case DateRangeOption.LastQuarter: {
      const lastQuarter = subQuarters(nowDate, 1);
      return [startOfQuarter(lastQuarter), endOfQuarter(lastQuarter)];
    }
    case DateRangeOption.YTD: {
      return [startOfYear(nowDate), endOfYesterday()];
    }
    case DateRangeOption.LastYear: {
      const lastYear = subYears(nowDate, 1);
      return [startOfYear(lastYear), endOfYear(lastYear)];
    }
    default:
      // Yesterday
      return [startOfYesterday(), endOfYesterday()];
  }
}

export function GetOptionFromRange(range: DateRange<Date>): DateRangeOption {
  if (range[0] && range[1]) {
    if (isEqual(range[0], startOfYesterday()) && isEqual(range[1], endOfYesterday())) {
      return DateRangeOption.Yesterday;
    }
    if (isEqual(range[0], subDays(startOfYesterday(), 6)) && isEqual(range[1], endOfYesterday())) {
      return DateRangeOption.Last7Days;
    }

    const nowDate = new Date();
    const lastMonth = subMonths(nowDate, 1);
    if (isEqual(range[0], startOfMonth(lastMonth)) && isEqual(range[1], endOfMonth(lastMonth))) {
      return DateRangeOption.LastMonth;
    }

    const lastYear = subYears(nowDate, 1);
    if (isEqual(range[0], startOfYear(lastYear)) && isEqual(range[1], endOfYear(lastYear))) {
      return DateRangeOption.LastYear;
    }

    if (isEqual(range[0], startOfYear(nowDate)) && isEqual(range[1], endOfYesterday())) {
      return DateRangeOption.YTD;
    }
    const lastQuarter = subQuarters(nowDate, 1);
    if (isEqual(range[0], startOfQuarter(lastQuarter)) && isEqual(range[1], endOfQuarter(lastQuarter))) {
      return DateRangeOption.LastQuarter;
    }
  }

  return DateRangeOption.Custom;
}

const StyledList = styled(List)`
  min-width: 175px;
  * {
    font: var(--font-regular-14pt-normal);
  }
  .Mui-selected {
    background-color: var(--color-gray-10);
  }
  .Mui-selected * {
    font: var(--font-regular-14pt-semibold);
  }

  .Mui-selected.Mui-disabled * {
    color: var(--color-grayscale-black);
  }
`;
const StyledListItemButton = styled(ListItemButton)`
  padding-left: 32px;
  padding-right: 32px;
`;

const StyledDateRangeCalendar = styled(DateRangeCalendar)`
  .MuiDateRangeCalendar-monthContainer {
    border-right: none !important;
  }
  .MuiPickersArrowSwitcher-root,
  .MuiPickersArrowSwitcher-button {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .Mui-selected,
  .MuiDateRangePickerDay-dayInsideRangeInterval {
    font: var(--font-regular-14pt-semibold);
  }
  margin-top: 12px;
`;

const StyledMultiInputDateRangeField = styled(MultiInputDateRangeField)`
  margin: 0px 24px;
`;

const StyledStack = styled(Stack)`
  margin: 16px 0px;
`;

const StyledPopover = styled(Popover)`
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  outline: 1px solid var(--color-gray-40);
`;

// Snowflake backed reports common handling helpers
export const shouldDisableDate = (day: unknown) => {
  const nowDate = new Date();
  const lastYear = subYears(nowDate, 1);
  return isAfter(day as Date, endOfYesterday()) || isBefore(day as Date, startOfYear(lastYear));
};
