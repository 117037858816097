import React, { useMemo, useRef, useCallback } from 'react';

import { Doughnut } from 'react-chartjs-2';

import { ChartContainer } from './chart-styles.styles';

export type DoughnutChartDataset = {
  backgroundColor?: string[];
  data: number[];
  hoverOffset?: number;
  opacity?: number;
};

export type DoughnutChartProps = {
  centerSubText?: string;
  centerText?: string;
  className?: string;
  cutoutPercentage?: number;
  datasets: DoughnutChartDataset[];
  height?: number;
  labels: string[];
  maintainAspectRatio?: boolean;
  onHover?: (event: any, elements: any[]) => void;
  showLegend?: boolean;
  showTooltips?: boolean;
  width?: number;
};

const wrapText = (ctx: CanvasRenderingContext2D, text: string, maxWidth: number): string[] => {
  const lines: string[] = [];
  if (text) {
    const words = text.split(' ');
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const { width } = ctx.measureText(`${currentLine} ${word}`);
      if (width < maxWidth) {
        currentLine += ` ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
  }
  return lines;
};

export function DoughnutChart({
  labels,
  datasets,
  height = 300,
  width = 300,
  className,
  cutoutPercentage = 80,
  maintainAspectRatio = false,
  showLegend = false,
  showTooltips = false,
  centerText,
  centerSubText,
  onHover,
}: DoughnutChartProps) {
  const chartRef = useRef<any>(null);
  const hoverTimeout = useRef<any>(null);

  const handleHover = useCallback(
    (event: any, elements: any[]) => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
      hoverTimeout.current = setTimeout(() => {
        onHover?.(event, elements);
      }, 4);
    },
    [onHover]
  );

  const data = useMemo(
    () => ({
      labels,
      datasets: datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor ?? ['#10adcf'],
        opacity: dataset.opacity ?? 0.5,
        hoverOffset: dataset.hoverOffset ?? 4,
      })),
    }),
    [labels, datasets]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio,
      cutoutPercentage,
      legend: {
        display: showLegend,
      },
      tooltips: {
        enabled: showTooltips,
      },
      onHover: handleHover,
      animation: false,
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    }),
    [maintainAspectRatio, cutoutPercentage, showLegend, showTooltips, handleHover]
  );

  // Plugin for center text
  const centerTextPlugin = useMemo(
    () => [
      {
        id: 'doughnut-center-text',
        beforeDraw(chart: any) {
          const { ctx } = chart;
          const { width, height } = chart;
          const centerX = width / 2;
          const centerY = height / 2;

          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          // Main text
          if (centerText) {
            ctx.font = 'normal 500 29px Matter';
            ctx.fillStyle = '#121516';
            ctx.fillText(centerText, centerX, centerY);
          }

          // Subtext
          if (centerSubText) {
            ctx.font = 'normal 100 15px Matter';
            ctx.fillStyle = '#A0A7AB';
            const subtextY = centerY + 25;

            const words = wrapText(ctx, centerSubText, 200);
            if (words.length > 1) {
              words.forEach((line, index) => {
                ctx.fillText(line, centerX, subtextY + 25 * index);
              });
            } else {
              ctx.fillText(centerSubText, centerX, subtextY);
            }
          }
          ctx.restore();
        },
      },
    ],
    [centerText, centerSubText]
  );

  return (
    <ChartContainer className={className}>
      <Doughnut
        data={data}
        height={height}
        key={`doughnut-${centerText ?? ''}-${centerSubText ?? ''}`}
        options={options}
        plugins={centerTextPlugin}
        ref={chartRef}
        width={width}
      />
    </ChartContainer>
  );
}

export default DoughnutChart;
