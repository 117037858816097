import React, { useState } from 'react';

import { LinkButton } from 'src/app/components/lib/button';
import { Input, InputAdornment } from 'src/app/components/lib/input';

import { CreateNewBatchModal } from './create-new-batch';
import { SelectBatchModal } from './select-batch';

import type { InputProps } from 'src/app/components/lib/input';
import type { BatchesWithLabsFragment } from 'src/gql/graphql';

export type BatchInputProps = {
  allowAddBatch?: boolean;
  automationId?: string;
  disabled?: boolean;
  initialValue?: number | null;
  label: InputProps['label'];
  labelPlacement: InputProps['labelPlacement'];
  onChange?: (batchId: number) => void;
  placeholder?: string;
  required?: InputProps['required'];
  strainId?: string;
};

/**
 * This is the newest Batch Id Input component.
 * Use this component to select or create a new batch and autofill the batchId.
 */
export function BatchInput({
  allowAddBatch = true,
  automationId,
  disabled,
  label,
  labelPlacement,
  placeholder,
  onChange,
  required,
  strainId,
  initialValue,
}: BatchInputProps) {
  const [currentBatchId, setCurrentBatchId] = useState<number | undefined>(initialValue ?? undefined);
  const [showCreateBatchModal, setShowCreateBatchModal] = useState(false);
  const [showSelectBatchModal, setShowSelectBatchModal] = useState(false);

  const updateBatchId = (batchId: number) => {
    onChange?.(batchId);
    setCurrentBatchId(batchId);
  };

  const onCloseCreateNewBatch = (newBatch?: { BatchId: number; BatchNo: string }) => {
    setShowCreateBatchModal(false);
    if (newBatch) {
      updateBatchId(newBatch.BatchId);
    }
  };

  const onCloseSelectBatchModal = (selectedBatch?: BatchesWithLabsFragment) => {
    setShowSelectBatchModal(false);
    if (selectedBatch) {
      updateBatchId(selectedBatch.id);
    }
  };

  return (
    <>
      <Input
        automationId={automationId}
        disabled={disabled}
        endAdornment={
          allowAddBatch && (
            <InputAdornment position='end'>
              <LinkButton
                automationId='assemblies_create-new-batch'
                disabled={disabled}
                label='New batch'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCreateBatchModal(true);
                }}
              />
            </InputAdornment>
          )
        }
        label={label}
        labelPlacement={labelPlacement}
        placeholder={placeholder}
        required={required}
        value={currentBatchId}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowSelectBatchModal(true);
        }}
      />
      {showCreateBatchModal && <CreateNewBatchModal initialStrainId={strainId} onClose={onCloseCreateNewBatch} />}
      {showSelectBatchModal && <SelectBatchModal onClose={onCloseSelectBatchModal} />}
    </>
  );
}
