import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { getOperatorsFromOperatorGroup } from './details/segment-query-builder/operators';

import type { Attributes, CustomerSegmentSchemaMap } from './details/segment-query-builder/schema';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';

export type SegmentPayload = {
  segmentName: string;
};

export type QueryRulesAndSchemaResponse = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  segmentDetails?: RuleGroup;
};

type GetOperatorFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  rule: Rule;
};

export const getOperatorFromRule = ({ rule, customerSegmentSchema, enableBrands }: GetOperatorFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';
  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableBrands);
  return ruleOperators.find((operator) => operator.name === rule.Operator);
};

type getOperatorGroupFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  rule: Rule;
};

export const getOperatorGroupFromRule = ({
  rule,
  customerSegmentSchema,
  enableBrands,
}: getOperatorGroupFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';

  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableBrands);
  return ruleOperators;
};

// Swap this with the flag we are currently using, or return false if we don't have a flag
export const useNewSegmentEnabled = () => useLDFlag<boolean>(LD.CUSTOMERS_QUERY_BUILDER_PRODUCT_BRAND_ROLLOUT);
