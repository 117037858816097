export enum ServerPaginatedTables {
  AddPackageToAuditList = 'add-package-to-audit-list-paginated',
  AssembliesList = 'assemblies-list-paginated',
  AssociateQRCodes = 'associate-qr-codes',
  AuditDetailAdvancedWorkflowAllPackagesList = 'audit-detail-advanced-workflow-review-list-paginated',
  AuditDetailAdvancedWorkflowCountedList = 'audit-detail-advanced-workflow-counted-list-paginated',
  AuditDetailAdvancedWorkflowDiscrepanciesList = 'audit-detail-advanced-workflow-discrepancies-list-paginated',
  AuditDetailAdvancedWorkflowUncountedList = 'audit-detail-advanced-workflow-uncounted-list-paginated',
  AuditDetailSimpleWorkflowBlindCountedList = 'audit-detail-simple-workflow-blind-counted-list-paginated',
  AuditDetailSimpleWorkflowBlindUncountedList = 'audit-detail-simple-workflow-blind-uncounted-list-paginated',
  AuditDetailSimpleWorkflowCountedList = 'audit-detail-simple-workflow-counted-list-paginated',
  AuditDetailSimpleWorkflowUncountedList = 'audit-detail-simple-workflow-uncounted-list-paginated',
  AuditsList = 'audits-list-paginated',
  BatchesWithLabsList = 'batches-with-labs-list',
  BillOfMaterialsAddCategoryList = 'bill-of-materials-add-category-list-paginated',
  BillOfMaterialsAddProductList = 'bill-of-materials-add-product-list-paginated',
  BillOfMaterialsList = 'bill-of-materials-list-paginated',
  BrandsList = 'brands-list-paginated',
  DeliveryConfigureCars = 'delivery-configure-cars-paginated',
  DeliveryConfigureDrivers = 'delivery-configure-drivers-paginated',
  DeliveryConfigureZones = 'delivery-configure-zones-paginated',
  InventoryPackagesList = 'inventory-packages-list-paginated',
  PackageEventsAudit = 'package-events-audit-paginated',
  PlantsList = 'plants-list-paginated',
  ProductEventsAudit = 'product-events-audit-paginated',
  StrainsList = 'strains-list-paginated',
  UserEventsAudit = 'user-events-audit-paginated',
  VendorsList = 'vendors-list-paginated',
}
