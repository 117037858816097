import type { ChangeEvent } from 'react';
import React from 'react';

import { InputAdornment as MUIInputAdornment } from '@mui/material';
import styled, { css } from 'styled-components';

import Translate from 'src/app_deprecated/components/form/layout/Translate.react';

import { ErrorMessage } from 'src/app/components/lib/error-message';
import { FormControl } from 'src/app/components/lib/form-control';
import { Label } from 'src/app/components/lib/label';
import { Loader } from 'src/app/components/lib/loader';
import { getIDFromLabel } from 'src/app/components/lib/utils';

import { PasswordPolicy } from '../../password-policy';

import { LoaderContainer } from './base-input/base-input.styles';
import { FormInput } from './styled-inputs/form-input';

import type { BaseInputProps } from './base-input';
import type { SxProps, FormControlProps as MUIFormControlProps } from '@mui/material';

export const InputAdornment = MUIInputAdornment;

export type InputProps = BaseInputProps &
  MUIFormControlProps & {
    autoComplete?: string;
    automationId?: string;
    disabled?: boolean;
    endAdornment?: any;
    error?: boolean;
    errorMessage?: string;
    gridColumnGap?: string;
    gridColumns?: number;
    id?: string;
    label?: React.ReactElement | string;
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
    lineHeight?: string;
    loading?: boolean;
    maxlength?: number;
    multiline?: boolean;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onPasswordPolicyValidation?: (valid: boolean) => void;
    passwordPolicyLength?: number;
    placeholder?: string;
    required?: boolean;
    rows?: number;
    showCharacterCount?: boolean;
    startAdornment?: any;
    sx?: SxProps;
    tableInput?: boolean;
    tooltip?: React.ReactNode;
    useV2Tooltip?: boolean;
    validatePasswordPolicy?: boolean;
  };

export function Input(props: InputProps): JSX.Element {
  const {
    label,
    id,
    value,
    type = 'text',
    onChange = () => undefined,
    onBlur,
    multiline = false,
    labelPlacement = 'start',
    width,
    required = false,
    disabled = false,
    sx,
    placeholder,
    rows,
    endAdornment,
    startAdornment,
    helperText,
    InputProps,
    validator = null,
    className = '',
    tooltip,
    onClick,
    automationId,
    autoFocus = false,
    onKeyPress,
    loading,
    maxlength,
    showCharacterCount = false,
    inputProps,
    error = false,
    errorMessage,
    autoComplete,
    gridColumnGap,
    gridColumns,
    validatePasswordPolicy = false,
    passwordPolicyLength = 8,
    onPasswordPolicyValidation = () => undefined,
    tableInput,
    useV2Tooltip = false,
    lineHeight,
    ...other
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    (validator && !validator(event)) || onChange(event);
  };

  const idFromLabel = getIDFromLabel(label);

  return (
    <FormControl
      $labelPlacement={label ? labelPlacement : undefined}
      $width={width}
      className={`input-form-control ${className ?? ''}`}
      gridColumnGap={gridColumnGap}
      gridColumns={gridColumns}
      hiddenLabel
      lineHeight={lineHeight}
      multilineLabel={Boolean(multiline && label)}
      sx={sx}
    >
      {label && (
        <Label
          className='input-label'
          htmlFor={`input-input_${id ?? idFromLabel}`}
          id={`input-label_${id ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
          useV2Tooltip={useV2Tooltip}
        >
          <Translate>{label}</Translate>
        </Label>
      )}

      {loading ? (
        <LoaderContainer>
          <Loader variant='black' />
        </LoaderContainer>
      ) : (
        <FormInput
          {...other}
          $fullWidth={!label}
          $showCharacterCount={showCharacterCount}
          $tableInput={tableInput}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          automationId={automationId}
          className='input-input'
          disabled={disabled}
          endAdornment={endAdornment}
          error={!!errorMessage || error}
          helperText={helperText}
          id={`input-input_${id ?? idFromLabel}`}
          InputProps={InputProps}
          multiline={multiline}
          placeholder={placeholder}
          required={required}
          rows={rows}
          startAdornment={startAdornment}
          type={type}
          value={value}
          /* eslint-disable */
          inputProps={{ ...inputProps, maxLength: maxlength }}
          onBlur={onBlur}
          /* eslint-enable */
          variant='outlined'
          onChange={handleChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      )}
      {maxlength && showCharacterCount && (
        <CharacterCount $labelOnTop={labelPlacement === 'top'}>
          {value ? String(value).length : 0}/{maxlength}
        </CharacterCount>
      )}
      {validatePasswordPolicy && value && (
        <PasswordPolicy
          password={value.toString()}
          passwordPolicyLength={passwordPolicyLength}
          onPasswordPolicyValidation={onPasswordPolicyValidation}
        />
      )}
      {errorMessage && (
        <ErrorMessage $fullWidth={!label} error>
          {errorMessage}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

const CharacterCount = styled.span<{ $labelOnTop?: boolean }>`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 500;
  grid-column: 4;
  margin-top: calc(var(--sizes-80) * -1); /* -40px */
  padding-right: var(--sizes-50);
  text-align: right;
  ${({ $labelOnTop }) =>
    $labelOnTop &&
    css`
      position: absolute;
      right: 0;
      bottom: 12px;
    `}
`;
