import { useQuery } from '@tanstack/react-query';
import { differenceInCalendarDays, getDate, getMonth } from 'date-fns';

import { postData } from 'src/app/queries';

import { reportDetailKeys } from './query-key-factory';

import type { QueryKey } from '@tanstack/react-query';
import type { ReportsSummaryOverview } from 'src/app/pages/customers/customers/segments/details/reports/utils/types';

type SegmentPayload = {
  CustomerTypes?: any[];
  FromDate?: Date;
  isDummyDataEnabled?: boolean;
  Locations?: any[];
  segmentId?: number;
  ToDate?: Date;
};

export const GET_SEGMENT_REPORT = 'api/segment/overview';
export const segmentsReportQueryKey: QueryKey = ['segmentsReport'];

export function useGetSegmentReportQuery(payload: SegmentPayload) {
  return useQuery({
    queryKey: reportDetailKeys.one(Number(payload.segmentId)),
    queryFn: payload?.isDummyDataEnabled
      ? () => getResponseDataII(payload?.FromDate ?? new Date(), payload?.ToDate ?? new Date())
      : () => postData<SegmentPayload, ReportsSummaryOverview>({ endpoint: GET_SEGMENT_REPORT, payload }),
  });
}

const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const getResponseDataII = (FromDate: Date, ToDate: Date) => {
  const dailyTotals = {
    AllSales: [],
    SegmentSales: [],
  } as ReportsSummaryOverview;

  const iteratorStart = 1;
  const iteratorEnd = differenceInCalendarDays(ToDate, FromDate) + 1;
  let endingDay = getDate(ToDate);
  const monthFromDate = getMonth(FromDate) + 1;
  let monthToDate = getMonth(ToDate) + 1;

  const dayValue: number = monthToDate === 2 ? 29 : 31;

  if (iteratorEnd < dayValue) {
    for (let day = iteratorStart; day <= iteratorEnd; day++) {
      const dayValue = {
        Date: `${monthToDate}/${endingDay}/2025`,
        AOV: generateRandomNumber(20, 50),
        AvgItemsPerCart: generateRandomNumber(1, 10),
        TransactionCount: generateRandomNumber(50, 500),
        TotalSpend: generateRandomNumber(250, 1000),
      };
      const dayValueLastYear = {
        Date: `${monthToDate}/${endingDay}/2024`,
        AOV: generateRandomNumber(20, 50),
        AvgItemsPerCart: generateRandomNumber(5, 8),
        TransactionCount: generateRandomNumber(10, 300),
        TotalSpend: generateRandomNumber(100, 500),
      };
      const dayValuesAllCustomers = {
        Date: `${monthToDate}/${endingDay}/2025`,
        AOV: generateRandomNumber(10, 90),
        AvgItemsPerCart: generateRandomNumber(1, 5),
        TransactionCount: generateRandomNumber(100, 300),
        TotalSpend: generateRandomNumber(250, 750),
      };
      const dayValuesAllCustomersLastYear = {
        Date: `${monthToDate}/${endingDay}/2024`,
        AOV: generateRandomNumber(10, 90),
        AvgItemsPerCart: generateRandomNumber(3, 5),
        TransactionCount: generateRandomNumber(50, 100),
        TotalSpend: generateRandomNumber(150, 500),
      };
      endingDay -= 1;
      if (endingDay === 0) {
        monthToDate -= 1;
        endingDay = monthToDate === 2 ? 28 : 31;
      }
      dailyTotals.AllSales.push(dayValuesAllCustomersLastYear, dayValuesAllCustomers);
      dailyTotals.SegmentSales.push(dayValueLastYear, dayValue);
    }
  } else if (monthFromDate < monthToDate) {
    for (let month = monthFromDate; month <= monthToDate; month++) {
      for (let day = 1; day < dayValue; day++) {
        const dayValue = {
          Date: `${month}/${day}/2025`,
          AOV: generateRandomNumber(20, 50),
          AvgItemsPerCart: generateRandomNumber(3, 8),
          TransactionCount: generateRandomNumber(90, 150),
          TotalSpend: generateRandomNumber(150, 500),
        };
        const dayValueLastYear = {
          Date: `${month}/${day}/2024`,
          AOV: generateRandomNumber(20, 50),
          AvgItemsPerCart: generateRandomNumber(1, 9),
          TransactionCount: generateRandomNumber(100, 400),
          TotalSpend: generateRandomNumber(100, 500),
        };
        const dayValuesAllCustomers = {
          Date: `${month}/${day}/2025`,
          AOV: generateRandomNumber(10, 90),
          AvgItemsPerCart: generateRandomNumber(1, 7),
          TransactionCount: generateRandomNumber(100, 300),
          TotalSpend: generateRandomNumber(250, 500),
        };
        const dayValuesAllCustomersLastYear = {
          Date: `${month}/${day}/2024`,
          AOV: generateRandomNumber(10, 90),
          AvgItemsPerCart: generateRandomNumber(10, 25),
          TransactionCount: generateRandomNumber(50, 200),
          TotalSpend: generateRandomNumber(100, 500),
        };
        dailyTotals.AllSales.push(dayValuesAllCustomersLastYear, dayValuesAllCustomers);
        dailyTotals.SegmentSales.push(dayValueLastYear, dayValue);
      }
    }
  }

  return (
    dailyTotals ?? {
      AllSales: [],
      SegmentSales: [],
    }
  );
};
