import React, { useEffect } from 'react';

import { init } from '@module-federation/runtime';
import pkgReact from 'react/package.json';
import ReactDom from 'react-dom';
import pkgReactDom from 'react-dom/package.json';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { dependencies } from '../../../../package.json';

let MF_INITIALIZED = false;

type RemoteName = 'backoffice-remote-suppliers';

type Remote = {
  entry: string;
  name: RemoteName;
  shareScope: string;
};

type RemoteConfig = {
  entry: string | undefined;
  name: RemoteName;
  shareScope: string;
};

const initializedRemotes = new Set<RemoteName>();

function isValidRemoteEntry(remoteEntry: string | undefined): boolean {
  if (!remoteEntry) {
    return false;
  }

  try {
    // Store the URL instance to avoid the 'new' for side effects warning
    const url = new URL(remoteEntry);
    return Boolean(url);
  } catch {
    return false;
  }
}

function isRemote(config: RemoteConfig): config is Remote {
  return isValidRemoteEntry(config.entry);
}

function getRemotes(): Remote[] {
  const config = [
    {
      name: 'backoffice-remote-suppliers' as const,
      entry: process.env.MF_REMOTE_SUPPLIERS_ENTRY,
      shareScope: 'default',
    },
  ];

  return config.filter(isRemote);
}

function initModuleFederation() {
  const remotes = getRemotes();

  if (MF_INITIALIZED || !remotes.length) {
    return;
  }

  init({
    name: 'backoffice-host',
    remotes,
    shared: {
      react: {
        version: pkgReact.version,
        lib: () => React,
        shareConfig: {
          singleton: true,
          requiredVersion: dependencies.react,
        },
      },
      'react-dom': {
        version: pkgReactDom.version,
        lib: () => ReactDom,
        shareConfig: {
          singleton: true,
          requiredVersion: dependencies['react-dom'],
        },
      },
    },
  });

  remotes.forEach((remote) => {
    initializedRemotes.add(remote.name);
  });

  MF_INITIALIZED = true;
}

// NOTE: for testing purposes only
export function resetModuleFederation() {
  MF_INITIALIZED = false;
  initializedRemotes.clear();
}

export function useInitModuleFederation() {
  const isModuleFederationEnabled = useLDFlag(LD.MODULE_FEDERATION_ENABLED, false);

  useEffect(() => {
    if (isModuleFederationEnabled) {
      initModuleFederation();
    }
  }, [isModuleFederationEnabled]);
}

export const hasRemoteInitialized = (remoteName: RemoteName) => initializedRemotes.has(remoteName);
