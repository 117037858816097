import { differenceInCalendarDays, format, getMonth } from 'date-fns';

import HttpClient from 'src/app_deprecated/utils/HttpClient';
import Utils from 'src/app_deprecated/utils/Utils';

import { DateRangeOption } from 'src/app/components/lib/snowflake-backed-components/date-picker-range/date-picker-range';
import { formatNumberAsMoneyWithCommas } from 'src/app/utils/formatters';

import { BREAKDOWN_TYPE } from './consts';

import type { LocationsDateRange, ReportsSummary } from './types';

export function formatCurrency(value: number): string {
  return value < 0 ? `-$${formatNumberAsMoneyWithCommas(Math.abs(value))}` : `$${formatNumberAsMoneyWithCommas(value)}`;
}

export async function exportData(exportEndpoint: string, payload: LocationsDateRange) {
  const resp = await HttpClient.post(
    exportEndpoint,
    {
      FromDate: payload.fromDate,
      ToDate: payload.toDate,
      ExportDate: format(new Date(), 'MM/dd/yyyy h:mm a'),
      LocIds: payload.locIds,
    },
    'Report failed to generate'
  );

  if (resp) {
    for (const file of resp) {
      Utils.openFileInWindow(file.File, file.FileName, file.FileType);
    }
  } else {
    throw new Error('Error generating report');
  }
}

export function getColumn(type: string): { field: string; headerName: string } {
  let headerName = '';
  let field = '';

  switch (type) {
    case BREAKDOWN_TYPE.AVO:
      headerName = 'AVO';
      field = 'AVO';
      break;

    case BREAKDOWN_TYPE.AvgItemsPerCart:
      headerName = 'Avgerage items per cart';
      field = 'AvgItemsPerCart';
      break;

    case BREAKDOWN_TYPE.TransactionCount:
      headerName = 'Transaction count';
      field = 'TransactionCount';
      break;

    // TODO future use case still being researched
    // case BREAKDOWN_TYPE.ShoppingFrequency:
    //   headerName = 'Shopping frequency';
    //   field = 'ShoppingFrequency';
    //   break;

    case BREAKDOWN_TYPE.TotalSpend:
      headerName = 'Total Spend';
      field = 'TotalSpend';
      break;
  }

  return { headerName, field };
}

export const filterLocation = (location: any): boolean =>
  location.HasPermission && location.MDCompanyFlag === 'Dispensary';

export const maskLocationName = (location: any): string => {
  const name = location.Name as string;
  if (!location.IsLive) {
    return `${name} - STAGING`;
  }
  if (location.IsSandbox) {
    return `${name} - SANDBOX`;
  }

  return name;
};

// Helper function to get date range based on option
export const getDateRange = (dateOption: DateRangeOption, startDate: Date, endDate: Date) => {
  const isGroupByDay = !(
    dateOption === DateRangeOption.YTD ||
    dateOption === DateRangeOption.LastYear ||
    dateOption === DateRangeOption.LastQuarter
  );

  let start = 0;
  let end = 11;

  if (dateOption === DateRangeOption.Custom || isGroupByDay) {
    end = differenceInCalendarDays(endDate, startDate);
  } else if (dateOption === DateRangeOption.LastQuarter) {
    start = getMonth(startDate);
    end = getMonth(endDate);
  }

  return { start, end, isGroupByDay };
};

// Helper function for summary data calculation
export const calculateSummaryData = (
  values: Array<{ locData: ReportsSummary[] }>,
  summedSummaries: (data: ReportsSummary[]) => any
) => {
  const summaryData = values.filter((v) => v.locData.length > 0).flatMap((v) => v.locData);

  return summedSummaries(summaryData);
};
