// Comment to make v8 happy. See https://github.com/istanbuljs/v8-to-istanbul/issues/198#issuecomment-1464375388

export const PotencyIndicatorList = [
  { name: 'High THC : Low CBD', val: 1 },
  { name: 'Low THC : High CBD', val: 2 },
  { name: 'Equal THC : CBD', val: 3 },
  { name: 'Per Pharmacist', val: 4 },
];

export const TestStatusColors = {
  TestFailed: { backgroundColor: 'red', color: 'white' },
  RetestFailed: { backgroundColor: 'red', color: 'white' },
  TestPassed: { backgroundColor: 'green', color: 'white' },
  RetestPassed: { backgroundColor: 'green', color: 'white' },
  SubmittedForTesting: { backgroundColor: 'yellow' },
  TestingInProgress: { backgroundColor: 'yellow' },
  AwaitingConfirmation: { backgroundColor: 'yellow' },
};

export const BioTrackQAStatusColors = {
  PASSED: { backgroundColor: 'green', color: 'white' },
  PENDING: { backgroundColor: 'yellow' },
  FAILED: { backgroundColor: 'red', color: 'white' },
};

export const packageHasNoValidCustomerTypesMessage =
  "This package is not currently sellable; please update the 'Available For:' field with customer types enabled at this location. This can be done from the catalog or package level.";

export const ALL_ENABLED_CUSTOMER_TYPES = 'All enabled customer types';
export const NO_VALID_CUSTOMER_TYPES =
  'None - customers types enabled at this location do NOT match the customer types enabled for this package';
