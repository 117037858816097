import { useQuery } from '@tanstack/react-query';

import { fetchData } from '..';

import type { QueryKey } from '@tanstack/react-query';

export type Segment = {
  AudienceSize: number;
  CreatedBy: string;
  CreatedOnUtc: string;
  Definition: string | null;
  IsDeleted: boolean;
  IsUpdating: boolean;
  LastEditedUtc: string | null;
  Name: string;
  SegmentId: number;
  ShowOnRegister: boolean;
};

export const segmentsQueryKey: QueryKey = ['segments'];

type UseGetSegmentsQueryParams = {
  disableRefetch?: boolean;
};

export function useGetSegmentsQuery(params?: UseGetSegmentsQueryParams) {
  return useQuery({
    queryKey: segmentsQueryKey,
    queryFn: () => fetchData<Segment[]>('api/segment/get-segment-list'),
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: params?.disableRefetch
      ? false
      : (segmentListData) => (segmentListData?.some((data) => data.IsUpdating) ? 3000 : false),
  });
}
