import { object, string } from 'yup';

import { getOperatorFromRule } from '../../utils';

import type { Attributes, CustomerSegmentSchemaMap } from './schema';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';
import type { BaseSchema } from 'yup';

type getQueryBuilderSchemaVersionProps = {
  getYup: BaseSchema;
};

const getQueryBuilderSchemaVersion = ({ getYup }: getQueryBuilderSchemaVersionProps) =>
  object({
    Attribute: string().required(),
    Condition: string().required(),
    Operator: string().required(),
    Value: getYup.required(),
    SecondValue: getYup
      .when('$requireSecondValue', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) =>
          schema
            .transform((value, originalValue) => (originalValue === '' || originalValue === null ? undefined : value))
            .optional(),
      })
      .test({
        name: 'ValueLessThanSecondValue',
        test() {
          const { Value, SecondValue } = this.parent;

          if (Value && SecondValue) {
            return Value <= SecondValue;
          }
          return true;
        },
      }),
    Variant: string().when('$requireVariant', (requireVariant, schema) => {
      if (requireVariant) {
        return schema.required().nullable();
      }
      return schema.nullable();
    }),
  });

type SchemaForQueryBuilderProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  rule: Rule;
};

export const generateSchemaForQueryBuilder = ({
  rule,
  customerSegmentSchema,
  enableBrands,
}: SchemaForQueryBuilderProps) => {
  const getYup: BaseSchema = getOperatorFromRule({ rule, customerSegmentSchema, enableBrands })?.yup || string();

  const queryBuilderSchema = getQueryBuilderSchemaVersion({ getYup });

  return queryBuilderSchema;
};

type IsRuleValidProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  requireSecondValue: boolean;
  requireVariant: boolean;
  rule: Rule;
};

export function isRuleValid({
  rule,
  customerSegmentSchema,
  requireVariant,
  requireSecondValue,
  enableBrands,
}: IsRuleValidProps) {
  try {
    generateSchemaForQueryBuilder({
      rule,
      customerSegmentSchema,
      enableBrands,
    }).validateSync(rule, {
      context: { requireSecondValue, requireVariant },
    });
    return true;
  } catch {
    return false;
  }
}

type ValidateRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  rule: Rule;
};

export const validateRule = ({ rule, customerSegmentSchema, enableBrands }: ValidateRuleProps) => {
  // only some rules require a second value or variant
  const getOperatorSecondValue =
    getOperatorFromRule({ rule, customerSegmentSchema, enableBrands })?.requiresSecondValue || false;
  const requireSecondValue = getOperatorSecondValue;
  const variants = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.variants
    : [];
  const requireVariant = variants?.length > 0;

  return isRuleValid({
    rule,
    customerSegmentSchema,
    requireVariant,
    requireSecondValue,
    enableBrands,
  });
};

type ValidateAllRulesProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableBrands: boolean | undefined;
  ruleGroup?: RuleGroup;
};

export const validateAllRules = ({ ruleGroup, customerSegmentSchema, enableBrands }: ValidateAllRulesProps) => {
  const areRulesValid = ruleGroup?.rules.every((rule) => validateRule({ rule, customerSegmentSchema, enableBrands }));

  return areRulesValid;
};
