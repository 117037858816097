import { useQuery } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { reportDemographicKeys } from './query-key-factory';

import type {
  ReportsDemographicsOverview,
  DemographicReportRow,
} from 'src/app/pages/customers/customers/segments/details/reports/utils/types';

type SegmentDemographicPayload = {
  FromDate?: Date;
  isDummyDataEnabled?: boolean;
  segmentId: number;
  ToDate?: Date;
};

export function useGetSegmentDemographicQuery(payload: SegmentDemographicPayload) {
  return useQuery({
    queryKey: reportDemographicKeys.one(Number(payload?.segmentId)),
    queryFn: payload?.isDummyDataEnabled
      ? () => getResponseDataII()
      : () =>
          postData<SegmentDemographicPayload, ReportsDemographicsOverview>({
            endpoint: `api/segment/demographics`,
            payload,
          }),
  });
}

const getResponseDataII = () => {
  const demographics = {
    ageDemographicReport: [] as DemographicReportRow[],
    locationDemographicReport: [] as DemographicReportRow[],
  } as ReportsDemographicsOverview;

  demographics.ageDemographicReport.push(
    { metric: 'Products', groupName: '18-20', metricValue: 'Blue Dragon', topMetricPercentage: 28 },
    { metric: 'Products', groupName: '21-24', metricValue: 'Black Momba', topMetricPercentage: 22 },
    { metric: 'Products', groupName: '25-34', metricValue: 'Blackberry', topMetricPercentage: 20 },
    { metric: 'Products', groupName: '45-54', metricValue: 'Purple Urkle', topMetricPercentage: 12 },
    { metric: 'Products', groupName: '55-64', metricValue: 'OG Kush', topMetricPercentage: 10 },
    { metric: 'Products', groupName: '65+', metricValue: 'BlueBerry', topMetricPercentage: 8 },
    { metric: 'Usage', groupName: '18-20', metricValue: 'Vape', topMetricPercentage: 28 },
    { metric: 'Usage', groupName: '21-24', metricValue: 'Smoke', topMetricPercentage: 22 },
    { metric: 'Usage', groupName: '25-34', metricValue: 'Eat', topMetricPercentage: 20 },
    { metric: 'Usage', groupName: '45-54', metricValue: 'Dab', topMetricPercentage: 12 },
    { metric: 'Usage', groupName: '55-64', metricValue: 'Drink', topMetricPercentage: 10 },
    { metric: 'Usage', groupName: '65+', metricValue: 'Other', topMetricPercentage: 8 },
    { metric: 'Categories', groupName: '18-20', metricValue: 'Vaporizers', topMetricPercentage: 28 },
    { metric: 'Categories', groupName: '21-24', metricValue: 'Pre-rolls', topMetricPercentage: 22 },
    { metric: 'Categories', groupName: '25-34', metricValue: 'Edibles', topMetricPercentage: 20 },
    { metric: 'Categories', groupName: '45-54', metricValue: 'Concentrates', topMetricPercentage: 12 },
    { metric: 'Categories', groupName: '55-64', metricValue: 'Topicals', topMetricPercentage: 10 },
    { metric: 'Categories', groupName: '65+', metricValue: 'CBD', topMetricPercentage: 8 },
    { metric: 'CustomerType', groupName: '18-54', metricValue: 'Recreational', topMetricPercentage: 75 },
    { metric: 'CustomerType', groupName: '55-65+', metricValue: 'Medical', topMetricPercentage: 25 }
  );
  demographics.locationDemographicReport.push(
    { metric: 'Products', groupName: 'AYR- Tampa, Fl', metricValue: 'Blue Dragon', topMetricPercentage: 60 },
    { metric: 'Products', groupName: 'AYR- Clear Water, Fl', metricValue: 'Black Momba', topMetricPercentage: 35 },
    { metric: 'Products', groupName: 'AYR- St. Petersburg, Fl', metricValue: 'God Bud', topMetricPercentage: 5 },
    { metric: 'Usage', groupName: 'AYR- Tampa, Fl', metricValue: 'Vape', topMetricPercentage: 10 },
    { metric: 'Usage', groupName: 'AYR- Clear Water, Fl', metricValue: 'Smoke', topMetricPercentage: 65 },
    { metric: 'Usage', groupName: 'AYR- St. Petersburg, Fl', metricValue: 'Eat', topMetricPercentage: 25 },
    { metric: 'Categories', groupName: 'AYR- Tampa, Fl', metricValue: 'Vaporizers', topMetricPercentage: 40 },
    { metric: 'Categories', groupName: 'AYR- Clear Water, Fl', metricValue: 'pre-roll', topMetricPercentage: 35 },
    { metric: 'Categories', groupName: 'AYR- St. Petersburg, Fl', metricValue: 'Edibles', topMetricPercentage: 25 },
    { metric: 'CustomerType', groupName: 'AYR- Tampa, Fl', metricValue: 'Recreational', topMetricPercentage: 28 },
    { metric: 'CustomerType', groupName: 'AYR- Clear Water, Fl', metricValue: 'Recreational', topMetricPercentage: 22 },
    { metric: 'CustomerType', groupName: 'AYR- St. Petersburg, Fl', metricValue: 'Medical', topMetricPercentage: 20 }
  );

  return (
    demographics ?? {
      ageDemographicReport: [],
      locationDemographicReport: [],
    }
  );
};
