/* eslint-disable no-plusplus */
import React from 'react';

import { format, isBefore, isAfter } from 'date-fns';
import uuid from 'uuid/v1';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import {
  RebrandMonthContainer,
  RebrandMonthDate,
  RebrandDaysOfWeekContainer,
} from './date-picker-month-rebrand.styles';
import { combineDateWithTime } from './utils';

type DataItem = {
  date: string | false;
  dd: string | false;
  ddd: string | false;
};

type DatePickerMonthProps = {
  afterSelectedDate?: Date;
  allowPastDate?: boolean;
  beforeSelectedDate?: Date;
  data?: DataItem[];
  enableTime?: boolean;
  selectDate?: (date: string) => void;
  selected?: Date;
  time?: string;
  today?: Date;
};

export function DatePickerMonth(props: DatePickerMonthProps) {
  const {
    data,
    today,
    selected,
    selectDate,
    allowPastDate,
    afterSelectedDate,
    beforeSelectedDate,
    time,
    enableTime = false,
  } = props;

  const isDisableDaysBeforeTodayFixEnabled = useLDFlag<boolean>(LD.CUSTOMERS_CAMPAIGN_DATEPICKER_ENABLE_TIME_ROLLOUT);

  const daysOfTheMonth = data ?? [];
  const daysOfTheWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const bufferDays = [];
  let i = 0;
  while (daysOfTheWeek[i] !== daysOfTheMonth[0].dd) {
    bufferDays.push(
      <div className='buffer-day' key={`buffer_${i}`}>
        &nbsp;
      </div>
    );
    i++;
  }

  function handleSelectDate(date) {
    if (
      (isBefore(date, today) && !allowPastDate) ||
      (beforeSelectedDate && isBefore(date, beforeSelectedDate)) ||
      (afterSelectedDate && isAfter(date, afterSelectedDate))
    ) {
      return;
    }
    selectDate(date);
  }

  return (
    <RebrandMonthContainer>
      <RebrandDaysOfWeekContainer>
        {daysOfTheWeek.map((day) => (
          <div className='week-day__dd' key={day}>
            {day}
          </div>
        ))}
      </RebrandDaysOfWeekContainer>

      {bufferDays}

      {daysOfTheMonth.map((day) => {
        if (typeof day.date !== 'string') {
          return null;
        }

        const date = new Date(day.date);

        // Combine date and time to make time considered in the date selection when enableTime is true
        const dateWithTime = combineDateWithTime(date, time, enableTime);

        const isToday = format(today, 'MM/dd/yyyy') === day.date;

        // Check if date is before today, considering time when enabled, allowpastdate, and LD flag is enabled
        const beforeTodayDisabled =
          enableTime && isDisableDaysBeforeTodayFixEnabled
            ? dateWithTime < today && !allowPastDate
            : isBefore(date, today) && !allowPastDate;

        const beforeSelectedDisabled = beforeSelectedDate instanceof Date && isBefore(date, beforeSelectedDate);
        const afterSelectedDisabled = afterSelectedDate instanceof Date && isAfter(date, afterSelectedDate);
        const dataTestIdToday = isToday ? 'calendar-month_div_today' : '';
        const selectedDate = format(selected, 'MM/dd/yyyy') === day.date;

        // When CUSTOMERS_CAMPAIGN_DATEPICKER_ENABLE_TIME_ROLLOUT is retired,
        // Remove this line and change $beforeTodayDisabled to use beforeTodayDisabledStyles exclusively
        // TODO: Update usecase when enableTime is false to also disable it properly
        return (
          <RebrandMonthDate
            $afterSelectedDate={afterSelectedDisabled}
            $beforeSelectedDate={beforeSelectedDisabled}
            $beforeTodayDisabled={beforeTodayDisabled}
            $beforeTodayDisabledAndEnableTime={beforeTodayDisabled && isDisableDaysBeforeTodayFixEnabled}
            $selectedDate={selectedDate}
            $todayDate={isToday}
            data-testid={dataTestIdToday}
            key={uuid()}
            role='button'
            title={isToday ? 'Today' : ''}
            onClick={() => handleSelectDate(format(date, 'MM/dd/yyyy'))}
          >
            {format(date, 'd')}
          </RebrandMonthDate>
        );
      })}
    </RebrandMonthContainer>
  );
}
