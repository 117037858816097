import type { OperatorGroup } from './operators';

export enum AttributeName {
  Age = 'age',
  AvgOrderValue = 'avgOrderValue',
  AvgSpend = 'avg_spend',
  AvgSpendPercentileRank = 'avg_spend_percentile_rank',
  Birthday = 'date_of_birth',
  CustomerGroupIds = 'customer_group_ids',
  CustomerTypeId = 'customer_type_id',
  DiscountTransactions = 'discount_transactions',
  FirstPurchaseDate = 'first_order_date_local',
  Gender = 'gender',
  IsOutOfState = 'is_out_of_state_customer',
  LastPurchaseDate = 'last_order_date_local',
  MedicalExpirationDate = 'medid_expiration_date',
  MostCommonlyBoughtBrands = 'top_brand',
  MostCommonlyBoughtCategory = 'top_category_id',
  SpendPercentileRank = 'spend_percentile_rank',
  State = 'customer_state',
  TotalOrders = 'total_orders',
  TotalSpend = 'total_spend',
  ZipCode = 'zip_code',
}

export enum ConditionName {
  AttributesOfSomeone = 'attributesOfSomeone',
  PredictiveAnalyticsAboutSomeone = 'predictiveAnalyticsAboutSomeone',
  WhatSomeoneHasOrHasNotDone = 'whatSomeoneHasOrHasNotDone',
}

export enum CustomerQueryBuilderUnit {
  day = 'day',
  dollar = 'dollar',
  percent = 'percent',
  quantity = 'qty',
  year = 'year',
}

export type Variant = {
  id: string;
  label: string;
};

export type Attributes = {
  label: string;
  name: string;
  operatorGroup: OperatorGroup;
  unit?: CustomerQueryBuilderUnit;
  variants?: Variant[];
};

export type CustomerSegmentSchema = {
  attributes: Attributes[];
  id?: string;
  label: string;
  value: string;
};

const predictiveAnalyticsAboutSomeoneCriteria: Attributes[] = [
  {
    name: AttributeName.TotalOrders,
    label: 'Orders placed',
    operatorGroup: 'number',
    unit: CustomerQueryBuilderUnit.quantity,
  },
  {
    name: AttributeName.MostCommonlyBoughtCategory,
    label: 'Most commonly bought category',
    operatorGroup: 'productCategory',
  },
  {
    name: AttributeName.MostCommonlyBoughtBrands,
    label: 'Most commonly bought brands',
    operatorGroup: 'brand',
  },
];

const whatSomeoneHasOrHasNotDoneCriteria: Attributes[] = [
  {
    name: AttributeName.FirstPurchaseDate,
    label: 'First purchase date',
    operatorGroup: 'historicDate',
    unit: CustomerQueryBuilderUnit.day,
  },
  {
    name: AttributeName.LastPurchaseDate,
    label: 'Last purchase date',
    operatorGroup: 'historicDate',
    unit: CustomerQueryBuilderUnit.day,
  },
  {
    name: AttributeName.DiscountTransactions,
    label: 'Total discounts used',
    operatorGroup: 'number',
    variants: [
      { id: '_last_7', label: 'past week' },
      { id: '_last_30', label: 'past month' },
      { id: '_last_90', label: 'past 3 months' },
      { id: '_last_365', label: 'past year' },
      { id: '_lifetime', label: 'lifetime' },
    ],
    unit: CustomerQueryBuilderUnit.quantity,
  },
  {
    name: AttributeName.SpendPercentileRank,
    label: 'Customer total spending percentile',
    operatorGroup: 'percent',
    variants: [
      { id: '_last_7', label: 'past week' },
      { id: '_last_30', label: 'past month' },
      { id: '_last_90', label: 'past 3 months' },
      { id: '_last_365', label: 'past year' },
      { id: '_lifetime', label: 'lifetime' },
    ],
    unit: CustomerQueryBuilderUnit.percent,
  },
  {
    name: AttributeName.AvgSpendPercentileRank,
    label: 'Customer average spending percentile',
    operatorGroup: 'percent',
    variants: [
      { id: '_last_7', label: 'past week' },
      { id: '_last_30', label: 'past month' },
      { id: '_last_90', label: 'past 3 months' },
      { id: '_last_365', label: 'past year' },
      { id: '_lifetime', label: 'lifetime' },
    ],
    unit: CustomerQueryBuilderUnit.percent,
  },
  {
    name: AttributeName.TotalSpend,
    label: 'Total spend',
    operatorGroup: 'number',
    variants: [
      { id: '_last_7', label: 'past week' },
      { id: '_last_30', label: 'past month' },
      { id: '_last_90', label: 'past 3 months' },
      { id: '_last_365', label: 'past year' },
      { id: '_lifetime', label: 'lifetime' },
    ],
    unit: CustomerQueryBuilderUnit.dollar,
  },
  {
    name: AttributeName.AvgSpend,
    label: 'Average spend',
    operatorGroup: 'number',
    variants: [
      { id: '_last_7', label: 'past week' },
      { id: '_last_30', label: 'past month' },
      { id: '_last_90', label: 'past 3 months' },
      { id: '_last_365', label: 'past year' },
      { id: '_lifetime', label: 'lifetime' },
    ],
    unit: CustomerQueryBuilderUnit.dollar,
  },
];

const attributesOfSomeoneCriteria: Attributes[] = [
  { name: AttributeName.Birthday, label: 'Birthday', operatorGroup: 'date', unit: CustomerQueryBuilderUnit.day },
  { name: AttributeName.State, label: 'State', operatorGroup: 'stateLocation' },
  { name: AttributeName.IsOutOfState, label: 'Is tourist', operatorGroup: 'boolean' },
  {
    name: AttributeName.MedicalExpirationDate,
    label: 'Medical expiration date',
    operatorGroup: 'date',
    unit: CustomerQueryBuilderUnit.day,
  },
  { name: AttributeName.CustomerTypeId, label: 'Customer type', operatorGroup: 'customerType' },
  { name: AttributeName.CustomerGroupIds, label: 'Customer group', operatorGroup: 'customerGroup' },
  { name: AttributeName.Age, label: 'Age', operatorGroup: 'number', unit: CustomerQueryBuilderUnit.year },
  { name: AttributeName.Gender, label: 'Gender', operatorGroup: 'gender' },
  { name: AttributeName.ZipCode, label: 'Zip code', operatorGroup: 'string' },
];

export type CustomerSegmentSchemaMap = {
  [key in ConditionName]: CustomerSegmentSchema;
};

export const mockCustomerSegmentSchema: CustomerSegmentSchemaMap = {
  [ConditionName.WhatSomeoneHasOrHasNotDone]: {
    id: 'whatSomeoneHasOrHasNotDone',
    value: 'whatSomeoneHasOrHasNotDone',
    label: 'What someone has or has not done',
    attributes: whatSomeoneHasOrHasNotDoneCriteria,
  },
  [ConditionName.AttributesOfSomeone]: {
    id: 'attributesOfSomeone',
    value: 'attributesOfSomeone',
    label: 'Attributes of someone',
    attributes: attributesOfSomeoneCriteria,
  },
  [ConditionName.PredictiveAnalyticsAboutSomeone]: {
    id: 'predictiveAnalyticsAboutSomeone',
    value: 'predictiveAnalyticsAboutSomeone',
    label: 'Predictive analytics about someone',
    attributes: predictiveAnalyticsAboutSomeoneCriteria,
  },
};
